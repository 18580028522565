/**
 * Author: Harsh Agrawal
 * Summary: This page is dedicated to editing billing details. Users can utilize this page to update their billing information.
 */


import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Header from "../../../components/layout/header";
import Footer from "../../../components/layout/footer";
import arrowsvg from './../../../assets/img/arrow-left-16px.svg';
import { focusAndOpenKeyboard } from '../../../utils/globalUtils';
import { useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import OrderSummary from '../../../components/orderSummary';
import { validateForm } from '../../../utils/form';
import GoogleMapAddress from '../../../components/address';
import { updateUserBillingDetails } from '../../../reducers/accountDetails';
import { displayError } from '../../../reducers/snackbar';
import { getTranslation } from '../../../reducers/translation';
import { TRANSLATION_TYPE } from '../../../utils/constants';

export default function EditBillingDetails() {
    const { billingDetail } = useSelector((state) => (state.accountDetails));

    const [formData, setFormData] = useState({
        firstName: billingDetail.firstName,
        lastName: billingDetail.lastName,
        address: billingDetail.addressLine1,
        address2: billingDetail.addressLine2,
        city: billingDetail.city,
        state: billingDetail.state,
        zip: billingDetail.zip,
        country: billingDetail.country,
        phone: billingDetail.phone,
        isSubmitted: false
    });

    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const options = useMemo(() => countryList().getData(), [])

    useEffect(() => {
        focusAndOpenKeyboard(document.getElementById('first-name'), 300);
    }, [])

    const validateBillingForm = () => {
        setTimeout(() => {
            validateForm("edit-billing-details__form");
        }, 50);
    }

    const handleInputChange = event => {
        const { name, value, type, checked } = event.target;

        // Handle checkboxes separately
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData(prevData => ({
            ...prevData,
            [name]: inputValue
        }));
    };

    const autoCompleteAddress = ({ address, city, state, stateCode, zip, country }) => {
        let formData_ = { ...formData, address, city, state, zip, country };

        setFormData(formData_);

        if (formData.isSubmitted)
            validateBillingForm();
    }

    const handleSubmit = () => {
        if (validateForm("edit-billing-details__form")) {
            let payload = createEditBillingDetailsPayload();
            dispatch(updateUserBillingDetails(payload)).then((response) => {
                navigate(-1);
            }).catch((error) => {
                dispatch(displayError(error));
            })
        }
    }

    const createEditBillingDetailsPayload = () => {
        let billingDetail_ = {
            "FirstName": `${formData.firstName}`,
            "LastName": `${formData.lastName}`,
            "AddressLine1": `${formData.address}`,
            "AddressLine2": formData.address2 !== null ? `${formData.address2}` : null,
            "City": `${formData.city}`,
            "State": `${formData.state}`,
            "Zip": `${formData.zip}`,
            "Country": `${formData.country}`,
            "Phone": `${formData.phone}`
        }

        let payload = { "billingDetail": billingDetail_ };

        return payload;
    }

    return (
        <>
            <Header />
            <div className="body body--alt2">
                <main className="main main--fullh">
                    <section className="section personal-details">
                        <div >
                            <div onClick={() => navigate(-1)} className="action-button2">
                                <img src={arrowsvg} alt="" />
                                <div>{t_label?.['account.button.go_back'] ?? 'Go Back'}</div>
                            </div>
                            <form
                                action=""
                                id="edit-billing-details__form"
                                className="personal-details__form mt-4"
                            >
                                <div className="grid-cont2">
                                    <div className="personal-details__heading2">
                                        {t_label?.['account.label.edit_personal_details'] ?? 'Edit Personal Details'}
                                    </div>

                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <label htmlFor="first-name" className="personal-details__label2">
                                                    {t_label?.['checkout.label.first_name'] ?? 'First Name'}
                                                </label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    placeholder={t_label?.['checkout.label.first_name'] ?? 'First Name'}
                                                    name="firstName"
                                                    value={formData.firstName}
                                                    id="first-name"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="150"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.first_name_min_len'] ?? "First name cannot have more than 150 characters"}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <label htmlFor="last-name" className="personal-details__label2">
                                                    {t_label?.['checkout.label.last_name'] ?? 'Last Name'}
                                                </label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    placeholder={t_label?.['checkout.label.last_name'] ?? 'Last Name'}
                                                    name="lastName"
                                                    value={formData.lastName}
                                                    id="last-name"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="150"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.last_name_min_len_err'] ?? "Last name cannot have more than 150 characters"}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group personal-details__control">
                                        <br />
                                        <label htmlFor="phone" className="personal-details__label2">
                                            {t_label?.['checkout.label.phone'] ?? 'Phone'}
                                        </label>
                                        <input
                                            required
                                            data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                            type="text"
                                            placeholder={t_label?.['checkout.label.phone'] ?? 'Phone'}
                                            name="phone"
                                            value={formData.phone}
                                            id="phone"
                                            className="personal-details__input"
                                            data-pristine-maxLength="20"
                                            data-pristine-maxlength-message={t_message?.['checkout.message.phone_min_len'] ?? "Phone cannot have more than 20 characters"}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="form-group personal-details__control">
                                        <br />
                                        <label htmlFor="address" className="personal-details__label2">
                                            {t_label?.['checkout.label.address'] ?? 'Address'}
                                        </label>
                                        <GoogleMapAddress address={formData.address} callback={autoCompleteAddress} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group personal-details__control">
                                        <input
                                            type="text"
                                            name="address2"
                                            placeholder={t_label?.['checkout.label.address_line_2'] ?? "Address line 2"}
                                            value={formData.address2}
                                            id="address2"
                                            className="personal-details__input"
                                            data-pristine-maxLength="150"
                                            data-pristine-maxlength-message={t_message?.['checkout.message.address_line_2_min_len'] ?? "Address line 2 cannot have more than 150 characters"}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <label htmlFor="state" className="personal-details__label2">
                                                    {t_label?.['account.label.state'] ?? 'State'}
                                                </label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    placeholder="State"
                                                    name={t_label?.['account.label.state'] ?? 'State'}
                                                    value={formData.state}
                                                    id="state"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="50"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.state_min_len'] ?? "State cannot have more than 50 characters"}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <label htmlFor="zip" className="personal-details__label2">
                                                    {t_label?.['account.label.zip'] ?? 'Zip'}
                                                </label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    placeholder={t_label?.['account.label.zip'] ?? 'Zip'}
                                                    name="zip"
                                                    value={formData.zip}
                                                    id="zip"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="20"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.zip_min_len'] ?? "Zip code cannot have more than 20 characters"}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <label htmlFor="country" className="personal-details__label2">
                                                    {t_label?.['checkout.label.country'] ?? 'Country'}
                                                </label>
                                                <select required name="country" id="country" value={formData.country} className="personal-details__select" onChange={handleInputChange} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}>
                                                    {options.map(option_ =>
                                                        <option key={option_.value} value={option_.value}>{option_.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-row"></div>
                                </div>

                                <div className="form-footer">
                                    <div onClick={() => navigate(-1)} className="form-footer__action_button bg-1">
                                        {/* <img src={cancelpng} alt="" /> */}
                                        <svg class="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                                            <use href="/img/sprite.svg#close"></use>
                                        </svg>
                                        <div>{t_label?.['account.label.cancel'] ?? 'Cancel'}</div>
                                    </div>
                                    <div onClick={handleSubmit} className="form-footer__action_button bg-2">
                                        <svg class="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                                            <use href="/img/sprite.svg#check"></use>
                                        </svg>
                                        <div>{t_label?.['account.label.save'] ?? 'Save'}</div>
                                    </div>
                                </div>

                            </form>
                            <OrderSummary />
                            {/* <ApproveAndCancel /> */}
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    );
}