const countryStates =
{
    "US": [
        {
            "countryCode": "US",
            "isoCode": "AL",
            "name": "Alabama"
        },
        {
            "countryCode": "US",
            "isoCode": "AK",
            "name": "Alaska"
        },
        {
            "countryCode": "US",
            "isoCode": "AS",
            "name": "American Samoa"
        },
        {
            "countryCode": "US",
            "isoCode": "AZ",
            "name": "Arizona"
        },
        {
            "countryCode": "US",
            "isoCode": "AR",
            "name": "Arkansas"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-81",
            "name": "Baker Island"
        },
        {
            "countryCode": "US",
            "isoCode": "CA",
            "name": "California"
        },
        {
            "countryCode": "US",
            "isoCode": "CO",
            "name": "Colorado"
        },
        {
            "countryCode": "US",
            "isoCode": "CT",
            "name": "Connecticut"
        },
        {
            "countryCode": "US",
            "isoCode": "DE",
            "name": "Delaware"
        },
        {
            "countryCode": "US",
            "isoCode": "DC",
            "name": "District of Columbia"
        },
        {
            "countryCode": "US",
            "isoCode": "FL",
            "name": "Florida"
        },
        {
            "countryCode": "US",
            "isoCode": "GA",
            "name": "Georgia"
        },
        {
            "countryCode": "US",
            "isoCode": "GU",
            "name": "Guam"
        },
        {
            "countryCode": "US",
            "isoCode": "HI",
            "name": "Hawaii"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-84",
            "name": "Howland Island"
        },
        {
            "countryCode": "US",
            "isoCode": "ID",
            "name": "Idaho"
        },
        {
            "countryCode": "US",
            "isoCode": "IL",
            "name": "Illinois"
        },
        {
            "countryCode": "US",
            "isoCode": "IN",
            "name": "Indiana"
        },
        {
            "countryCode": "US",
            "isoCode": "IA",
            "name": "Iowa"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-86",
            "name": "Jarvis Island"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-67",
            "name": "Johnston Atoll"
        },
        {
            "countryCode": "US",
            "isoCode": "KS",
            "name": "Kansas"
        },
        {
            "countryCode": "US",
            "isoCode": "KY",
            "name": "Kentucky"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-89",
            "name": "Kingman Reef"
        },
        {
            "countryCode": "US",
            "isoCode": "LA",
            "name": "Louisiana"
        },
        {
            "countryCode": "US",
            "isoCode": "ME",
            "name": "Maine"
        },
        {
            "countryCode": "US",
            "isoCode": "MD",
            "name": "Maryland"
        },
        {
            "countryCode": "US",
            "isoCode": "MA",
            "name": "Massachusetts"
        },
        {
            "countryCode": "US",
            "isoCode": "MI",
            "name": "Michigan"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-71",
            "name": "Midway Atoll"
        },
        {
            "countryCode": "US",
            "isoCode": "MN",
            "name": "Minnesota"
        },
        {
            "countryCode": "US",
            "isoCode": "MS",
            "name": "Mississippi"
        },
        {
            "countryCode": "US",
            "isoCode": "MO",
            "name": "Missouri"
        },
        {
            "countryCode": "US",
            "isoCode": "MT",
            "name": "Montana"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-76",
            "name": "Navassa Island"
        },
        {
            "countryCode": "US",
            "isoCode": "NE",
            "name": "Nebraska"
        },
        {
            "countryCode": "US",
            "isoCode": "NV",
            "name": "Nevada"
        },
        {
            "countryCode": "US",
            "isoCode": "NH",
            "name": "New Hampshire"
        },
        {
            "countryCode": "US",
            "isoCode": "NJ",
            "name": "New Jersey"
        },
        {
            "countryCode": "US",
            "isoCode": "NM",
            "name": "New Mexico"
        },
        {
            "countryCode": "US",
            "isoCode": "NY",
            "name": "New York"
        },
        {
            "countryCode": "US",
            "isoCode": "NC",
            "name": "North Carolina"
        },
        {
            "countryCode": "US",
            "isoCode": "ND",
            "name": "North Dakota"
        },
        {
            "countryCode": "US",
            "isoCode": "MP",
            "name": "Northern Mariana Islands"
        },
        {
            "countryCode": "US",
            "isoCode": "OH",
            "name": "Ohio"
        },
        {
            "countryCode": "US",
            "isoCode": "OK",
            "name": "Oklahoma"
        },
        {
            "countryCode": "US",
            "isoCode": "OR",
            "name": "Oregon"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-95",
            "name": "Palmyra Atoll"
        },
        {
            "countryCode": "US",
            "isoCode": "PA",
            "name": "Pennsylvania"
        },
        {
            "countryCode": "US",
            "isoCode": "PR",
            "name": "Puerto Rico"
        },
        {
            "countryCode": "US",
            "isoCode": "RI",
            "name": "Rhode Island"
        },
        {
            "countryCode": "US",
            "isoCode": "SC",
            "name": "South Carolina"
        },
        {
            "countryCode": "US",
            "isoCode": "SD",
            "name": "South Dakota"
        },
        {
            "countryCode": "US",
            "isoCode": "TN",
            "name": "Tennessee"
        },
        {
            "countryCode": "US",
            "isoCode": "TX",
            "name": "Texas"
        },
        {
            "countryCode": "US",
            "isoCode": "UM",
            "name": "United States Minor Outlying Islands"
        },
        {
            "countryCode": "US",
            "isoCode": "VI",
            "name": "United States Virgin Islands"
        },
        {
            "countryCode": "US",
            "isoCode": "UT",
            "name": "Utah"
        },
        {
            "countryCode": "US",
            "isoCode": "VT",
            "name": "Vermont"
        },
        {
            "countryCode": "US",
            "isoCode": "VA",
            "name": "Virginia"
        },
        {
            "countryCode": "US",
            "isoCode": "UM-79",
            "name": "Wake Island"
        },
        {
            "countryCode": "US",
            "isoCode": "WA",
            "name": "Washington"
        },
        {
            "countryCode": "US",
            "isoCode": "WV",
            "name": "West Virginia"
        },
        {
            "countryCode": "US",
            "isoCode": "WI",
            "name": "Wisconsin"
        },
        {
            "countryCode": "US",
            "isoCode": "WY",
            "name": "Wyoming"
        }
    ],
    "IN": [
        {
            "countryCode": "IN",
            "isoCode": "AN",
            "name": "Andaman and Nicobar Islands"
        },
        {
            "countryCode": "IN",
            "isoCode": "AP",
            "name": "Andhra Pradesh"
        },
        {
            "countryCode": "IN",
            "isoCode": "AR",
            "name": "Arunachal Pradesh"
        },
        {
            "countryCode": "IN",
            "isoCode": "AS",
            "name": "Assam"
        },
        {
            "countryCode": "IN",
            "isoCode": "BR",
            "name": "Bihar"
        },
        {
            "countryCode": "IN",
            "isoCode": "CH",
            "name": "Chandigarh"
        },
        {
            "countryCode": "IN",
            "isoCode": "CT",
            "name": "Chhattisgarh"
        },
        {
            "countryCode": "IN",
            "isoCode": "DH",
            "name": "Dadra and Nagar Haveli and Daman and Diu"
        },
        {
            "countryCode": "IN",
            "isoCode": "DL",
            "name": "Delhi"
        },
        {
            "countryCode": "IN",
            "isoCode": "GA",
            "name": "Goa"
        },
        {
            "countryCode": "IN",
            "isoCode": "GJ",
            "name": "Gujarat"
        },
        {
            "countryCode": "IN",
            "isoCode": "HR",
            "name": "Haryana"
        },
        {
            "countryCode": "IN",
            "isoCode": "HP",
            "name": "Himachal Pradesh"
        },
        {
            "countryCode": "IN",
            "isoCode": "JK",
            "name": "Jammu and Kashmir"
        },
        {
            "countryCode": "IN",
            "isoCode": "JH",
            "name": "Jharkhand"
        },
        {
            "countryCode": "IN",
            "isoCode": "KA",
            "name": "Karnataka"
        },
        {
            "countryCode": "IN",
            "isoCode": "KL",
            "name": "Kerala"
        },
        {
            "countryCode": "IN",
            "isoCode": "LA",
            "name": "Ladakh"
        },
        {
            "countryCode": "IN",
            "isoCode": "LD",
            "name": "Lakshadweep"
        },
        {
            "countryCode": "IN",
            "isoCode": "MP",
            "name": "Madhya Pradesh"
        },
        {
            "countryCode": "IN",
            "isoCode": "MH",
            "name": "Maharashtra"
        },
        {
            "countryCode": "IN",
            "isoCode": "MN",
            "name": "Manipur"
        },
        {
            "countryCode": "IN",
            "isoCode": "ML",
            "name": "Meghalaya"
        },
        {
            "countryCode": "IN",
            "isoCode": "MZ",
            "name": "Mizoram"
        },
        {
            "countryCode": "IN",
            "isoCode": "NL",
            "name": "Nagaland"
        },
        {
            "countryCode": "IN",
            "isoCode": "OR",
            "name": "Odisha"
        },
        {
            "countryCode": "IN",
            "isoCode": "PY",
            "name": "Puducherry"
        },
        {
            "countryCode": "IN",
            "isoCode": "PB",
            "name": "Punjab"
        },
        {
            "countryCode": "IN",
            "isoCode": "RJ",
            "name": "Rajasthan"
        },
        {
            "countryCode": "IN",
            "isoCode": "SK",
            "name": "Sikkim"
        },
        {
            "countryCode": "IN",
            "isoCode": "TN",
            "name": "Tamil Nadu"
        },
        {
            "countryCode": "IN",
            "isoCode": "TG",
            "name": "Telangana"
        },
        {
            "countryCode": "IN",
            "isoCode": "TR",
            "name": "Tripura"
        },
        {
            "countryCode": "IN",
            "isoCode": "UP",
            "name": "Uttar Pradesh"
        },
        {
            "countryCode": "IN",
            "isoCode": "UT",
            "name": "Uttarakhand"
        },
        {
            "countryCode": "IN",
            "isoCode": "WB",
            "name": "West Bengal"
        }
    ],
    "CA": [
        {
            "countryCode": "CA",
            "isoCode": "AB",
            "name": "Alberta"
        },
        {
            "countryCode": "CA",
            "isoCode": "BC",
            "name": "British Columbia"
        },
        {
            "countryCode": "CA",
            "isoCode": "MB",
            "name": "Manitoba"
        },
        {
            "countryCode": "CA",
            "isoCode": "NB",
            "name": "New Brunswick"
        },
        {
            "countryCode": "CA",
            "isoCode": "NL",
            "name": "Newfoundland and Labrador"
        },
        {
            "countryCode": "CA",
            "isoCode": "NT",
            "name": "Northwest Territories"
        },
        {
            "countryCode": "CA",
            "isoCode": "NS",
            "name": "Nova Scotia"
        },
        {
            "countryCode": "CA",
            "isoCode": "NU",
            "name": "Nunavut"
        },
        {
            "countryCode": "CA",
            "isoCode": "ON",
            "name": "Ontario"
        },
        {
            "countryCode": "CA",
            "isoCode": "PE",
            "name": "Prince Edward Island"
        },
        {
            "countryCode": "CA",
            "isoCode": "QC",
            "name": "Quebec"
        },
        {
            "countryCode": "CA",
            "isoCode": "SK",
            "name": "Saskatchewan"
        },
        {
            "countryCode": "CA",
            "isoCode": "YT",
            "name": "Yukon"
        }
    ]
}


export const getStateCodeFromCountryCodeAndState = (countryCode, stateName) => {
    let states = countryStates[countryCode];
    if (states) {
        let state = states.find((state) => (state.name.toLowerCase() === stateName.toLowerCase() || state.isoCode.toLowerCase() === stateName.toLowerCase()));
        return state?.isoCode
    }
    return null;
}