import React from 'react';
import { Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cookieAlert: {
    "& .MuiAlert-icon": {
      fontSize: 20
    }
  }
});

function ErrorAlert(props) {
  const classes = useStyles();

  return (
    <>
      <Alert
        className={classes.cookieAlert}
        severity="error"
        sx={{
          bgcolor: props.bgcolor != null ? props.bgcolor : 'white',
          color: '#C5443C',
          padding: 0,
          fontSize: '13px'
        }}
      >
        {props.error}
      </Alert>
    </>
  )
}

export default ErrorAlert