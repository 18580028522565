import React from "react";

const ELLIPSIS = '...';

export function ellipsis(string, maxLength = 35) {
  if (!string) return '';
  const cutLength = maxLength - ELLIPSIS.length;
  return string.length > maxLength ? `${string.substring(0, cutLength)}${ELLIPSIS}` : string;
}

export function pluralize(word, number, plural = `${word}s`) {
  return number === 1 ? word : plural;
}

export function toSnakeCase(string) {
  return string.replace(/([A-Z]+)/g, (match) => `_${match.toLowerCase()}`);
}

export function toCamelCase(string) {
  return string.replace(/_(\w)/g, (match, p1) => p1.toUpperCase());
}

export function toTitleCase(text) {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function isString(text) {
  return typeof text === 'string';
}

export function getInterpolatedString(text, values) {

  text = text?.replace(/\$?{(.*?)}/g, (match, key) => {

    const value = values[key.trim()];

    if (React.isValidElement(value)) {
      return ''
    }
    return value;
  });

  return text;
}