import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allowSwitchPlans: false,
  switchPlansUrl: null
};

export const sessionDetails = createSlice({
  name: "sessionDetails",
  initialState,
  reducers: {
    updateURLParams: (state, action) => {
      console.log(action);
      let { allowSwitchPlans, switchPlansUrl } = action.payload;
      state.switchPlansUrl = switchPlansUrl;
      if (allowSwitchPlans)
        state.allowSwitchPlans = allowSwitchPlans.toLowerCase() === 'true';
    }
  },
});

export const parseURLParams = (searchParams) => (dispatch) => {
  let obj = {};
  searchParams = Array.from(searchParams);

  for (let i = 0; i < searchParams.length; i++) {
    console.log();
    let key = searchParams[i][0];
    let val = searchParams[i][1];

    obj[key] = val;
  }

  dispatch(updateURLParams(obj));
}


export const {
  updateURLParams
} = sessionDetails.actions;

export default sessionDetails.reducer;
