import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";
import { baseURL } from "../settings.js/dev";
import localStore from "../utils/localstore";
import { USER_PAYMENT_METHOD_PREFERENCE } from "../utils/constants";

const estimate = {
    taxAmount: 0,
    discountAmount: 0,
    subTotal: 0,
    total: 0,
    nextTotal: 0,
    trialPeriod: null,
    trialPeriodUnit: null,
    description: '',
    meta: {},
    displayTax: false,
    creditsTotal: 0,
    thankyouPageType: null
}

const flexTrack = {
    orderNumber: null,
    orderAmount: null,
    coupon: null,
    currencyCode: null
}

const initialState = {
    count: 0,
    items: {},
    promoCode: [],
    estimate,
    flexTrack,
    displayPurchaseComplete: false,
    paymentOption: {
        paymentIntentId: null,
        paymentToken: null,
        PaymentSourceId: null,
    },
    checkoutPayload: null,
    ecommerceGtmEvent: null,
    userPaymentMethodPreference: USER_PAYMENT_METHOD_PREFERENCE.CARD
}

export const cart = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart: (state, action) => {
            let { count, items, promoCode } = action.payload;
            state.items = JSON.parse(JSON.stringify(items));
            state.count = count;
            state.promoCode = promoCode;
            state.displayPurchaseComplete = false;
        },
        updateEstimate: (state, action) => {
            let estimate = action.payload;
            state.estimate = estimate;
            localStore.set('thankyouPageType', estimate.thankyouPageType);
        },
        updateItemMeta: (state, action) => {
            let { description, meta } = action.payload;
            state.description = description;
            state.meta = meta;
        },
        setDisplayTax: (state, action) => {
            state.estimate.displayTax = action.payload.displayTax;
        },
        updateNextTotal: (state, action) => {
            state.estimate.nextTotal = action.payload.nextTotal;
        },
        updateFlexTrack: (state, action) => {
            let { orderNumber, orderAmount, coupon, currencyCode } = action.payload.flexTrack;
            let flexTrack = { orderNumber, orderAmount, coupon, currencyCode };
            state.flexTrack = flexTrack;
        },
        updateDisplayPurchaseComplete: (state, action) => {
            state.displayPurchaseComplete = action.payload.displayPurchaseComplete;
        },
        updatePaymentIntentId: (state, action) => {
            state.paymentOption = {
                paymentIntentId: action.payload.paymentIntentId
            }
        },
        updatePaymentToken: (state, action) => {
            state.paymentOption = {
                paymentToken: action.payload.paymentToken
            }
        },
        updatePaymentSource: (state, action) => {
            state.paymentOption = {
                paymentSourceId: action.payload.paymentSourceId
            }
        },
        setCheckoutPayload: (state, action) => {
            state.checkoutPayload = action.payload.payload;
        },
        setEcommerceGTMEvent: (state, action) => {
            state.ecommerceGtmEvent = action.payload.ecommerceGtmEvent;
        },
        resetEstimate: (state, action) => {
            state.estimate = estimate;
        },
        updateUserPaymentMethodPreference: (state, action) => {
            state.userPaymentMethodPreference = action.payload.preference;
        },
        resetCart: () => initialState

    }
});

export const fetchEstimateForNewSubscription = (payload) => async (dispatch) => {
    const response = await api.post(baseURL, '/checkout/estimate', payload);
    dispatch(updateEstimate(response));
    return response;
}

export const fetchEstimateForUpdateSubscription = (payload) => async (dispatch) => {
    const response = await api.post(baseURL, '/checkout/update-estimate', payload);
    dispatch(updateEstimate(response));
    return response;
}

export const fetchRenewalEstimate = (email) => async (dispatch) => {
    const response = await api.get(baseURL, `/checkout/renewal-estimate/${email}`);
    dispatch(updateNextTotal(response));
    return response;
}

export const { setCart, updateEstimate, updateItemMeta, setDisplayTax, resetCart, updateNextTotal, updateFlexTrack, updateDisplayPurchaseComplete, resetEstimate, updatePaymentIntentId, updatePaymentToken, updatePaymentSource, setCheckoutPayload, setEcommerceGTMEvent, updateUserPaymentMethodPreference } = cart.actions;

export default cart.reducer;