import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";
import { baseURL } from "../settings.js/dev";

const initialState = {
    plans: {
        currencyCode: "",
        planTypes: [],
        features: [],
        subscribedPlanPeriod: ""
    }
}

export const itemDetails = createSlice({
    name: 'itemDetails',
    initialState,
    reducers: {
        addPlan: (state, action) => {
            let { currencyCode, planTypes, features, subscribedPlanPeriod } = action.payload;
            let plans = { currencyCode, planTypes, features, subscribedPlanPeriod };
            state.plans = plans;
        }
    }
});

export const fetchAllPlans = () => async (dispatch) => {
    const response = await api.get(baseURL, '/billing/upgrade-plans');
    const subscribedPlanPeriod = updateSubscribedPlanPeriod(response.planTypes);
    dispatch(addPlan({ subscribedPlanPeriod, ...response }));
    return response;
}

export const fetchItemDetails = async (payload) => {
    const response = await api.post(baseURL, '/checkout/get-items-details', payload);
    return response;
};


const updateSubscribedPlanPeriod = (planTypes) => {
    for (const planType of planTypes) {
        const plans = planType.plans || [];
        const selectedPrice = plans
            .flatMap(plan => plan.itemPrices || [])
            .find(itemPrice => itemPrice.isSelected);

        if (selectedPrice) {
            return selectedPrice.periodUnit
        }
    }
    return "";
};


export const { addPlan } = itemDetails.actions;

export default itemDetails.reducer;