import React, { useEffect, useState } from 'react';
import PrivacyPolicy from './privacyPolicy';
import RefundPolicy from './refundPolicy';
import useModalUtils from '../../../utils/globalUtils';
import { getUserLocation } from '../../../reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import localStore from '../../../utils/localstore';
import { TRANSLATION_TYPE } from '../../../utils/constants';
import { getTranslation } from '../../../reducers/translation';
import { getInterpolatedString } from '../../../utils/text';

const currentYear = new Date().getFullYear();

export default function Footer() {
    const AU = "AU";
    const [displayAUFooter, setDisplayAUFooter] = useState(localStore.get("userLocation") === AU);
    const dispatch = useDispatch();
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    useModalUtils();

    useEffect(() => {
        if (!localStore.get('userLocation')) {
            dispatch(getUserLocation()).then((response) => {
                setDisplayAUFooter(response.country_code === AU);
            })
        }
    }, [])

    return (
        <>
            <footer className="footer">
                <div className="grid-cont">
                    <div className="footer__cont">
                        <div className="copyrights">{getInterpolatedString(t_message?.['footer.message.reserved_rights'], {
                            'currentYear': currentYear
                        }) ?? `© VectorVest, Inc.® ${currentYear}. All Rights Reserved.`}</div>
                        <nav className="legal-nav">
                            {/* <a href="#" data-modal-id="privacy-policy" className="legal-nav__link">Privacy Policy</a>
                            <a href="#" data-modal-id="refund-policy" className="legal-nav__link">Refund Policy</a> */}
                            <a href="https://www.vectorvest.com/privacy-policy/" target="_blank" className="legal-nav__link">{t_label?.['footer.label.privacy_policy'] ?? 'Privacy Policy'}</a>
                            <a href="https://www.vectorvest.com/refund-policy/" target="_blank" className="legal-nav__link">{t_label?.['footer.label.refund_policy'] ?? 'Refund Policy'}</a>
                        </nav>
                    </div>
                    {
                        displayAUFooter &&
                        <div class="mt-4" dangerouslySetInnerHTML={{ __html: t_message?.['checkout.message.au_info'] }} />
                    }
                </div>
            </footer>

            <PrivacyPolicy />
            <RefundPolicy />
        </>
    )
}