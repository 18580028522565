import React, { useState } from 'react'
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { useSelector } from 'react-redux';
import { TRANSLATION_TYPE } from '../../utils/constants';
import { getTranslation } from '../../reducers/translation';

const libraries = ['places'];

const GoogleMapAddress = (props) => {
    const { googleMapKey } = useSelector((state) => (state.chargebee));
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    const [searchResult, setSearchResult] = useState({});
    const [address, setAddress] = useState(props.address ?? "");
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapKey,
        libraries: libraries
    });



    const handleInputChange = (event) => {
        setAddress(event.target.value)
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const handleAddressAutoComplete = () => {
        let address = '';
        let zip = '';
        let city = '';
        let state = '';
        let stateCode = '';
        let country = '';

        if (searchResult != null) {
            const place = searchResult.getPlace();
            const address_components = place.address_components

            address_components.forEach((component) => {
                const { short_name, long_name } = component;
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number':
                        address = long_name + ' ';
                        break;
                    case 'route':
                        address += short_name;
                        break;
                    case 'locality':
                        city = long_name;
                        break;
                    case 'postal_town':
                        city = long_name;
                        break;
                    case 'administrative_area_level_1':
                        stateCode = short_name;
                        state = long_name;
                        break;
                    case 'postal_code':
                        zip = long_name;
                        break;
                    case 'country':
                        country = short_name;
                        break;
                    default:
                }
            });
        }

        setAddress(address);

        props.callback({ address, city, state, stateCode, zip, country });
    };

    function hidePacContainer() {
        var pacContainer = document.querySelector('.pac-container');
        if (pacContainer) {
            pacContainer.style.display = 'none';
        }
    }

    return (
        <div>
            {
                isLoaded &&
                <Autocomplete
                    onLoad={autocomplete => setSearchResult(autocomplete)}
                    onPlaceChanged={handleAddressAutoComplete}
                    options={{
                        fields: [
                            'address_components'
                        ],
                    }}
                >
                    <input
                        type="text"
                        className={props.className ?? "personal-details__input"}
                        placeholder={t_label?.['checkout.label.address_line_1'] ?? "Address line 1"}
                        name="address"
                        id="address"
                        value={address}
                        required
                        data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                        onChange={handleInputChange}
                        onBlur={hidePacContainer}
                        data-pristine-maxLength="150"
                        data-pristine-maxlength-message={t_message?.['checkout.message.address_line_1_min_len'] ?? "Address line 1 cannot be more than 150 characters"}
                    />
                </Autocomplete>
            }
        </div>
    )
}

export default GoogleMapAddress