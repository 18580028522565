import React from 'react'
import CurrencyFormat from 'react-currency-format';
import getSymbolFromCurrency from 'currency-symbol-map'
import localStore from '../../utils/localstore';

const decimalSeparatedCurrency = ['EUR'];
const suffixCurrency = ["EUR"]

export const displayPrice = (price, currencyCode) => {
    if (currencyCode)
        return <PriceCurrency price={price ?? 0} currencyCode={currencyCode} />
    else
        return '';
}

const isNonUSDollar = (currencyCode) => {
    let countryCode = localStore.get("userLocation");
    if (currencyCode === 'USD' && countryCode != null && countryCode != "US")
        return true;
    return false;
}

const PriceCurrency = (props) => {
    const thousandSeparator = decimalSeparatedCurrency.includes(props.currencyCode) ? '.' : ',';
    const decimalSeparator = decimalSeparatedCurrency.includes(props.currencyCode) ? ',' : '.';
    const prefix = !suffixCurrency.includes(props.currencyCode) ? getSymbolFromCurrency(props.currencyCode) : '';
    const suffix = isNonUSDollar(props.currencyCode) ? " USD" : suffixCurrency.includes(props.currencyCode) ? getSymbolFromCurrency(props.currencyCode) : '';

    return (
        <CurrencyFormat
            value={props.price}
            displayType={'text'}
            isNumericString={true}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            prefix={prefix}
            suffix={suffix}
        />
    )
}

export default PriceCurrency