import Papa from "papaparse";

export const parseCSV = async (filePath) => {
  let result = await fetch(filePath);
  let csvData = await result.text();

  const parsedData = await Papa.parse(csvData, {
    complete: csvData.data
  });

  return parsedData.data;
}