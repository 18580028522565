/**
 * Author: Harsh Agrawal
 * Summary: This page facilitates the creation of a new user account in AWS Cognito.
 */


import React, { useState, useEffect } from 'react'
import Header from '../../../components/layout/header';
import Footer from '../../../components/layout/footer';
import OrderSummary from '../../../components/orderSummary';
import { createAccountForNewUser } from '../../../reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { validateForm } from '../../../utils/form';
import { useNavigate } from 'react-router-dom';
import { displayError, displaySuccess } from '../../../reducers/snackbar';
import { focusAndOpenKeyboard } from '../../../utils/globalUtils';
import { displayPrice } from '../../../components/currencyPrice';
import { usePreventBackNavigation } from '../../../utils/globalUtils';
import { deletePacContainer } from '../../../utils/globalUtils';
import { fetchRenewalEstimate, updateDisplayPurchaseComplete } from '../../../reducers/cart';
import { checkUserStatus } from '../../../reducers/auth';
import { TRANSLATION_TYPE } from '../../../utils/constants';
import { getTranslation } from '../../../reducers/translation';
import { createSubscription } from '../../../reducers/billingDetails';
import { CHECKOUT_STATUS } from '../../../utils/constants';
import { salesforcePayload } from '../../../utils/functions';
import { createCampaignMember } from '../../../reducers/salesforce';
import Localization from '../../../components/translation';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

function CreateAccount() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [displayTemporaryPasswordMsg, setDisplayTemporaryPasswordMsg] = useState(false);

    const { email, userStatus } = useSelector((state) => (state.auth))
    const { estimate, checkoutPayload, paymentOption } = useSelector((state) => state.cart);
    const { nextTotal, trialPeriod, trialPeriodUnit, periodUnit, subStatus, currencyCode } = estimate;
    const { gdprEmailUpdates } = useSelector((state) => state.accountDetails);
    const [disableBtn, setDisableBtn] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    const location = useLocation();

    // usePreventBackNavigation();
    deletePacContainer();

    const updatePassword = (e) => {
        setPassword(e.target.value);
    }

    const updateConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const sendGTMEvent = (gtm_event) => {
        if (gtm_event != null) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'cb_ecommerce',
                    pagePath: location.pathname,
                    pageTitle: document.title,
                    ecommerce_order: gtm_event
                }
            });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm("create-account__form")) {

            let payload = checkoutPayload;

            if (paymentOption?.paymentToken)
                payload = { ...checkoutPayload, PaymentTokenId: paymentOption.paymentToken };
            else if (paymentOption?.paymentIntentId)
                payload = { ...checkoutPayload, PaymentIntentId: paymentOption.paymentIntentId };
            else if (paymentOption?.paymentSourceId)
                payload = { ...checkoutPayload, paymentSourceId: paymentOption.paymentSourceId };
            setDisableBtn(true);
            dispatch(createSubscription(payload)).then((response) => {
                sendGTMEvent(response?.subscriptionGTMEvent);
                createSalesforceCampaign(payload?.billingDetail);
                dispatch(updateDisplayPurchaseComplete({ displayPurchaseComplete: true }))

                if (userStatus === "NEW") {
                    dispatch(createAccountForNewUser({ email, password, confirmPassword })).then((response) => {
                        navigate("/checkout/thankyou")
                    }).catch((error) => {
                        setDisableBtn(false);
                        dispatch(displayError(error));
                    })
                }
                else
                    navigate("/checkout/thankyou")

            }).catch((error) => {
                setDisableBtn(false);
                dispatch(displayError(error));
            })

        }
    };

    useEffect(() => {
        // dispatch(fetchRenewalEstimate(email)).catch((error) => {
        //     displayError(error);
        // })

        checkForTemporaryPassword();

        focusAndOpenKeyboard(document.getElementById('password'), 300);

        const eyeIcons = document.querySelectorAll('.personal-details__icon--eye');

        const handleClick = (event) => {
            eyeIcons.forEach((item) => {
                item.classList.toggle('crossed');
                if (item.classList.contains('crossed')) {
                    item.nextElementSibling.type = 'text';
                    item.querySelector('span').textContent = t_label?.['account.label.hide_password'] ?? 'Hide password';
                } else {
                    item.nextElementSibling.type = 'password';
                    item.querySelector('span').textContent = t_label?.['account.label.show_password'] ?? 'Show password';
                }
            });
        };

        eyeIcons.forEach((item) => {
            item.addEventListener('click', handleClick);
        });

        return () => {
            eyeIcons.forEach((item) => {
                item.removeEventListener('click', handleClick);
            });
        };
    }, []);

    // useEffect(() => {
    //     const delayInMilliseconds = 16 * 60 * 1000;
    //     const timeoutId = setTimeout(checkForTemporaryPassword, delayInMilliseconds);

    //     return () => {
    //         clearTimeout(timeoutId);
    //     }
    // }, [])

    const checkForTemporaryPassword = () => {
        if (email) {
            dispatch(checkUserStatus(email)).then((response) => {
                if (response?.userStatus === "FORCE_CHANGE_PASSWORD") {
                    setDisplayTemporaryPasswordMsg(true);
                }
            })
        }
    }

    const createSalesforceCampaign = ({ firstName, lastName }, checkoutStatus = CHECKOUT_STATUS.COMPLETED) => {
        let payload = salesforcePayload({ firstName, lastName, email, checkoutStatus });
        if (gdprEmailUpdates !== undefined) {
            payload = { ...payload, 'OptinMarketing': gdprEmailUpdates ? "YES" : "NO" }
        }
        return dispatch(createCampaignMember(payload))
    }

    const getPeriodText2 = (periodUnit) => {
        if (periodUnit) {
            if (periodUnit === "Month")
                return t_label?.['checkout.label.monthly'] ?? "monthly"
            else if (periodUnit === "Year")
                return t_label?.['checkout.label.annual'] ?? "annual"
            else if (periodUnit === "Day")
                return t_label?.['checkout.label.day'] ?? "day"
        }
        return null;
    }

    return (
        <>
            <div className="body body--alt2">
                <Header />

                <main className="main main--fullh">
                    <section className="section personal-details">
                        <div className="grid-cont">

                            {
                                !displayTemporaryPasswordMsg &&
                                <form onSubmit={handleSubmit} noValidate id="create-account__form" className="personal-details__form">
                                    <h2 className="personal-details__heading">
                                        {
                                            userStatus === "NEW" ?
                                                t_label?.['checkout.label.confirm_purchase_and_create_account'] ?? 'Confirm Purchase & Create Account'
                                                :
                                                t_label?.['checkout.label.confirm_purchase'] ?? 'Confirm Purchase'
                                        }
                                    </h2>

                                    <label className="personal-details__label">{t_label?.['checkout.label.your_email'] ?? 'Your Email'}</label>
                                    <div className="form-group personal-details__control personal-details__control--hastip">
                                        <div className="personal-details__icon personal-details__icon--prefix">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M11.5 7.5H4.5C3.94772 7.5 3.5 7.94772 3.5 8.5V12C3.5 12.5523 3.94772 13 4.5 13H11.5C12.0523 13 12.5 12.5523 12.5 12V8.5C12.5 7.94772 12.0523 7.5 11.5 7.5Z" />
                                                <path d="M5.5 7.5V5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5V7.5" />
                                            </svg>
                                            <div className="personal-details__tooltip">
                                                <span>{t_message?.['checkout.message.fixed_email'] ?? "This email address was entered earlier in the process and cannot be changed. If you would like to use another email address, please start over."}</span>
                                            </div>
                                        </div>
                                        <input disabled type="email" value={email} name="email" id="email" className="personal-details__input" />
                                    </div>
                                    {userStatus === "NEW" &&
                                        <>
                                            <label className="personal-details__label">{t_label?.['checkout.label.new_password'] ?? 'New Password'}</label>
                                            <div className="form-group personal-details__control">
                                                <button type="button" tabIndex="-1" className="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye">
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                                                        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                                                    </svg>
                                                    <div className="personal-details__tooltip personal-details__tooltip--auto">
                                                        <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                                                    </div>
                                                </button>
                                                <input required type="password" name="password" id="password" className="personal-details__input" onChange={updatePassword} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} />
                                            </div>

                                            <label className="personal-details__label">{t_label?.['account.label.confirm_password'] ?? 'Confirm Password'}</label>
                                            <div className="form-group personal-details__control">
                                                <button type="button" tabIndex="-1" className="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye">
                                                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                                                        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                                                    </svg>
                                                    <div className="personal-details__tooltip personal-details__tooltip--auto">
                                                        <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                                                    </div>
                                                </button>
                                                <input required type="password" name="password2" data-pristine-equals="#password" data-pristine-equals-message={t_message?.['account.message.password_not_matching']} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} id="password2" className="personal-details__input" onChange={updateConfirmPassword} />
                                            </div>

                                        </>
                                    }

                                    {
                                        subStatus === "InTrial" &&
                                        <label className="form-group personal-details__checkbox">
                                            <input id='agreement' required type="checkbox" name="agreement" />

                                            <Localization text={t_message?.['checkout.message.subscription_trial_billing']} values={{
                                                trialPeriod,
                                                trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                                                amount: displayPrice(nextTotal, currencyCode),
                                                periodUnit: getPeriodText2(periodUnit)?.toLowerCase()
                                            }}
                                            />
                                            &nbsp;
                                            <Localization text={t_message?.['checkout.message.subscription_trial_billing_acknowledge']} values={{
                                                trialPeriod,
                                                trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                                                periodUnit: getPeriodText2(periodUnit)?.toLowerCase()
                                            }}
                                            />

                                        </label>
                                    }

                                    <label className="form-group personal-details__checkbox">
                                        <input id='accept_tos' required type="checkbox" name="accept_tos" />
                                        <span dangerouslySetInnerHTML={{ __html: t_message?.['checkout.message.agree_to_policy'] }} />
                                    </label>

                                    <button disabled={disableBtn} type="submit" className={`button payment-details__submit ${disableBtn ? 'disabled' : ''}`}>{t_label?.['checkout.label.complete_purchase']}</button>
                                </form>
                            }

                            {
                                displayTemporaryPasswordMsg &&
                                <>
                                    <div className="personal-details__heading">{t_message?.['checkout.message.create_password'] ?? 'Create Password to Access VectorVest'}</div>
                                    <div className='mt-5'>{t_message?.['checkout.message.temporary_email'] ?? 'A temporary password has been sent to your email'}</div>

                                </>
                            }

                            <OrderSummary />

                        </div>
                    </section>
                </main>

                <Footer />

            </div>
        </>
    )
}

export default CreateAccount