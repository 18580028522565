/**
 * Author: Harsh Agrawal
 * Summary: This page represents a new checkout experience. Users can utilize this checkout page to initiate a new subscription. After successful checkout, users will be redirected to the create account page.
 */


import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../../components/layout/header';
import Footer from '../../../components/layout/footer';
import OrderSummary from '../../../components/orderSummary';
import { validateForm } from '../../../utils/form';
import { createCheckoutForNewSubscription, createIntent, initChargebee } from '../../../reducers/chargebee';
import { createSubscription } from '../../../reducers/billingDetails';
import { useNavigate, useSearchParams } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { focusAndOpenKeyboard } from '../../../utils/globalUtils';
import { createItemPayload } from '../../../utils/cart';
import { fetchEstimateForNewSubscription, setCheckoutPayload, setDisplayTax, updateDisplayPurchaseComplete, updatePaymentIntentId, updatePaymentToken } from '../../../reducers/cart';
import { displayError } from '../../../reducers/snackbar';
import GoogleMapAddress from '../../../components/address';
import { getStateCodeFromCountryCodeAndState } from '../../../utils/stateCodeMapping';
import CBCardComponent from '../../../components/cardComponent';
import { useLocation } from 'react-router-dom'
import { parseError } from '../../../utils/error';
import ErrorAlert from '../../../components/Alert';
import { displayPrice } from '../../../components/currencyPrice';
import { getPeriodText2 } from '../../../utils/order';
import { createCampaignMember } from "../../../reducers/salesforce";
import { CHECKOUT_STATUS } from "../../../utils/constants";
import { salesforcePayload } from "../../../utils/functions";
import { TRANSLATION_TYPE } from '../../../utils/constants';
import { getTranslation } from '../../../reducers/translation';
import { getInterpolatedString } from '../../../utils/text';
import Localization from '../../../components/translation';
import sepaLogo from './../../../assets/img/sepa-logo.svg'
import bancontactLogo from './../../../assets/img/bancontact-logo.svg'
import appleLogo from './../../../assets/img/apple-logo-black.svg'
import { parseTokenFromSearchUrl } from '../../../utils/jwt';


function NewUserBillingDetails() {
    const { state } = useLocation();
    const { items, promoCode, count, estimate } = useSelector((state) => state.cart)
    const [displayCardComponent, setDisplayCardComponent] = useState(true)
    const { userStatus } = useSelector((state) => state.auth);
    const { nextTotal, trialPeriod, trialPeriodUnit, periodUnit, subStatus, currencyCode, total } = estimate;
    const { gdprEmailUpdates } = useSelector((state) => state.accountDetails);
    const { locale } = useSelector((state) => state.translation);
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}
    const [displayApplePayBtn, setDisplayApplePayBtn] = useState(false);
    const [disableCheckoutBtn, setDisableCheckoutBtn] = useState(false);
    const [billingEstimateFetched, setBillingEstimateFetched] = useState(false);
    const { countryCode } = useSelector((state) => state.auth);
    const [searchParams, setSearchParams] = useSearchParams();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        stateCode: '',
        zip: '',
        country: countryCode ?? 'US',
        promoCode: promoCode,
        trader: false,
        isSubmitted: false
    });

    const { isCbInitiated } = useSelector((state) => state.chargebee);
    const { cbInstance } = useSelector((state) => state.chargebee);
    const { email } = useSelector((state) => (state.auth));
    const [estimatePayload, setEstimatePayload] = useState({});
    const [searchResult, setSearchResult] = useState({});
    const [error, setError] = useState();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const options = useMemo(() => countryList().getData(), [])
    const cardRef = useRef(null);
    const [gpayHandler, setGpayHandler] = useState(null);
    const [applePayHandler, setApplePayHandler] = useState(null)

    useEffect(() => {
        if (!isCbInitiated) {
            dispatch(initChargebee());
        }
        focusAndOpenKeyboard(document.getElementById('first-name'), 300);
        parseTokenFromSearchUrl(searchParams);

        fetchEstimateOnPageReload();
    }, [])

    useEffect(() => {
        setFormData({ ...formData, country: countryCode ?? 'US' })
    }, [countryCode])

    const fetchEstimateOnPageReload = () => {
        dispatch(setDisplayTax({ "displayTax": false }));
        let discount_ = estimate?.discountAmount ?? 0;
        let tax_ = estimate?.taxAmount ?? 0;
        if (discount_ > 0 || tax_ > 0) {
            fetchEstimate(formData, false);
        }
    }

    const handleError = (error) => {
        if (error.Param == null)
            dispatch(displayError(error));
        else
            setError(error);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormData({ ...formData, isSubmitted: true });

        if (validateForm("add-billing-details__form")) {
            createSalesforceCampaign(formData, CHECKOUT_STATUS.INITIATED);
            if (displayCardComponent) {
                if (cardRef.current) {
                    var token = null;
                    try {
                        token = await cardRef.current.tokenize(event);
                        let payload = createHostedPagePayload();
                        payload = { ...payload, PaymentTokenId: token };
                        setError();
                        dispatch(createSubscription(payload)).then((response) => {
                            createSalesforceCampaign(formData);
                            dispatch(updateDisplayPurchaseComplete({ displayPurchaseComplete: true }))
                            if (userStatus === "NEW")
                                navigate("/checkout/createaccount")
                            else if (userStatus === "EXIST_NO_SUB")
                                navigate("/checkout/thankyou")
                        }).catch((error) => {
                            handleError(error);
                        })

                    } catch (error) {
                        console.log(error)
                    }
                }
            } else {
                openCheckout(cbInstance);
            }
        }

    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();
        setFormData({ ...formData, isSubmitted: true });
        let payload = createHostedPagePayload();

        if (validateForm("add-billing-details__form")) {
            if (!billingEstimateFetched) {
                fetchEstimateAndSubmit();
            } else {
                createSalesforceCampaign(formData, CHECKOUT_STATUS.INITIATED);
                dispatch(setCheckoutPayload({ payload }));
                navigate("/checkout/addPaymentMethod");
            }
        }
    };

    const fetchEstimateAndSubmit = async () => {
        if (validateForm("add-billing-details__form")) {
            let payload = createEstimatePayload(formData);
            setEstimatePayload(payload);
            setDisableCheckoutBtn(true);
            dispatch(fetchEstimateForNewSubscription(payload)).then((response) => {
                setBillingEstimateFetched(true);
                dispatch(setDisplayTax({ "displayTax": true }));
                setDisableCheckoutBtn(false);
                createSalesforceCampaign(formData, CHECKOUT_STATUS.INITIATED);
                dispatch(setCheckoutPayload({ payload }));
                navigate("/checkout/addPaymentMethod");
            }).catch((error) => {
                handleError(error);
            })
        }
    }

    const validateBillingForm = () => {
        setTimeout(() => {
            validateForm("add-billing-details__form");
        }, 50);
    }

    const handleInputChange = event => {
        const { name, value, type, checked } = event.target;

        // Handle checkboxes separately
        const inputValue = type === 'checkbox' ? checked : value;

        if (event.target.id === 'state') {
            setFormData(prevData => ({
                ...prevData,
                [name]: inputValue,
                'stateCode': getStateCodeFromCountryCodeAndState(formData.country, inputValue)
            }));
        } else if (event.target.id === 'promo-code') {
            setFormData(prevData => ({
                ...prevData,
                [name]: inputValue.split(',').filter(value => value.trim() !== '')
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: inputValue
            }));
        }
    };

    const handleAddressAutoComplete = () => {
        let address = '';
        let zip = '';
        let city = '';
        let state = '';
        let stateCode = '';
        let country = '';

        if (searchResult != null) {
            const place = searchResult.getPlace();
            const address_components = place.address_components

            address_components.forEach((component) => {
                const { short_name, long_name } = component;
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number':
                        address = long_name + ' ';
                        break;
                    case 'route':
                        address += short_name;
                        break;
                    case 'locality':
                        city = long_name;
                        break;
                    case 'postal_town':
                        city = long_name;
                        break;
                    case 'administrative_area_level_1':
                        stateCode = short_name;
                        state = long_name;
                        break;
                    case 'postal_code':
                        zip = long_name;
                        break;
                    case 'country':
                        country = short_name;
                        break;
                    default:
                }
            });
        }

        let formData_ = { ...formData, address, city, state, stateCode, zip, country };

        setFormData(formData_);

        focusAndOpenKeyboard(document.getElementById('address2'));

        if (formData.isSubmitted)
            validateBillingForm();

        fetchEstimate(formData_);
    };

    const openCheckout = (cbInstance) => {
        setError();
        dispatch(createCheckoutForNewSubscription(createHostedPagePayload())).then((response) => {
            createCheckoutPopup(cbInstance, response);
        }).catch((error) => {
            handleError(error);
        })
    }


    const createCheckoutPopup = (cbInstance, payload) => {
        cbInstance.openCheckout({
            hostedPage: () => {
                const hostedPageData = payload;
                return Promise.resolve(hostedPageData);
            },
            success(hostedPageId) {
                createSalesforceCampaign(formData);
                dispatch(updateDisplayPurchaseComplete({ displayPurchaseComplete: true }))
                if (userStatus === "NEW")
                    navigate("/checkout/createaccount")
                else if (userStatus === "EXIST_NO_SUB")
                    navigate("/checkout/thankyou")
                cbInstance.closeAll();
            },
            close: () => {

            },
            step(step) {
            }
        });
    }

    const createHostedPagePayload = () => {

        const items_ = createItemPayload(items, count);

        const stateCodeSupportedCountry = ["IN", "US", "CA"];

        let billingDetails_ = {
            "firstName": `${formData.firstName}`,
            "lastName": `${formData.lastName}`,
            "addressLine1": `${formData.address}`,
            "addressLine2": `${formData.address2}`,
            "city": `${formData.city}`,
            "zip": `${formData.zip}`,
            "country": `${formData.country}`,
            "stateCode": formData.stateCode ? `${formData.stateCode}` : getStateCodeFromCountryCodeAndState(formData.country, formData.state)
        };

        if (!stateCodeSupportedCountry.includes(formData.country))
            billingDetails_["state"] = formData.state;

        let payload = {
            "billingDetail": billingDetails_,
            "email": `${email}`,
            "items": items_,
        }

        if (formData.promoCode) {
            payload = { ...payload, promoCode: formData.promoCode };
        }

        payload["investorOrTradder"] = formData.trader;

        return payload;
    }

    const createEstimatePayload = (formData) => {
        const items_ = createItemPayload(items, count);
        let isAddressPopulated = (formData.city && formData.state && formData.zip && formData.country) ? true : false;
        let billingAddress = {};

        if (isAddressPopulated) {
            billingAddress = {
                "firstName": `${formData.firstName}`,
                "lastName": `${formData.lastName}`,
                "addressLine1": `${formData.address}`,
                "addressLine2": formData.address2 !== null ? `${formData.address2}` : null,
                "city": `${formData.city}`,
                "state": `${formData.state}`,
                "zip": `${formData.zip}`,
                "country": `${formData.country}`
            }
        }

        let payload = {
            "billingDetail": billingAddress,
            "items": items_,
        }

        if (formData.promoCode) {
            payload = { ...payload, promoCode: formData.promoCode };
        }

        if (email)
            payload = { ...payload, email };

        setEstimatePayload(payload);

        return payload;
    }

    const fetchEstimate = (formData, formCheck = true) => {
        let isAddressPopulated = (formData.city && formData.state && formData.zip && formData.country) ? true : false;
        let valid = !formCheck || isAddressPopulated;
        if (valid) {
            setError();
            let payload = createEstimatePayload(formData);
            setEstimatePayload(payload);
            setDisableCheckoutBtn(true);
            dispatch(fetchEstimateForNewSubscription(payload)).then((response) => {
                if (isAddressPopulated)
                    setBillingEstimateFetched(true);
                dispatch(setDisplayTax({ "displayTax": true }));
                setDisableCheckoutBtn(false);
            }).catch((error) => {
                handleError(error);
            })
        } else {
            dispatch(setDisplayTax({ "displayTax": false }));
        }
    }

    const fetchEstimateOnInputChanges = (event) => {
        let previousValue = estimatePayload?.billingDetail?.[event.target.name];

        if (event.relatedTarget == document.getElementById("continue_to_payment")) {
            fetchEstimateAndSubmit();
        } else if (event.target.value !== previousValue)
            fetchEstimate(formData);
    }

    const autoCompleteAddress = ({ address, city, state, stateCode, zip, country }) => {
        let formData_ = { ...formData, address, city, state, stateCode, zip, country };

        setFormData(formData_);

        focusAndOpenKeyboard(document.getElementById('address2'));

        if (formData.isSubmitted)
            validateBillingForm();

        fetchEstimate(formData_);
    }

    const handleCountryAndStateUpdate = (e) => {
        let stateCode = getStateCodeFromCountryCodeAndState(formData.country, formData.state);
        if (stateCode != null) {
            setFormData({ ...formData, stateCode });
        }

        fetchEstimateOnInputChanges(e);
    }

    const createSalesforceCampaign = ({ firstName, lastName }, checkoutStatus = CHECKOUT_STATUS.COMPLETED) => {
        let payload = salesforcePayload({ firstName, lastName, email, checkoutStatus });
        if (gdprEmailUpdates !== undefined) {
            payload = { ...payload, 'OptinMarketing': gdprEmailUpdates ? "YES" : "NO" }
        }
        return dispatch(createCampaignMember(payload))
    }

    return (
        <>
            {
                isCbInitiated &&
                <div className="body body--alt2">
                    <Header />

                    <main className="main main--fullh">
                        <section className="section personal-details">
                            <div className="grid-cont">
                                <form onSubmit={handleSubmit2} noValidate id="add-billing-details__form" className="personal-details__form">
                                    <h2 className="personal-details__heading">{t_label?.['checkout.label.billing_details'] ?? 'Billing Details'}</h2>
                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <label htmlFor="first-name" className="personal-details__label">{t_label?.['checkout.label.first_name'] ?? 'First Name'}</label>
                                                <input required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    name="firstName"
                                                    placeholder={t_label?.['checkout.label.first_name'] ?? 'First Name'}
                                                    value={formData.firstName}
                                                    id="first-name"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="150"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.first_name_min_len'] ?? 'First name cannot have more than 150 characters'}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <label htmlFor="last-name" className="personal-details__label">{t_label?.['checkout.label.last_name'] ?? 'Last Name'}</label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    name="lastName"
                                                    placeholder={t_label?.['checkout.label.last_name'] ?? 'Last Name'}
                                                    value={formData.lastName}
                                                    id="last-name"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="150"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.last_name_min_len_err'] ?? "Last name cannot have more than 150 characters"}
                                                    onChange={handleInputChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group personal-details__control">
                                        <label htmlFor="address" className="personal-details__label">{t_label?.['checkout.label.address'] ?? 'Address'}</label>
                                        {
                                            isCbInitiated ?
                                                <GoogleMapAddress callback={autoCompleteAddress} onChange={handleInputChange} />
                                                :
                                                <input
                                                    type="text"
                                                    className="personal-details__input"
                                                    placeholder={t_label?.['checkout.label.address_line_1'] ?? "Address line 1"}
                                                    name="address"
                                                    id="address"
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    onChange={handleInputChange}
                                                    data-pristine-maxLength="150"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.address_line_1_min_len'] ?? "Address line 1 cannot be more than 150 characters"}
                                                />
                                        }
                                    </div>
                                    <div className="form-group personal-details__control">
                                        <input
                                            type="text"
                                            name="address2"
                                            placeholder={t_label?.['checkout.label.address_line_2'] ?? "Address line 2"}
                                            value={formData.address2}
                                            id="address2"
                                            className="personal-details__input"
                                            data-pristine-maxLength="150"
                                            data-pristine-maxlength-message={t_message?.['checkout.message.address_line_2_min_len'] ?? "Address line 2 cannot have more than 150 characters"}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <label htmlFor="city" className="personal-details__label">{t_label?.['checkout.label.city'] ?? 'City'}</label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    name="city"
                                                    placeholder={t_label?.['checkout.label.city'] ?? "City"}
                                                    value={formData.city}
                                                    id="city"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="50"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.city_min_len'] ?? "City cannot have more than 50 characters"}
                                                    onChange={handleInputChange}
                                                    onBlur={fetchEstimateOnInputChanges}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <label htmlFor="state" className="personal-details__label">{t_label?.['account.label.state'] ?? 'State'}/{t_label?.['checkout.label.province' ?? 'Province']}</label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    name="state"
                                                    placeholder={`${t_label?.['account.label.state'] ?? 'State'}/${t_label?.['checkout.label.province'] ?? 'Province'}`}
                                                    value={formData.state}
                                                    id="state"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="50"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.state_min_len'] ?? "State cannot have more than 50 characters"}
                                                    onChange={handleInputChange}
                                                    onBlur={fetchEstimateOnInputChanges}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-row">
                                        <div className="grid-col grid-col--4 sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <label htmlFor="zip" className="personal-details__label">{t_label?.['checkout.label.zip_code'] ?? 'Zip Code'}</label>
                                                <input
                                                    required
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    type="text"
                                                    name="zip"
                                                    placeholder={t_label?.['checkout.label.zip_code'] ?? 'Zip Code'}
                                                    value={formData.zip}
                                                    id="zip"
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="20"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.zip_min_len'] ?? "Zip code cannot have more than 20 characters"}
                                                    onChange={handleInputChange}
                                                    onBlur={fetchEstimateOnInputChanges}
                                                />

                                            </div>
                                        </div>
                                        <div className="grid-col grid-col--8 sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <label htmlFor="country" className="personal-details__label">{t_label?.['checkout.label.country'] ?? 'Country'}</label>

                                                <select required name="country" id="country" value={formData.country} className="personal-details__select"
                                                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                                                    onChange={handleInputChange}
                                                    onBlur={fetchEstimateOnInputChanges}
                                                >
                                                    {options.map(option_ =>
                                                        <option key={option_.value} value={option_.value}>{option_.label}</option>
                                                    )}
                                                </select>

                                            </div>
                                        </div>
                                    </div>

                                    <label className="personal-details__checkbox">
                                        <input type="checkbox" value={formData.trader} id="trader" name="trader" onChange={handleInputChange} />
                                        <span>{t_message?.['checkout.message.professional_investor'] ?? "I'm a professional investor/trader"}</span>
                                    </label>

                                    {
                                        formData.promoCode.length < 2 &&
                                        <div className="grid-row mt-4">
                                            <div className="grid-col sm:grid-col--12">
                                                <div className="form-group promo-code">
                                                    <label htmlFor="city" className="personal-details__label mt-0">{t_label?.['checkout.label.enter_a_promo_code'] ?? 'Enter a Promo Code'}</label>
                                                    <input
                                                        type="text"
                                                        name="promoCode"
                                                        value={formData.promoCode}
                                                        id="promo-code"
                                                        placeholder={t_label?.['checkout.label.enter_a_promo_code'] ?? 'Enter a Promo Code'}
                                                        className="personal-details__input"
                                                        data-pristine-maxLength="50"
                                                        data-pristine-maxlength-message={t_message?.['checkout.message.promo_code_min_len'] ?? "Promo code cannot have more than 50 characters"}
                                                        onChange={handleInputChange}
                                                        onBlur={fetchEstimateOnInputChanges}
                                                    />

                                                    {
                                                        error != null && error.Param === 'promocode' &&
                                                        <ErrorAlert error={parseError(error)} bgcolor='#f7f8f9' />
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    <button style={{ 'display': 'block' }} />

                                    <button id="continue_to_payment" disabled={disableCheckoutBtn} type="submit" className={`button payment-details__submit ${disableCheckoutBtn ? 'disabled' : ''}`}>{t_label?.['checkout.button.continue_to_payment'] ?? 'Continue to Payment'}</button>

                                </form>


                                <OrderSummary />
                            </div>
                        </section>

                    </main>

                    <Footer />
                </div>
            }
        </>
    )
}

export default NewUserBillingDetails