import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './../../../assets/css/output.module.css';
import { displayError } from '../../../reducers/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { pauseSubscription } from '../../../reducers/billingDetails'
import { displayPrice } from '../../../components/currencyPrice'
import { addMonthToCurrent, formatDate, formatDate2 } from '../../../utils/date'
import SideBar from '../../../components/layout/sidebar'
import { fetchSubscription } from '../../../reducers/billingDetails';
import Footer from '../../../components/layout/footer';
import { getTranslation } from '../../../reducers/translation';
import { TRANSLATION_TYPE } from '../../../utils/constants';
import { getInterpolatedString } from '../../../utils/text';

function PauseSubscription() {
  const [pauseOption, setPauseOption] = useState(0);
  const navigate = useNavigate();

  const { subscription } = useSelector((state) => state.billingDetails);
  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const dispatch = useDispatch();

  useEffect(() => {
    fetchSubscriptionDetails();
  }, [])

  const fetchSubscriptionDetails = () => {
    dispatch(fetchSubscription()).catch((error) => {
      dispatch(displayError(error));
    })
  }

  const handlePause = (e) => {
    if (pauseOption == 0)
      return;
    let resumeAt = addMonthToCurrent(pauseOption);
    resumeAt = Math.floor(resumeAt.getTime() / 1000);
    let payload = { "ResumeAt": resumeAt }
    dispatch(pauseSubscription(payload)).then(() => {
      navigate("/billing")
    }).catch((error) => {
      dispatch(displayError(error));
    })
  }

  const billedPeriodText = () => {
    if (subscription.billingPeriodUnit === "Year")
      return t_label?.['billing.label.billed_annually'] ?? 'Billed annually'
    else
      return t_label?.['billing.label.billed_monthly'] ?? 'Billed monthly'
  }

  return (
    <>
      <div class={`dark ${styles.body} bg-white pt-22 md:pl-13 md:pt-0 lg:pl-50`}>
        <SideBar page="billing" />
        <div class="min-h-[100dvh] flex flex-col">
          <div class="mb-auto">
            {
              subscription?.plan &&
              <main class={`mx-auto box-content max-w-[540px] p-5 md:py-10 lg:p-12`}>
                <h1 className="text-h1 text-gray-900">{t_label?.['billing.button.pause_subscription'] ?? 'Pause Subscription'}</h1>

                <div className="flex flex-col md:flex-row md:gap-5 lg:gap-10">


                  <hr className="mt-5 border-gray-300/50 md:hidden" />
                  <div className="md:grow">
                    <div className="mt-5 rounded-md bg-gray-0 p-5">

                      <>
                        <div className="lg:flex lg:items-center lg:justify-between">
                          <h1 className="text-h1 text-gray-900">{subscription.plan}</h1>
                          <div className="mt-1 flex items-baseline lg:mt-0">
                            <div className="text-h2 text-gray-900">{displayPrice(subscription.perMonth, subscription.currencyCode)}</div>
                            <div className="text-lg">/{t_label?.['billing.label.month'] ?? 'month'}</div>
                          </div>
                        </div>

                        <div className="mt-1.5 flex items-center justify-between lg:mt-2">
                          <div className="text-sm lg:order-last lg:text-md">{billedPeriodText()}: <span className="text-gray-900">{displayPrice(subscription.billingPeriodUnit === "Year" ? subscription.perAnnual : subscription.perMonth, subscription.currencyCode)}</span></div>
                          {
                            subscription?.status === "Active" &&
                            <div className="text-sm lg:text-md">{t_label?.['billing.label.renews'] ?? 'Renews'}: <span className="text-gray-900">{formatDate(subscription.renewal)}</span></div>
                          }
                        </div>

                        <div className='text-sm mt-5'>
                          {t_message?.['billing.message.pause_subscription_subtitle'] ?? 'You can pause your subscription without any hidden fees or hassles. Need to come back sooner? No problem! Resume your subscription early in your account settings, with all your preferences saved just as you left them.'}
                        </div>

                        {
                          [1, 2, 3].map((option => {
                            return <>

                              <div onClick={(e) => setPauseOption(option)} aria-current={option === pauseOption} className={`border-blue mt-4  rounded-md bg-white py-2.5 px-4 shadow-card hover:bg-gray-100 cursor-pointer aria-current:text-blue-600 aria-current:border-blue`}>
                                <div className="text-sm lg:text-md font-bold">{getInterpolatedString(t_label?.['billing.label.pause_for_month'], { 'option': option })} </div>
                                <div className="text-sm mt-1">{t_label?.['billing.label.resumes'] ?? 'Resumes'}: <span>{formatDate2(addMonthToCurrent(option))}</span></div>
                              </div>
                            </>
                          }))
                        }

                      </>

                    </div>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-2.5 sm:grid-cols-2">
                  <a onClick={(e) => handlePause(e)} className="flex h-[42px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 cursor-pointer">
                    <span className="text-lg font-bold">{t_label?.['billing.button.pause_subscription'] ?? 'Pause Subscription'}</span>
                  </a>

                  <a onClick={(e) => navigate("/billing")} className="flex h-[42px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-3 text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 cursor-pointer">
                    <span className="text-lg font-bold">{t_label?.['billing.label.never_mind'] ?? 'Never mind'}</span>
                  </a>

                </div>
              </main>
            }
          </div>
        </div>
        <Footer />
      </div >

    </>
  )
}

export default PauseSubscription