import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper";
import { getTranslation } from '../../reducers/translation';
import { TRANSLATION_TYPE } from '../../utils/constants';
import { useSelector } from 'react-redux';
import localStore from '../../utils/localstore';

const CBCardComponent = (props, ref) => {
  const cardRef = useRef();
  const { checkoutPayload } = useSelector((state) => state.cart);
  const { email } = useSelector((state) => state.auth);
  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [options, setOptions] = useState({
    classes: {
      focus: 'focus',
      invalid: 'invalid',
      empty: 'empty',
      complete: 'complete',
    },
    style: {
      base: {
        color: '#2a2d5b',
        fontWeight: '500',
        fontFamily: 'Raleway,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':focus': {},
        '::placeholder': {
          color: '#9293AB',
          fontSize: '14px',
        },
        ':focus::placeholder': {
          color: '#666',
        },
        ':-webkit-autofill': {
          webkitTextColor: '#2a2d5b',
        }
      },
      invalid: {
        color: '#e41029',
        ':focus': {
          color: '#e44d5f',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    },
    locale: 'en',
    fonts: [
      'https://fonts.googleapis.com/css?family=Raleway:300,500,600'
    ]
  });

  const tokenize = (e) => {
    return new Promise((resolve, reject) => {
      e.preventDefault();
      setLoading(true);

      let additionalData = getAdditionalDetail();

      cardRef.current.tokenize(additionalData)
        .then((data) => {
          setLoading(false);
          setToken(data.token);
          setErrorMessage("");
          resolve(data.token); // Resolve the Promise with the token
        })
        .catch((error) => {
          setLoading(false);
          setToken("");
          setErrorMessage(error.message);
          // setErrorMessage("Problem while tokenizing your card details");
          reject(error); // Reject the Promise with the error
        });
    });
  };

  useEffect(() => {
    localStore.remove('carderror');
  }, [])


  const authorizeWith3ds = (payment_intent) => {
    return new Promise((resolve, reject) => {
      // e.preventDefault();
      setLoading(true);

      let additionalData = getAdditionalDetail();

      cardRef.current.authorizeWith3ds(payment_intent, additionalData).then((data) => {
        setLoading(false);
        setErrorMessage("");
        resolve(payment_intent);
      }).catch((error) => {
        setLoading(false);
        setErrorMessage(error.message);
        // setErrorMessage("Problem while tokenizing your card details");
        reject(error);
      });
    });
  };

  const getAdditionalDetail = () => {
    let billingDetail = { ...checkoutPayload?.billingDetail, 'countryCode': checkoutPayload?.billingDetail?.country };
    let additionalData = {
      'billingAddress': billingDetail,
      'customerBillingAddress': billingDetail,
      'shippingAddress': billingDetail,
      'email': checkoutPayload?.email ?? email
    };
    return additionalData;
  }


  useImperativeHandle(ref, () => ({
    tokenize,
    authorizeWith3ds
  }));


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFirstName(newValue);
  };

  const setLocale = (e) => {
    e.preventDefault();
    const locale = e.target.id;
    const updatedOptions = { ...options, locale };
    setOptions(updatedOptions);
  };

  const onChange = (event) => {
    let errors = localStore.get('carderror');
    errors = errors != null ? JSON.parse(errors) : {};

    const updatedErrors = { ...errors };
    updatedErrors[event.field] = event?.error?.message;
    let newErrorMessage = updatedErrors?.number ?? updatedErrors?.key ?? updatedErrors?.expiry;

    if (event.error) {
      newErrorMessage = event.error.message;
    }

    localStore.set('carderror', JSON.stringify(updatedErrors), false);
    setErrorMessage(newErrorMessage);
  };

  const { style, classes, locale, fonts } = options;

  return (
    <>
      <CardComponent
        ref={cardRef}
        styles={style}
        classes={classes}
        className="fieldset field"
        locale={locale}
        fonts={fonts}
        onChange={onChange}
      >

        <div className="form-group personal-details__control">
          <label for="cardnumber" class="personal-details__label">{t_label?.['checkout.label.credit_card_number'] ?? 'Credit Card Number'}</label>
          <CardNumber required className="personal-details__input" />
          <i className="cc-bar"></i>
        </div>

        <div class="grid-row">
          <div class="grid-col grid-col--6 md:grid-col--8 sm:grid-col--7 xs:grid-col--8">
            <div className="form-group personal-details__control">
              <label for="exp-date" class="personal-details__label">{t_label?.['checkout.label.expiration_date'] ?? 'Expiration Date'}</label>
              <CardExpiry className=" personal-details__input" />
            </div>
          </div>
          <div class="grid-col grid-col--3 md:grid-col--4 sm:grid-col--5 xs:grid-col--4">
            <label for="cvc" class="personal-details__label">{t_label?.['checkout.label.cvv'] ?? 'CVV'}</label>
            <div class="form-group personal-details__control">
              <div class="personal-details__icon personal-details__icon--postfix">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6819 1.33337 7.99998 1.33337C4.31808 1.33337 1.33331 4.31814 1.33331 8.00004C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667Z" />
                  <path d="M8 10.6667V8" />
                  <path d="M8 5.33337H8.00727" />
                </svg>
                <div class="personal-details__tooltip">
                  <span>{t_message?.['checkout.message.cvv']}</span>
                </div>
              </div>
              <CardCVV className="personal-details__input" />
            </div>
          </div>
        </div>


      </CardComponent>

      {errorMessage && <div className="cc-error" role="alert">{errorMessage}</div>}
    </>
  );
};

export default forwardRef(CBCardComponent);