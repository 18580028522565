import localStore from "./localstore";

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function parseTokenFromSearchUrl(searchParams) {
  for (let i = 0; i < Array.from(searchParams).length; i++) {
    if (searchParams.has('token')) {
      let token = searchParams.get('token');
      localStore.set('token', token);
      return token;
    }
  }
  return null;
}