/**
 * Author: Harsh Agrawal
 * Summary: This page shows the support contacts for various countries.
 */

import React from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../reducers/translation';
import { TRANSLATION_TYPE } from '../../utils/constants';

function SupportPortal() {
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    return (
        <>
            <div aria-hidden="true" id="modal-support" class="group/modal fixed inset-0 flex overflow-auto bg-gray-1000/70 pt-5 transition-opacity aria-hidden:pointer-events-none aria-hidden:opacity-0 sm:p-5">
                <div data-toggle="#modal-support" class="fixed inset-0 cursor-pointer"></div>
                <div class="relative m-auto mb-0 w-full rounded-t-md bg-white p-8 transition-transform group-aria-hidden/modal:translate-y-10 sm:mb-auto sm:max-w-[540px] sm:rounded-b-md">
                    <button type="button" data-toggle="#modal-support" class="absolute top-2.5 right-2.5 hover:text-gray-600">
                        <svg class="h-6 w-6"><use href="/img/sprite24.svg#close"></use></svg>
                    </button>
                    <div class="text-h1 text-gray-900">{t_label?.['support.label.contact_support'] ?? 'Contact Support'}</div>
                    <div class="mt-1 text-gray-500">{t_message?.['support.message.contact_support'] ?? 'We’re here to help you succeed.'}</div>

                    <div class="grid sm:grid-cols-2">
                        <div>
                            <div class="mt-6 text-xs font-bold uppercase text-gray-500">{t_label?.['support.label.telephone'] ?? 'Telephone'}</div>
                            <div class="mt-3 flex items-center gap-2">
                                <img src="/img/flags/us.svg" width="21" height="" alt="" />
                                <img src="/img/flags/ca.svg" width="21" height="" alt="" />
                                <div class="text-gray-600">US {t_label?.['support.label.and'] ?? 'and'} Canada</div>
                            </div>
                            <div class="mt-2">{t_label?.['support.label.product_support'] ?? 'Product Support'}</div>
                            <div class="text-h3 font-bold text-gray-900">1-704-895-4095</div>
                            <div class="mt-2">{t_label?.['support.label.sales'] ?? 'Sales'}</div>
                            <div class="text-h3 font-bold text-gray-900">1-888-658-7638</div>
                        </div>
                        <div>
                            <div class="mt-6 text-xs font-bold uppercase text-gray-500">{t_label?.['checkout.label.email'] ?? 'Email'}</div>
                            <div class="mt-2 text-lg"><a href="mailto:support@vectorvest.com" class="text-blue-600 underline hover:text-blue-700">support@vectorvest.com</a></div>
                            <div class="mt-5 text-xs font-bold uppercase text-gray-500">{t_label?.['support.label.mailing_address'] ?? 'Mailing Address'}</div>
                            <div class="mt-1.5 text-lg text-gray-900">
                                VectorVest, Inc.<br />
                                20472 Chartwell Center Drive<br />
                                Cornelius, NC 28031
                            </div>
                        </div>
                    </div>

                    <hr class="mt-6 border-gray-300/50" />

                    <div class="mt-6 -mb-1 text-xs font-bold uppercase text-gray-500">{t_label?.['support.label.international'] ?? 'International'}</div>

                    <div class="grid sm:grid-cols-2">
                        <div class="">
                            <div class="mt-5 flex items-center gap-2">
                                <img src="/img/flags/au.svg" width="21" height="" alt="" />
                                <div class="text-gray-600">Australia</div>
                            </div>
                            <div class="mt-1.5 text-h3 font-bold text-gray-900">1800-303-782</div>
                            <div class="">2100-1300 AEST, {t_label?.['support.label.days'] ?? 'Mon-Fri'}</div>

                            <div class="mt-5 flex items-center gap-2">
                                <img src="/img/flags/be.svg" width="21" height="" alt="" />
                                <div class="text-gray-600">Belgium</div>
                            </div>
                            <div class="mt-1.5 text-h3 font-bold text-gray-900">0800-261-88</div>
                            <div class="">1pm-5am, {t_label?.['support.label.days'] ?? 'Mon-Fri'}</div>
                        </div>
                        <div class="">
                            <div class="mt-5 flex items-center gap-2">
                                <img src="/img/flags/ge.svg" width="21" height="" alt="" />
                                <div class="text-gray-600">Germany</div>
                            </div>
                            <div class="mt-1.5 text-h3 font-bold text-gray-900">0800-184-4965</div>
                            <div class="">1pm-5am, {t_label?.['support.label.days'] ?? 'Mon-Fri'}</div>

                            <div class="mt-5 flex items-center gap-2">
                                <img src="/img/flags/ne.svg" width="21" height="" alt="" />
                                <div class="text-gray-600">Netherlands</div>
                            </div>
                            <div class="mt-1.5 text-h3 font-bold text-gray-900">0800-022-9159</div>
                            <div class="">1pm-5am, {t_label?.['support.label.days'] ?? 'Mon-Fri'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupportPortal