/**
 * Author: Harsh Agrawal
 * Summary: This page represents the login page. After successful login, users will be redirected to the update checkout page.
 */

import React, { useEffect, useState } from 'react';
import Header from '../../../components/layout/header';
import Footer from '../../../components/layout/footer';
import OrderSummary from '../../../components/orderSummary';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../reducers/auth';
import { useNavigate } from 'react-router-dom';
import { focusAndOpenKeyboard } from '../../../utils/globalUtils';
import { displayError } from '../../../reducers/snackbar';
import arrowsvg from './../../../assets/img/arrow-left-16px.svg';
import { validateForm } from '../../../utils/form';
import ErrorAlert from '../../../components/Alert';
import { parseError } from '../../../utils/error';
import { getTranslation } from '../../../reducers/translation';
import { TRANSLATION_TYPE } from '../../../utils/constants';

export default function Login() {
    const [password, setPassword] = useState("");
    const [error, setError] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { username, userStatus } = useSelector((state) => state.auth);

    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}


    const updatePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleSubmit = (event) => {
        setError();
        event.preventDefault(); // Prevent form submission from reloading the page
        if (validateForm("account-login__form")) {
            dispatch(login({ "username": username, password })).then((response) => {
                if (userStatus === "EXIST")
                    navigate("/checkout/viewbillingdetails");
                else if (userStatus === "EXIST_NO_SUB")
                    navigate('/checkout/addbillingdetails')
            }).catch((error) => {
                if (error.Param == null)
                    dispatch(displayError(error));
                else
                    setError(error);
            })
        }
    };

    useEffect(() => {
        focusAndOpenKeyboard(document.getElementById('loginPassword'), 300);

        const eyeIcons = document.querySelectorAll('.personal-details__icon--eye');

        const handleClick = (event) => {
            eyeIcons.forEach((item) => {
                item.classList.toggle('crossed');
                if (item.classList.contains('crossed')) {
                    item.nextElementSibling.type = 'text';
                    item.querySelector('span').textContent = t_label?.['account.label.hide_password'] ?? 'Hide password';
                } else {
                    item.nextElementSibling.type = 'password';
                    item.querySelector('span').textContent = t_label?.['account.label.show_password'] ?? 'Show password';
                }
            });
        };

        eyeIcons.forEach((item) => {
            item.addEventListener('click', handleClick);
        });

        return () => {
            eyeIcons.forEach((item) => {
                item.removeEventListener('click', handleClick);
            });
        };
    }, []);

    return (
        <>
            <div className="body body--alt2">
                <Header />

                <main className="main main--fullh">
                    <section className="section personal-details">
                        <div className="grid-cont">

                            <div onClick={() => navigate(-1)} className="action-button2">
                                <img src={arrowsvg} alt="" />
                                <div>{t_label?.['account.button.go_back'] ?? 'Go Back'}</div>
                            </div>

                            <form noValidate id="account-login__form" className="personalogin__forml-details__form mt-5" onSubmit={handleSubmit}>
                                <h2 className="personal-details__heading">{t_message?.['checkout.message.login_heading'] ?? 'Welcome Back! Please login to your account to continue'}</h2>

                                <label className="personal-details__label">{t_label?.['checkout.label.your_username'] ?? 'Your Username'}</label>

                                <div className="form-group personal-details__control personal-details__control--hastip">
                                    <input disabled name="username" value={username} id="loginUsername" className="personal-details__input" />
                                </div>

                                <label className="personal-details__label">{t_label?.['checkout.label.password'] ?? 'Password'}</label>
                                <div className="form-group personal-details__control">
                                    <button type="button" tabIndex="-1" className="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye">
                                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                                            <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                                        </svg>
                                        <div className="personal-details__tooltip personal-details__tooltip--auto">
                                            <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                                        </div>
                                    </button>
                                    <input required type="password" name="password" id="loginPassword" className="personal-details__input" onChange={updatePassword} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} />

                                    {
                                        error != null && error.Param === '' &&
                                        <ErrorAlert error={parseError(error)} />
                                    }
                                </div>


                                <button type="submit" className="button personal-details__button">{t_label?.['checkout.label.login'] ?? 'Login'}</button>
                            </form>
                            <OrderSummary />
                        </div>
                    </section>

                </main>

                <Footer />
            </div >
        </>
    )
}