import React from 'react'
import { useNavigate } from 'react-router-dom'
import logopng from './../../../assets/img/logo.png'
import logosvg from './../../../assets/img/logo.svg'
import logo2x from './../../../assets/img/logo@2x.png'
import logoblue2x from './../../../assets/img/logo-blue@2x.png'
import SupportPortal from '../../../pages/support'
import logoutsvg from './../../../assets/img/log-out.svg'
import { useDispatch } from 'react-redux'
import { logout2 } from '../../../reducers/auth'
import { getTranslation } from '../../../reducers/translation'
import { TRANSLATION_TYPE } from '../../../utils/constants'
import { useSelector } from 'react-redux'

function SideBar(props) {
    const navigate = useNavigate();
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}
    const dispatch = useDispatch();

    const handleLogout = (e) => {
        dispatch(logout2());
    }

    return (
        <>
            <aside className="fixed top-0 left-0 w-full bg-white md:h-full md:w-13 md:bg-gray-800 lg:w-50">
                <div className="flex p-3 justify-between md:justify-center md:py-5 lg:justify-start">
                    <a onClick={() => navigate("/billing")} className="flex items-center gap-2 align-top">
                        <div className="h-6 w-6 overflow-hidden">
                            <img src={logosvg} width="26" height="26" alt="" className="-m-px max-w-none" />
                        </div>
                        <img src={logopng} srcSet={`${logo2x} 2x`} width="98" height="20" alt="" className="hidden lg:block" />
                        <img src="img/logo-blue.png" srcSet={`${logoblue2x} 2x`} width="98" height="20" alt="" className="md:hidden" />
                    </a>

                    <div className="flex md:hidden text-gray-400 md:flex-col md:gap-1 md:bg-transparent md:px-2.5 md:text-gray-500">
                        <div className='logout-box cursor-pointer md:hover:bg-gray-600' onClick={() => handleLogout()} >
                            <a className="flex flex-none grow basis-0 items-center justify-center gap-1.5 border-gray-300 p-1 transition-colors aria-current:border-blue-selected aria-current:text-blue-selected md:rounded-md md:border-0 md:aria-current:bg-gray-600 lg:justify-start ">
                                <img src={logoutsvg} />
                                <span className="text-gray-900 md:hidden lg:block lg:text-white">{t_label?.['account.button.logout'] ?? 'Logout'}</span>
                            </a>
                        </div>
                    </div>

                </div>

                <div className="flex bg-gray-0 text-gray-400 md:flex-col md:gap-1 md:bg-transparent md:px-2.5 md:text-gray-500">
                    <a onClick={() => navigate("/account")} aria-current={props.page === "account"} className="flex h-10 grow basis-0 items-center justify-center gap-1.5 border-b border-gray-300 p-1 transition-colors aria-current:border-blue-selected aria-current:bg-blue-100 aria-current:text-blue-selected md:rounded-md md:border-0 md:aria-current:bg-gray-600 lg:justify-start cursor-pointer">
                        <svg className="h-6 w-6"><use href="/img/sprite24f.svg#account"></use></svg>
                        <span className="text-gray-900 md:hidden lg:block lg:text-white">{t_label?.['account.label.account'] ?? 'Account'}</span>
                    </a>
                    <a onClick={() => navigate("/billing")} aria-current={props.page === "billing"} className="flex h-10 grow basis-0 items-center justify-center gap-1.5 border-b border-gray-300 p-1 transition-colors aria-current:border-blue-selected aria-current:bg-blue-100 aria-current:text-blue-selected md:rounded-md md:border-0 md:aria-current:bg-gray-600 lg:justify-start cursor-pointer">
                        <svg className="h-6 w-6"><use href="/img/sprite24f.svg#banknote"></use></svg>
                        <span className="text-gray-900 md:hidden lg:block lg:text-white">{t_label?.['billing.label.billing'] ?? 'Billing'}</span>
                    </a>
                    <a data-toggle="#modal-support" className="flex h-10 grow basis-0 items-center justify-center gap-1.5 border-b border-gray-300 p-1 transition-colors aria-current:border-blue-selected aria-current:bg-blue-100 aria-current:text-blue-selected md:rounded-md md:border-0 md:aria-current:bg-gray-600 lg:justify-start cursor-pointer">
                        <svg className="h-6 w-6"><use href="/img/sprite24f.svg#notes"></use></svg>
                        <span className="text-gray-900 md:hidden lg:block lg:text-white">{t_label?.['support.label.support'] ?? 'Support'}</span>
                    </a>
                </div>

                <div className="hidden md:flex md:p-5 text-gray-400 md:flex-col md:gap-1 md:bg-transparent md:px-2.5 md:text-gray-500">
                    <div className='logout-box cursor-pointer md:hover:bg-gray-600' onClick={() => handleLogout()} >
                        <a className="flex flex-none grow basis-0 items-center justify-center gap-1.5 border-gray-300 p-1 transition-colors aria-current:border-blue-selected aria-current:text-blue-selected md:rounded-md md:border-0 md:aria-current:bg-gray-600 lg:justify-start ">
                            <img src={logoutsvg} />
                            <span className="text-gray-900 md:hidden lg:block lg:text-white">{t_label?.['account.button.logout'] ?? 'Logout'}</span>
                        </a>
                    </div>
                </div>
            </aside>

            <SupportPortal />

        </>
    )
}

export default SideBar