import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Route, Routes } from "react-router-dom";
import AccountLogin from "./pages/account/loginDetails";
import Reset from "./pages/account/changePassword";
import EmailValidation from "./pages/checkout";
import NewUserBillingDetails from "./pages/checkout/addBillingDetails";
import CreateAccount from "./pages/checkout/createAccount";
import ViewBillingDetails from "./pages/checkout/viewBillingDetails";
import EditBillingDetails from "./pages/checkout/editBillingDetails";
import NotEligible from "./pages/checkout/notEligible";
import ViewBilling from "./pages/billing";
import ViewAllPlans from "./pages/billing/viewPlans";
import EditEmail from "./pages/account/editEmail";
import EditAccBillingDetails from "./pages/account/editAccountBillingDetails";
import PasswordResetSuccess from "./pages/account/thankyouPage";
import Login from "./pages/checkout/login";
import ThankYou from "./pages/checkout/thankYou";
import { AccountLoginDetails } from "./pages/account/accountDetails";
import ProtectedRoute from "./ProtectedRoute";
import PauseSubscription from "./pages/billing/pause";
import { useLocation } from "react-router-dom";
import AddPaymentMethod from './pages/checkout/addPaymentMethod';
import SelectPaymentMethod from './pages/checkout/selectPaymentMethod';
import PaymentMethodOption from './pages/checkout/paymentMethodOption';

const VVRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: location.pathname,
        pageTitle: document.title
      }
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<AccountLogin />} />

      <Route path="/checkout/emailvalidation" element={<EmailValidation />} />
      <Route path="/checkout/login" element={<Login />} />
      <Route path="/checkout/thankyou" element={<ThankYou />} />
      <Route path="/checkout/createaccount" element={<CreateAccount />} />
      <Route path="/checkout/noteligible" element={<NotEligible />} />
      <Route path="/checkout/addbillingdetails" element={<NewUserBillingDetails />} />
      <Route path="/checkout/addPaymentMethod" element={<AddPaymentMethod />} />
      <Route path="/checkout/selectPaymentMethod" element={<SelectPaymentMethod />} />
      <Route path="/checkout/choosePaymentMethod" element={<PaymentMethodOption />} />


      <Route element={<ProtectedRoute />}>
        <Route path="/checkout/viewbillingdetails" element={<ViewBillingDetails />} />
        <Route path="/checkout/editbillingdetails" element={<EditBillingDetails />} />

        <Route path="/account" element={<AccountLoginDetails />} />
        <Route path="/editEmail" element={<EditEmail />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/resetComplete" element={<PasswordResetSuccess />} />
        <Route path="/editBilling" element={<EditAccBillingDetails />} />

        <Route path="/billing/plans" element={<ViewAllPlans />} />
        <Route path="/billing" element={<ViewBilling />} />
        <Route path="/billing/pause" element={<PauseSubscription />} />
      </Route>

    </Routes>
  );
};

export default VVRoutes;





