import React from 'react'
import { displayPrice } from '../currencyPrice';
import ReactDOM from 'react-dom'
import { react } from '@babel/types';


function Localization(props) {

  function createHtmlElement(inputString, keyValueDict) {
    var inputString2 = inputString;
    var reactKey = null;
    var reactElement = null;
    if (inputString2 == null)
      return <></>;

    for (const key of Object.keys(keyValueDict)) {
      const value = keyValueDict[key];

      if (!React.isValidElement(value)) {
        inputString2 = inputString2.replace(`{${key}}`, value);
      } else {
        reactKey = `{${key}}`;
        reactElement = value;
      }
    }

    if (reactElement) {

      const output = inputString2.split(reactKey);

      return <>
        {output[0]}
        {reactElement}
        {output[1]}
      </>
    }

    return inputString2;
  }


  return (
    <>
      <span>
        {createHtmlElement(props.text, props.values)}
      </span>
    </>
  )
}

export default Localization