import React from 'react'

function RefundPolicy() {
    return (
        <>
            <section id="refund-policy" className="modal">
                <div className="modal__back"></div>
                <button type="button" className="modal__close"></button>
                <div className="modal__frame">
                    <div className="modal__cont">
                        <h3>VectorVest Refund&nbsp;Policy</h3>

                        <h4>30-Day Trial</h4>
                        <p>VectorVest offers a 30-Day Trial to allow a thorough evaluation of VectorVest 7 and ProTrader. If you are not completely satisfied with your VectorVest trial for any reason, simply notify us within 30 days for a prompt refund.</p>

                        <h4>Subscriptions</h4>
                        <p>VectorVest offers monthly and annual payment options for VectorVest products and services. Notification of termination shall be made to VectorVest via telephone, email or mail. Monthly and Annual subscriptions will be promptly scheduled for cancellation on the date of expiration of current subscription time period upon request, no refunds will be given.</p>

                        <h4>One time fee purchases</h4>
                        <p>Optional software modules such as, VectorVest ProTrader and AutoTimer are NOT refundable.</p>

                        <h4>Seminars and Events</h4>
                        <p>All events $49.99 or less: All registration fees are non-refundable. All events $50.00 to $95.00: Substitute registrants are welcome and may be named at any time prior to the event. Cancellations six or more days prior to the event will result in a full refund, less a $25.00 service charge per attendee. Cancellations within five days of the event will result in a cancellation fee of $50.All events $95.01 to $495.00: Substitute registrants are welcome and may be named at any time prior to the event. Cancellations six or more days prior to the event will result in a full refund, less a $50.00 service charge per attendee. Cancellations within five days of the event will result in a cancellation fee of $75.All events more than $495.00: Substitute registrants are welcome and may be named at any time prior to the event. Cancellations six or more days prior to the event will result in a full refund, less a $100.00 service charge per attendee. Cancellations within five days of the event will result in a cancellation fee of $150.00.</p>

                        <h4>The Options Paycheck, The Successful Investor, The Options JumpStarter and Precision Bottom-Fishing for Explosive Profits, Trade Like a Pro and All Webcast Series Courses.</h4>
                        <p>We want you to be satisfied with your purchase, but we also want you to give your best effort to apply all of the strategies in the course. We do offer a refund period for purchases. In the case of a live course, if you cancel prior to the start of the course, refunds will be applied using our “Seminar and Event” refund policy. In the case of a live course, once the course begins, requests for refunds must be made prior to the end of the final live session. In order to qualify for a refund we must see that you have done the work in the course and put forth your best effort. Contact our support team at support@vectorvest.com, before the last session and let us know you’d like a refund.</p>
                        <p>In order to qualify for a refund:</p>
                        <ul>
                            <li>You either attended all live sessions or watched all recordings of the live sessions in their entirety.</li>
                            <li>You completed all tests, quizzes or assignments in the VectorVest University, which is where the course will be stored.</li>
                            <li>You submit one or more examples of trades completed where you followed the steps taught in the class. (Paycheck course only)</li>
                        </ul>
                        <p>We will NOT provide refunds after the final live session. After that day, all payments are non-refundable and you are responsible for full payment of the fees for the program regardless if you complete the program.</p>
                        <p>All refunds are discretionary as determined by VectorVest Inc.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RefundPolicy