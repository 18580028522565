function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
}


export const salesforcePayload = (args) => {
    let cookieKeys = [
        "rw_channel",
        "rw_source",
        "rw_utm_campaign",
        "rw_utm_term",
        "rw_utm_content",
        "rw_refer_url",
        "rw_current_url",
        "rw_entry_url"
    ];
    return cookieKeys.reduce((acc, currentValue) => {

        let cookieVal = getCookie(currentValue);

        // check persist cookie if cookieVal is null
        if (!cookieVal) {
            cookieVal = getCookie(`${currentValue}-p`);
        }

        return { ...acc, [currentValue]: cookieVal ?? '' }
    }, { ...args })
}

export function getBaseUrl() {
    var location = window.location;
    if (typeof location.origin === 'undefined')
        return location.protocol + '//' + location.host;
    else
        return location.origin;
}