/**
 * Author: Harsh Agrawal
 * Summary: This page display the option to choose between different payment method.
 */


import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../components/layout/header'
import Footer from '../../../components/layout/footer'
import OrderSummary from '../../../components/orderSummary'
import applepaysvg2 from '../../../assets/img/payment-cards/cn-payment-applepay2.svg'
import visa2 from '../../../assets/img/payment-cards/cn-payment-visa2.svg'
import mastercard2 from '../../../assets/img/payment-cards/cn-payment-mastercard2.svg'
import { useNavigate } from 'react-router-dom';
import localStore from '../../../utils/localstore'
import { useSearchParams } from 'react-router-dom'
import cookieStore from '../../../utils/cookie'
import { CHECKOUT_STATUS, USER_PAYMENT_METHOD_PREFERENCE } from '../../../utils/constants'
import { salesforcePayload } from '../../../utils/functions'
import { createItemPayload } from '../../../utils/cart'
import { useDispatch, useSelector } from 'react-redux'
import { createCampaignMember } from '../../../reducers/salesforce'
import { setCheckoutPayload } from '../../../reducers/cart'
import { fetchUserBillingDetails } from '../../../reducers/accountDetails'
import { updateUserPaymentMethodPreference } from '../../../reducers/cart'
import { displayError } from '../../../reducers/snackbar'
import { MdArrowForwardIos } from "react-icons/md";


function PaymentMethodOption() {
  const { userValidationResponse, email } = useSelector((state) => state.auth);
  const { items, promoCode, count } = useSelector((state) => state.cart)
  const { gdprEmailUpdates, billingDetail } = useSelector((state) => state.accountDetails);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.has("token") || cookieStore.get("vvstore")) {
      let token = searchParams.get("token") ?? cookieStore.get("vvstore");
      localStore.set('token', token);
    }

    if (userValidationResponse?.userStatus === "EXIST") {
      fetchUserDetails(email);
    }
  }, [])


  const createSalesforceCampaign = (firstName, lastName, email, checkoutStatus = CHECKOUT_STATUS.COMPLETED) => {
    let payload = salesforcePayload({ firstName, lastName, email, checkoutStatus });
    if (gdprEmailUpdates !== undefined) {
      payload = { ...payload, 'OptinMarketing': gdprEmailUpdates ? "YES" : "NO" }
    }
    return dispatch(createCampaignMember(payload))
  }

  const redirectUser = (applePaySelected) => {
    if (applePaySelected) {
      let payload = createSubscriptionPayload();
      dispatch(updateUserPaymentMethodPreference({ 'preference': USER_PAYMENT_METHOD_PREFERENCE.APPLE_PAY }));
      createSalesforceCampaign(email, email, email, CHECKOUT_STATUS.INITIATED);
      dispatch(setCheckoutPayload({ payload }));
    } else {
      dispatch(updateUserPaymentMethodPreference({ 'preference': USER_PAYMENT_METHOD_PREFERENCE.CARD }));
    }

    let { userStatus, status } = userValidationResponse;
    if (userStatus === "EXIST") {
      if (status === "success")
        navigate("/checkout/viewbillingdetails");
    }
    else if (userStatus === "NEW") {
      if (status === "success")
        navigate(applePaySelected ? "/checkout/addPaymentMethod" : '/checkout/addbillingdetails')
    } else if (userStatus === "EXIST_NO_SUB") {
      if (status === "success")
        navigate(applePaySelected ? "/checkout/addPaymentMethod" : '/checkout/addbillingdetails')
    }
  }

  const fetchUserDetails = (email) => {
    dispatch(fetchUserBillingDetails(email))
      .then((response) => {

      })
      .catch((error) => {
        dispatch(displayError(error));
      });
  };

  const createSubscriptionPayload = () => {

    const items_ = createItemPayload(items, count);

    const stateCodeSupportedCountry = ["IN", "US", "CA"];

    let billingDetails_ = {};

    billingDetails_ = {
      "firstName": `${billingDetail?.firstName}`,
      "lastName": `${billingDetail?.lastName}`,
      "addressLine1": `${billingDetail?.addressLine1}`,
      "addressLine2": `${billingDetail?.addressLine2}`,
      "city": `${billingDetail?.city}`,
      "zip": `${billingDetail?.zip}`,
      "country": `${billingDetail?.country}`,
      "state": `${billingDetail?.state}`
    };

    let payload = {
      "billingDetail": billingDetails_,
      "email": email,
      "items": items_,
    }

    if (promoCode) {
      payload = { ...payload, promoCode: promoCode };
    }

    return payload;
  }

  return (
    <>
      <div className="body body--alt2">
        <Header />

        <main className="main main--fullh">
          <section className="section personal-details">
            <div className="grid-cont">


              <div className='mt-8'>
                <h3 className="personal-details__heading">Choose how to pay</h3>
                <h5 className='payment-detail__subtitle'>Your payment is safe and secure, change how you pay anytime</h5>
              </div>

              <div>
                <div className='container-gray cursor-pointer' onClick={(e) => redirectUser(true)}>
                  <div className='flex justify-between'>
                    <div>
                      <img src={applepaysvg2} width="40" />
                    </div>
                    <div className='font-bold'>
                      <MdArrowForwardIos />
                    </div>
                  </div>

                </div>

                <div className='container-gray cursor-pointer' onClick={(e) => redirectUser(false)}>
                  <div className='flex justify-between'>
                    <div className='flex flex-row gap-2'>
                      <div>Credit or Debit Card</div>
                      <div className='card-border'>
                        <img src={visa2} width="25" style={{ 'marginTop': '-1px' }} />
                      </div>
                      <div className='card-border'  >
                        <img src={mastercard2} width="25" style={{ 'marginTop': '2px' }} />
                      </div>
                    </div>

                    <div className='font-bold'>
                      <MdArrowForwardIos />
                    </div>
                  </div>
                </div>

              </div>

              <OrderSummary />

            </div>

          </section >
        </main >

        <Footer />

      </div >
    </>
  )
}

export default PaymentMethodOption