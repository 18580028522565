import React from "react";
import { useState } from "react";
import { focusAndOpenKeyboard } from "../../../utils/globalUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { displayError } from "../../../reducers/snackbar";
import { useEffect } from "react";
import { validateForm } from "../../../utils/form";
import { login } from "../../../reducers/auth";
import { addUserEmail } from "../../../reducers/accountDetails";
import { vvLogin } from "../../../settings.js/dev";
import Footer from "../../../components/layout/footer";
import { getTranslation } from "../../../reducers/translation";
import { TRANSLATION_TYPE } from "../../../utils/constants";

export const AccountLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page
    if (validateForm("account-login___form")) {
      dispatch(login({ username: email, password }))
        .then((response) => {
          dispatch(addUserEmail({ email }))
          navigate("/account");
        })
        .catch((error) => {
          dispatch(displayError(error));
        });
    }
  };

  useEffect(() => {
    window.location.href = vvLogin;
    focusAndOpenKeyboard(document.getElementById("loginEmail"), 300);

    const eyeIcons = document.querySelectorAll(".personal-details__icon--eye");

    const handleClick = (event) => {
      eyeIcons.forEach((item) => {
        item.classList.toggle("crossed");
        if (item.classList.contains("crossed")) {
          item.nextElementSibling.type = "text";
          item.querySelector("span").textContent = t_label?.['account.label.hide_password'] ?? 'Hide password';
        } else {
          item.nextElementSibling.type = "password";
          item.querySelector("span").textContent = t_label?.['account.label.show_password'] ?? 'Show password';
        }
      });
    };

    eyeIcons.forEach((item) => {
      item.addEventListener("click", handleClick);
    });

    return () => {
      eyeIcons.forEach((item) => {
        item.removeEventListener("click", handleClick);
      });
    };
  }, []);
  return (
    <>
      {/* <div>
        <body class="login-body">
          <header class="header">
            <img src="img/logo.png" srcset="img/logo@2x.png 2x" alt="" />

            <nav class="user-nav">
              <span>
                Already a<span class="xs:d-none"> VectorVest</span> Customer?
              </span>
              <a
                href={vvLogin}
                target="_blank"
                class="user-nav__link"
              >
                Sign In
              </a>
            </nav>
          </header>

          <section class="login">
            <div class="grid-cont">
              <form
                noValidate
                action=""
                onSubmit={handleSubmit}
                id="account-login___form"
                class="login__form"
              >
                <div class="login__cont">
                  <h1 class="login__title">Login to your Account</h1>
                  <p class="login__subtitle"></p>
                  <label for="code" class="personal-details__label">
                    Email
                  </label>
                  <div class="form-group">
                    <input
                      required
                      data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                      data-pristine-email-message={t_message?.['checkout.message.valid_email'] ?? 'This field requires a valid e-mail address'}
                      type="email"
                      name="email"
                      id="loginEmail"
                      class="personal-details__input"
                      onChange={updateEmail}
                    />
                  </div>

                  <label class="personal-details__label">Password</label>
                  <div class="form-group personal-details__control">
                    <button
                      type="button"
                      tabindex="-1"
                      class="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye"
                    >
                      <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                      </svg>
                      <div class="personal-details__tooltip personal-details__tooltip--auto">
                        <span>Show password</span>
                      </div>
                    </button>
                    <input
                      required
                      data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                      type="password"
                      name="password"
                      id="loginPassword"
                      className="personal-details__input"
                      onChange={updatePassword}
                    />
                  </div>
                  <br />
                  <div class="login__foot-inner">
                    <button type="submit" class="button">
                      <span>Login</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <Footer />
        </body>
      </div> */}
    </>
  );
};

export default AccountLogin;
