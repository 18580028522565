import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import chargebee from "./chargebee";
import cart from "./cart";
import snackbar from "./snackbar";
import accountDetails from "./accountDetails";
import itemDetails from "./itemDetails";
import billingDetails from "./billingDetails";
import translation from "./translation";
import sessionDetails from "./sessionDetails";

const appReducer = combineReducers({
  accountDetails,
  auth,
  chargebee,
  cart,
  snackbar,
  itemDetails,
  billingDetails,
  translation,
  sessionDetails
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    const { translation } = state;
    state = { translation };
    return appReducer(state, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
