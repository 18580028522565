/**
 * Author: Harsh Agrawal
 * Summary: This page represents an updated checkout page functionality. Upon completing the checkout process, the user's subscription will be updated, and they will be redirected to the thank you page.
 */


import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../../components/layout/header';
import Footer from '../../../components/layout/footer';
import OrderSummary from '../../../components/orderSummary';
import { initChargebee } from '../../../reducers/chargebee';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { fetchUserBillingDetails } from '../../../reducers/accountDetails';
import { createCheckoutForUpdateSubscription } from '../../../reducers/chargebee';
import { createItemPayload } from '../../../utils/cart';
import { fetchEstimateForUpdateSubscription, setCheckoutPayload, setDisplayTax } from '../../../reducers/cart';
import editpng from './../../../assets/img/edit.svg';
import { displayError } from '../../../reducers/snackbar';
import { getAddressStr } from '../../../utils/address';
import { getPeriodText2 } from '../../../utils/order';
import { displayPrice } from '../../../components/currencyPrice';
import { validateForm } from '../../../utils/form';
import { usePreventBackNavigation } from '../../../utils/globalUtils';
import { deletePacContainer } from '../../../utils/globalUtils';
import { fetchItemDetails } from '../../../reducers/itemDetails';
import { createSubscription } from '../../../reducers/billingDetails';
import ErrorAlert from '../../../components/Alert';
import { parseError } from '../../../utils/error';
import { createCampaignMember } from "../../../reducers/salesforce";
import { CHECKOUT_STATUS } from "../../../utils/constants";
import { salesforcePayload } from "../../../utils/functions";
import { getTranslation } from '../../../reducers/translation';
import { TRANSLATION_TYPE } from '../../../utils/constants';
import Localization from '../../../components/translation';

function NewUserBillingDetails() {
    const [error, setError] = useState();
    const [displayCardComponent, setDisplayCardComponent] = useState(false);
    const { items, count } = useSelector((state) => state.cart)
    const cart = useSelector((state) => state.cart)
    const [promoCode, setPromoCode] = useState(cart.promoCode);
    const { isCbInitiated } = useSelector((state) => state.chargebee);
    const { cbInstance } = useSelector((state) => state.chargebee);
    const { email } = useSelector((state) => (state.auth));
    const { billingDetail } = useSelector((state) => (state.accountDetails));
    const { estimate } = useSelector((state) => state.cart);
    const { nextTotal, trialPeriod, trialPeriodUnit, periodUnit, subStatus, currencyCode } = estimate;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const { state } = useLocation();
    const allowBackNavigation = state ? state.allowBackNavigation === true : null;
    const [reactivate, setReactivate] = useState(false);
    const options = useMemo(() => countryList().getData(), [])
    const [searchParams, setSearchParams] = useSearchParams();
    const { locale } = useSelector((state) => state.translation);
    const [disableCheckoutBtn, setDisableCheckoutBtn] = useState(true);


    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    useEffect(() => {
        IsReactivation();

        deletePacContainer();
        if (!isCbInitiated) {
            dispatch(initChargebee());
        }
        dispatch(fetchUserBillingDetails(email)).then((response) => {
            fetchEstimate();
        }).catch((error) => {
            handleError(error);
        })

        fetchItemDetails({
            "Items": createItemPayload(items, count)
        }).then((response) => {
            setDisplayCardComponent(response.hasAddonTrial);
        }).catch((error) => {
            handleError(error);
        })

    }, [])



    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm("view-billing-details__form")) {
            createSalesforceCampaign(billingDetail, CHECKOUT_STATUS.INITIATED);
            if (displayCardComponent) {
                let payload = createHostedPagePayload();
                dispatch(createSubscription(payload))
                    .then(() => createSalesforceCampaign(billingDetail, CHECKOUT_STATUS.COMPLETED))
                    .then(() => navigate("/checkout/thankyou"))
                    .catch((error) => handleError(error))
            } else {
                openCheckout(cbInstance);
            }
        }
    };

    const handleSubmit2 = async (event) => {
        event.preventDefault();

        if (validateForm("view-billing-details__form")) {
            createSalesforceCampaign(billingDetail, CHECKOUT_STATUS.INITIATED);
            let payload = createHostedPagePayload();
            dispatch(setCheckoutPayload({ payload }));
            navigate("/checkout/selectPaymentMethod");
        }
    };

    const handleError = (error) => {
        console.log(error);
        if (error.Param == null)
            dispatch(displayError(error));
        else
            setError(error);
    }

    const updatePromoCode = event => {
        setPromoCode(event.target.value.split(',').filter(value => value.trim() !== ''));
    }

    const IsReactivation = () => {
        if (searchParams.has("isReactivation")) {
            if (searchParams.get("isReactivation") === "true") {
                setReactivate(true);
                return true;
            }
        }
        return false;
    }

    const openCheckout = (cbInstance) => {
        setError();
        dispatch(createCheckoutForUpdateSubscription(createHostedPagePayload())).then((response) => {
            createCheckoutPopup(cbInstance, response);
        }).catch((error) => {
            handleError(error);
        })
    }


    const createCheckoutPopup = (cbInstance, payload) => {
        cbInstance.openCheckout({
            hostedPage: () => {
                const hostedPageData = payload;
                return Promise.resolve(hostedPageData);
            },
            success(hostedPageId) {
                createSalesforceCampaign(billingDetail, CHECKOUT_STATUS.COMPLETED);
                navigate("/checkout/thankyou")
                cbInstance.closeAll();
            },
            close: () => {

            },
            step(step) {
            }
        });
    }

    const createHostedPagePayload = () => {
        if (IsReactivation()) {
            return {
                "IsReactivation": true
            };
        }

        const items_ = createItemPayload(items, count);

        let payload = {
            "billingDetail": billingDetail,
            "items": items_,
        }

        if (promoCode) {
            payload = { ...payload, promoCode };
        }

        return payload;
    }

    const createEstimatePayload = () => {
        if (IsReactivation()) {
            return {
                "IsReactivation": true
            };
        }

        const items_ = createItemPayload(items, count);

        let payload = {
            billingDetail,
            "items": items_
        }

        if (promoCode) {
            payload = { ...payload, promoCode };
        }

        return payload;
    }

    const fetchEstimate = () => {
        setError();
        let payload = createEstimatePayload();
        setDisableCheckoutBtn(true);
        dispatch(fetchEstimateForUpdateSubscription(payload)).then((response) => {
            dispatch(setDisplayTax({ "displayTax": true }))
            setDisableCheckoutBtn(false);
        }).catch((error) => {
            handleError(error);
        })
    }

    const fetchEstimateOnInputChanges = (event) => {
        fetchEstimate();
    }

    const createSalesforceCampaign = ({ firstName, lastName }, checkoutStatus = CHECKOUT_STATUS.COMPLETED) => {
        let payload = salesforcePayload({ firstName, lastName, email, checkoutStatus });
        return dispatch(createCampaignMember(payload))
    }

    return (
        <>
            <div className="body body--alt2">
                <Header />

                <main className="main main--fullh">
                    <section className="section personal-details">
                        <div className="grid-cont">
                            <form onSubmit={handleSubmit2} noValidate id="view-billing-details__form" className="personal-details__form">

                                <div className="grid-cont2">
                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="personal-details__control personal-details__heading__action">
                                                <div className="personal-details__heading2">{t_label?.['checkout.label.billing_details'] ?? 'Billing Details'}</div>

                                                <div onClick={() => { navigate("/checkout/editbillingdetails") }} className="action-button">
                                                    <img src={editpng} alt="" />
                                                    <div>{t_label?.['account.label.edit'] ?? 'Edit'}</div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <div className="personal-details__label2">{t_label?.['checkout.label.first_name'] ?? 'First Name'}</div>
                                                <div>{billingDetail.firstName}</div>
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <div className="personal-details__label2">{t_label?.['checkout.label.last_name'] ?? 'Last Name'}</div>
                                                <div>{billingDetail.lastName}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <div className="personal-details__label2">{t_label?.['checkout.label.address'] ?? 'Address'}</div>
                                                <div>{getAddressStr(billingDetail, options)}</div>
                                            </div>
                                        </div>
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="form-group personal-details__control">
                                                <br />
                                                <div className="personal-details__label2">{t_label?.['checkout.label.phone'] ?? 'Phone'}</div>
                                                <div>{billingDetail.phone}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {(promoCode == null || promoCode.length < 2) &&
                                    <div className="grid-row">
                                        <div className="grid-col sm:grid-col--12">
                                            <div className="promo-code">
                                                <label htmlFor="city" className="personal-details__label mt-0">{t_label?.['checkout.label.enter_a_promo_code'] ?? 'Enter a Promo Code'}</label>
                                                <input
                                                    type="text"
                                                    name="promoCode"
                                                    value={promoCode}
                                                    id="promo-code"
                                                    placeholder={t_label?.['checkout.label.enter_a_promo_code'] ?? "Enter promo code"}
                                                    className="personal-details__input"
                                                    data-pristine-maxLength="50"
                                                    data-pristine-maxlength-message={t_message?.['checkout.message.promo_code_min_len'] ?? "Promo code cannot have more than 50 characters"}
                                                    onChange={updatePromoCode}
                                                    onBlur={fetchEstimateOnInputChanges}
                                                />
                                                {
                                                    error != null && error.Param === 'promocode' &&
                                                    <ErrorAlert error={parseError(error)} bgcolor='#f7f8f9' />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* 
                                {
                                    subStatus === "InTrial" &&
                                    <label className="form-group personal-details__checkbox">
                                        <input required type="checkbox" name="agreement" data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} />

                                        <Localization text={t_message?.['checkout.message.subscription_trial_billing']} values={{
                                            trialPeriod,
                                            trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                                            amount: displayPrice(nextTotal, currencyCode),
                                            periodUnit: getPeriodText2(periodUnit)
                                        }}
                                        />

                                    </label>
                                } */}


                                {/* <button type="submit" className="button personal-details__button">{t_label?.['checkout.button.continue_to_payment'] ?? 'Continue to Payment'}</button> */}

                                <button disabled={disableCheckoutBtn} type="submit" className={`button payment-details__submit ${disableCheckoutBtn ? 'disabled' : ''}`}>{t_label?.['checkout.button.continue_to_payment'] ?? 'Continue to Payment'}</button>

                            </form>
                            <OrderSummary />
                        </div>
                    </section>

                </main>

                <Footer />
            </div>
        </>
    )
}

export default NewUserBillingDetails