/**
 * Author: Harsh Agrawal
 * Summary: This page allows users to update their account email.
 */

import React, { useState, useEffect } from "react";
import { updateCustomerEmail } from "../../../reducers/accountDetails";
import { useDispatch, useSelector } from "react-redux";
import { validateForm } from "../../../utils/form";
import { useNavigate } from "react-router-dom";
import { displayError } from "../../../reducers/snackbar";
import { focusAndOpenKeyboard } from "../../../utils/globalUtils";
import SideBar from "../../../components/layout/sidebar";
import styles from './../../../assets/css/output.module.css'
import Footer from "../../../components/layout/footer";
import { getTranslation } from "../../../reducers/translation";
import { TRANSLATION_TYPE } from "../../../utils/constants";

export const EditEmail = () => {
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const { email } = useSelector((state) => state.accountDetails);

  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateNewEmail = (e) => {
    setNewEmail(e.target.value);
  };

  const updateConfirmEmail = (e) => {
    setConfirmEmail(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm("edit-account-login-details__form")) {
      dispatch(updateCustomerEmail({ email, newEmail, confirmEmail }))
        .then((response) => {
          navigate("/account");
        })
        .catch((error) => {
          dispatch(displayError(error));
        });
    }
  };

  useEffect(() => {
    focusAndOpenKeyboard(document.getElementById("email"), 300);
  }, []);
  return (
    <>
      <div class={`dark ${styles.body} bg-white pt-22 md:pl-13 md:pt-0 lg:pl-50`}>
        <SideBar page="account" />

        <div class="min-h-[100dvh] flex flex-col">
          <div class="mb-auto">
            <main class="mx-auto box-content max-w-[580px] p-5 md:p-10 lg:p-12">
              <div class="mb-7 flex cursor-pointer"
                onClick={() => navigate("/account")}
              >
                <a class="flex items-center gap-1 text-sm hover:text-blue-600">
                  <svg class="h-4 w-4">
                    <use href="img/sprite.svg#arrow-left"></use>
                  </svg>

                  <span>{t_label?.['account.button.go_back'] ?? 'Go Back'}</span>
                </a>
              </div>

              <form
                action="account-edit-email2.html"
                class="mt-5 rounded-md bg-gray-0 p-5"
                id="edit-account-login-details__form"
              >
                <h2 class="text-h2 font-bold text-gray-900">{t_label?.['account.label.edit_email_address'] ?? 'Edit Email Address'}</h2>

                <p class="mt-1.5" dangerouslySetInnerHTML={{ __html: t_message?.['account.message.edit_email_subtitle'] }} />

                <div class="mt-5 text-sm">{t_label?.['account.label.current_email'] ?? 'Current Email'}</div>
                <div class="mt-2 text-lg text-gray-900">{email}</div>
                <hr class="mt-4 border-gray-300/50" />
                <div class="mt-5 text-sm">{t_label?.['account.label.new_email_address'] ?? 'New Email Address'}</div>
                <div class="form-group relative mt-2">
                  <input
                    required
                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                    data-pristine-email-message={t_message?.['checkout.message.valid_email'] ?? 'This field requires a valid e-mail address'}
                    type="email"
                    name="email"
                    id="email"
                    class="personal-details__input"
                    placeholder={t_label?.['account.label.enter_new_email_address'] ?? "Enter New Email Address"}
                    onChange={updateNewEmail}
                  />
                </div>
                <div class="mt-5 text-sm">{t_label?.['account.label.confirm_new_email_address'] ?? 'Confirm New Email Address'}</div>
                <div class="form-group relative mt-2">
                  <input
                    required
                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                    data-pristine-email-message={t_message?.['checkout.message.valid_email'] ?? 'This field requires a valid e-mail address'}
                    type="email"
                    name="email2"
                    data-pristine-equals="#email"
                    data-pristine-equals-message={t_message?.['account.message.email_not_matching'] ?? "Emails do not match"}
                    id="email2"
                    class="personal-details__input"
                    placeholder={t_label?.['account.label.enter_email_to_confirm'] ?? "Enter Email Address Again to Confirm"}
                    onChange={updateConfirmEmail}
                  />
                </div>
                <hr class="-mx-5 mt-4 border-gray-300/50" />
                <div class="-mx-5 -mb-5 flex items-center justify-end gap-2 p-3 cursor-pointer">
                  <a
                    class="flex h-[26px] items-center justify-center gap-1 rounded-md bg-gray-700 px-2.5 text-sm text-blue-300 ring-1 ring-inset ring-blue-300/30 hover:text-white hover:shadow-button-ter-hover active:text-white active:shadow-button-ter-hover active:ring-blue-300/80 disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-blue-100 dark:text-blue-600 dark:ring-blue-200 dark:hover:bg-blue-200 dark:hover:ring-blue-300 dark:active:bg-blue-300 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent"
                    onClick={() => navigate("/account")}
                  >
                    <svg class="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                      <use href="img/sprite.svg#close"></use>
                    </svg>

                    <span>{t_label?.['account.label.cancel'] ?? 'Cancel'}</span>
                  </a>
                  <button
                    type="submit"
                    class="flex h-[26px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-2.5 text-sm text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400"
                    onClick={handleSubmit}
                  >
                    <span>{t_label?.['account.button.update_email_address'] ?? 'Update Email Address'}</span>

                    <svg class="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                      <use href="img/sprite.svg#arrow-right"></use>
                    </svg>
                  </button>
                </div>
              </form>
            </main>
          </div>
          <Footer />
        </div>
      </div >
    </>
  );
};

export default EditEmail;
