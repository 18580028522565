import { getUserLocation } from "../reducers/auth";
import { parseCSV } from "./file";
import { TRANSLATION_FILE_NAME, TRANSLATION_TYPE } from "./constants";

export const getLocale = async () => {
  let language = navigator.language || navigator.userLanguage;
  let supported_locale = getSupportedLocale();

  if (language) {
    if (supported_locale.includes(language))
      return language;

    if (language.includes("-")) {
      language = language.split("-")[0];
      if (supported_locale.includes(language))
        return language;
    }

  }

  return 'en';
}

export const getSupportedLocale = () => {
  return ['en', 'nl'];
}

export const getLocalizationFile = async (locale) => {
  var translationObj = {};
  translationObj[locale] = {};

  for (const type in TRANSLATION_TYPE) {
    let filePath = `/translation/${locale}/internal/${TRANSLATION_FILE_NAME[type]}`;
    let parsedCSV = await parseCSV(filePath);

    if (parsedCSV.length > 1) {
      var key_index = parsedCSV[0].indexOf('key');
      var value_index = parsedCSV[0].indexOf('value');
      var obj = {};
      if (key_index >= 0 && value_index >= 0) {
        for (var i = 1; i < parsedCSV.length; i++) {
          obj[parsedCSV[i][key_index]] = parsedCSV[i][value_index];
        }
      }
      translationObj[locale][type] = obj;
    }

  }
  return translationObj;
}