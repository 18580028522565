import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings.js/dev";
import api from "../utils/api";
import localStore from "../utils/localstore";
import { setUserEmail } from "./auth";

const billingDetail = {
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  phone: null,
};

const paymentDetail = {
  cardType: "",
  expiryMonth: null,
  expiryYear: null,
  last4: null,
};

const initialState = {
  email: "",
  billingDetail,
  paymentDetail,
  customerId: "",
  isTrialEligible: false,
  professionalInvestor: false,
  emailVerified: true,
  isElite: false,
  updateEmail: "",
  gdprEmailUpdates: undefined
};

export const accountDetails = createSlice({
  name: "accountDetails",
  initialState,
  reducers: {
    trialEligible: (state, action) => {
      let { email, isTrialEligible } = action.payload;
      state.isTrialEligible = isTrialEligible;
      state.email = email;
    },
    addUserDetails: (state, action) => {
      let {
        billingDetail,
        paymentDetail,
        customerId,
        updateEmail,
        isElite,
        emailVerified,
        email,
      } = action.payload;
      state.billingDetail = billingDetail;
      state.paymentDetail = paymentDetail;
      state.customerId = customerId;
      state.updateEmail = updateEmail;
      state.isElite = isElite;
      state.emailVerified = emailVerified;
      state.email = email;
    },
    updateBillingAddress: (state, action) => {
      let { billingDetail } = action.payload;
      state.billingDetail = billingDetail;
    },
    updateUserEmail: (state, action) => {
      let { accessToken } = action.payload;
      localStore.set('token', accessToken);
    },
    addUserEmail: (state, action) => {
      let { email } = action.payload;
      state.email = email;
    },
    confirmEmailCheck: (state, action) => {
      let { code } = action.payload;
      state.code = code;
    },
    emailConfirmed: (state, action) => {
      state.emailVerified = true;
      state.email = state.updateEmail;
    },
    addGdprUpdate: (state, action) => {
      state.gdprEmailUpdates = action.payload?.gdprEmailUpdates;
    }
  },
});

export const fetchUserBillingDetails = (email) => async (dispatch) => {
  const response = await api.get(baseURL, `/account/detail`);
  dispatch(addUserDetails(response));
  dispatch(setUserEmail({ email: response?.email }));
  return response;
};


export const updateCustomerEmail = (payload) => async (dispatch) => {
  const response = await api.post(baseURL, "/account/update-email", payload);
  dispatch(updateUserEmail(response));
  return response;
};

export const updateUserBillingDetails = (payload) => async (dispatch) => {
  const response = await api.put(baseURL, `/user/billing-details`, payload);
  dispatch(updateBillingAddress(payload));
  return response;
}

export const confirmUserEmail = (payload) => async (dispatch) => {
  const response = await api.post(baseURL, "/account/confirm-email", payload);
  dispatch(emailConfirmed());
  return response;
};

export const changeUserPassword = (payload) => async (dispatch) => {
  const response = await api.post(baseURL, "/account/change-password", payload);
  return response;
};

export const {
  trialEligible,
  addUserDetails,
  updateUserEmail,
  confirmEmailCheck,
  updateUserPassword,
  resendUserVerification,
  emailConfirmed,
  addUserEmail,
  updateBillingAddress,
  addGdprUpdate
} = accountDetails.actions;

export default accountDetails.reducer;
