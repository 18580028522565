/**
 * Author: Harsh Agrawal
 * Summary: This page facilitates the creation of a new user account in AWS Cognito.
 */


import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../components/layout/header';
import Footer from '../../../components/layout/footer';
import OrderSummary from '../../../components/orderSummary';
import { useDispatch, useSelector } from 'react-redux';
import { validateForm } from '../../../utils/form';
import { useNavigate } from 'react-router-dom';
import { displayError, displaySuccess } from '../../../reducers/snackbar';
import sepaLogo from './../../../assets/img/sepa-logo.svg'
import bancontactLogo from './../../../assets/img/bancontact-logo.svg'
import appleLogo from './../../../assets/img/apple-logo-black.svg'
import CBCardComponent from '../../../components/cardComponent';
import { createIntent } from '../../../reducers/chargebee';
import { deletePacContainer, focusAndOpenKeyboard } from '../../../utils/globalUtils';
import { setCheckoutPayload, updatePaymentIntentId, updatePaymentToken, updatePaymentSource, updateDisplayPurchaseComplete, setEcommerceGTMEvent } from '../../../reducers/cart';
import { initChargebee } from '../../../reducers/chargebee';
import visa from './../../../assets/img/payment-cards/cn-payment-visa.svg'
import mastercard from './../../../assets/img/payment-cards/cn-payment-mastercard.svg'
import amex from './../../../assets/img/payment-cards/cn-payment-amex.svg'
import discover from './../../../assets/img/payment-cards/cn-payment-discover.svg'
import jcb from './../../../assets/img/payment-cards/cn-payment-jcb.svg'
import diners from './../../../assets/img/payment-cards/cn-payment-dinersclub.svg'
import { TRANSLATION_TYPE } from '../../../utils/constants';
import { getTranslation } from '../../../reducers/translation';
import { listPaymentSource } from '../../../reducers/billingDetails';
import { CHECKOUT_STATUS } from '../../../utils/constants';
import { salesforcePayload } from '../../../utils/functions';
import { createCampaignMember } from '../../../reducers/salesforce';
import $ from 'jquery';
import { LinearProgress } from '@mui/material';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { createSubscription } from '../../../reducers/billingDetails';
import { createAccountForNewUser, checkUserStatus } from '../../../reducers/auth';
import Localization from '../../../components/translation';
import { displayPrice } from '../../../components/currencyPrice';
import { recaptchaAssessment } from '../../../utils/recaptcha';


function SelectPaymentMethod() {

  const [displayTemporaryPasswordMsg, setDisplayTemporaryPasswordMsg] = useState(false);

  const { email, userStatus } = useSelector((state) => (state.auth))
  const { estimate, checkoutPayload, paymentOption } = useSelector((state) => state.cart);
  const { nextTotal, trialPeriod, trialPeriodUnit, periodUnit, subStatus, currencyCode, total, thankyouPageType } = estimate;
  const [displayApplePayBtn, setDisplayApplePayBtn] = useState(false);
  const { cbInstance, isCbInitiated, _3dsEnabled, paymentModule } = useSelector((state) => state.chargebee);
  const { paymentSources } = useSelector((state) => state.billingDetails);
  const { billingDetail } = useSelector((state) => (state.accountDetails));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const [gpayHandler, setGpayHandler] = useState(null);
  const [applePayHandler, setApplePayHandler] = useState(null)
  const [displaySavedPaymentMethod, setDisplaySavedPaymentMethod] = useState(true);
  const [displayPage, setDisplayPage] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { gdprEmailUpdates } = useSelector((state) => state.accountDetails);
  const [isWalletUsed, setIsWalletUsed] = useState(false);
  const [displayPayWithCardLabel, setDisplayPayWithCardLabel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const payment_module = paymentModule;

  deletePacContainer();

  useEffect(() => {
    if (!isCbInitiated) {
      dispatch(initChargebee());
    }
    dispatch(listPaymentSource()).then((response) => {
      setDisplaySavedPaymentMethod(response.length > 0);
      setDisplayPage(true);
    })
  }, [])

  useEffect(() => {

    // mountPaymentMethod('google-pay', 'google_pay', '#gpay-button', {
    //     buttonColor: 'white', buttonType: 'plain', buttonSizeMode: 'fill'
    // })

    if (cbInstance) {
      if (payment_module.includes('google_pay'))
        initGPayCheckout();
      if (payment_module.includes('apple_pay'))
        initApplePayCheckout();
    }

  }, [cbInstance])

  const createPaymentIntent = async (payment_method_type) => {
    let response = await createIntent({ PaymentMethodType: payment_method_type, CurrencyCode: currencyCode, Amount: Math.round(total * 100) });
    return response?.payment_intent;
  }

  const initGPayCheckout = () => {
    if (cbInstance) {
      cbInstance.load('google-pay').then((gpayHandler) => {
        setGpayHandler(gpayHandler);
        createPaymentIntent('google_pay').then((intent) => {
          gpayHandler.setPaymentIntent(intent);
          setDisplayPayWithCardLabel(true);
          return gpayHandler.mountPaymentButton('#gpay-button', {
            buttonColor: 'white', buttonType: 'plain', buttonSizeMode: 'fill'
          })
        }).then(() => {
          // once button mounted
          return gpayHandler.handlePayment();
        }).then((result) => {
          dispatch(updatePaymentIntentId({ 'paymentIntentId': result.paymentIntent.id }))
          setIsWalletUsed(true);
          // navigate("/checkout/createaccount");
        }).catch((error) => {
          // handle error
        });
      });
    }
  };

  const initApplePayCheckout = () => {
    if (cbInstance) {

      cbInstance.load("apple-pay").then((applePayHandler) => {
        setApplePayHandler(applePayHandler);
        const isSupportedDevice = applePayHandler.canMakePayments();

        if (isSupportedDevice) {
          createPaymentIntent('apple_pay')
            .then((payment_intent) => {
              setDisplayApplePayBtn(isSupportedDevice);
              setDisplayPayWithCardLabel(true);
              applePayHandler.setPaymentIntent(payment_intent);
              return applePayHandler.mountPaymentButton("#apple-pay-button", {
                buttonColor: 'white-with-line'
              });
            })
            .then(() => {
              // once button mounted
              return applePayHandler.handlePayment(
                {
                  error: function (d) {
                    console.log("error", d);
                    console.log(d?.active_payment_attempt?.error_detail)
                    dispatch(displayError({ 'error': d?.active_payment_attempt?.error_text }));
                  },
                }
              );
            })
            .then((paymentIntent) => {
              //paymentIntent contains authorized payment intent
              dispatch(updatePaymentIntentId({ 'paymentIntentId': paymentIntent?.paymentIntent?.id }))
              setIsWalletUsed(true);
              // navigate("/checkout/createaccount");
            })
            .catch((error) => {
              console.log(error)
              // handle error
            });
        }
      });

    }
  };

  const mountPaymentMethod = (module_name, payment_method_type, button_selector, options) => {
    let paymentHandler;
    if (cbInstance) {
      cbInstance.load(module_name).then((paymentHandler_) => {
        paymentHandler = paymentHandler_;
        createPaymentIntent({ payment_method_type })
          .then((payment_intent) => {
            paymentHandler.setPaymentIntent(payment_intent);
            return paymentHandler.mountPaymentButton(button_selector, options);
          })
          .then(() => {
            // once button mounted
            return paymentHandler.handlePayment();
          })
          .then((paymentIntent) => {
            dispatch(updatePaymentIntentId({ 'paymentIntentId': paymentIntent.id }))
            navigate("/checkout/createaccount");
          })
          .catch((error) => {
            console.log(error)
            // handle error
          });

      });
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm("create-account__form")) {
      var recaptchaToken = await recaptchaAssessment('checkout');

      if (parseFloat(total) === 0 && thankyouPageType === "COURSE") {
        completePurchase({}, recaptchaToken);
      } else {
        let paymentOption_ = null;
        if (!displaySavedPaymentMethod) {
          if (!isWalletUsed) {
            if (cardRef.current) {
              var token = null;
              try {
                setDisableBtn(true);

                if (_3dsEnabled) {
                  let payment_intent = await cardRef.current.authorizeWith3ds(await createPaymentIntent('card'));
                  paymentOption_ = { 'paymentIntentId': payment_intent.id }
                  dispatch(updatePaymentIntentId(paymentOption_))
                } else {
                  token = await cardRef.current.tokenize(event);
                  paymentOption_ = { 'paymentToken': token }
                  dispatch(updatePaymentToken(paymentOption_))
                }
                completePurchase(paymentOption_, recaptchaToken);
                // navigate("/checkout/createaccount");
              } catch (error) {
                setDisableBtn(false);
                console.log(error)
              }
            }
          } else {
            completePurchase(paymentOption, recaptchaToken);
          }
        } else {
          let selectedPaymentSource = $('input[name="payment_source"]:checked').val();
          if (selectedPaymentSource) {
            paymentOption_ = { 'paymentSourceId': selectedPaymentSource }
            dispatch(updatePaymentSource(paymentOption_))
            completePurchase(paymentOption_, recaptchaToken);
            // navigate("/checkout/createaccount");
          }
        }
      }
    }
  };


  const getCardImg = (cardType) => {
    if (cardType === "Visa")
      return visa
    if (cardType === "Mastercard")
      return mastercard
    if (cardType === "AmericanExpress")
      return amex
    if (cardType === "Discover")
      return discover
    if (cardType === "Jcb")
      return jcb
    if (cardType === "DinersClub")
      return diners
    return null
  }

  const completePurchase = (paymentOption, recaptchaToken) => {
    if (validateForm("create-account__form")) {
      let payload = checkoutPayload;

      if (paymentOption?.paymentToken)
        payload = { ...payload, PaymentTokenId: paymentOption.paymentToken };
      else if (paymentOption?.paymentIntentId)
        payload = { ...payload, PaymentIntentId: paymentOption.paymentIntentId };
      else if (paymentOption?.paymentSourceId)
        payload = { ...payload, paymentSourceId: paymentOption.paymentSourceId };
      setDisableBtn(true);
      setErrorMessage("");
      dispatch(createSubscription(payload, recaptchaToken)).then((response) => {
        dispatch(setEcommerceGTMEvent({ ecommerceGtmEvent: response?.subscriptionGTMEvent }))
        createSalesforceCampaign(payload?.billingDetail);
        dispatch(updateDisplayPurchaseComplete({ displayPurchaseComplete: true }))

        navigate("/checkout/thankyou")

      }).catch((error) => {
        setDisableBtn(false);
        if (error?.Param === "payment")
          setErrorMessage(error?.Error)
        else
          dispatch(displayError(error));
      })

    }
  };

  useEffect(() => {
    // dispatch(fetchRenewalEstimate(email)).catch((error) => {
    //     displayError(error);
    // })

    checkForTemporaryPassword();

    focusAndOpenKeyboard(document.getElementById('password'), 300);

    const eyeIcons = document.querySelectorAll('.personal-details__icon--eye');

    const handleClick = (event) => {
      eyeIcons.forEach((item) => {
        item.classList.toggle('crossed');
        if (item.classList.contains('crossed')) {
          item.nextElementSibling.type = 'text';
          item.querySelector('span').textContent = t_label?.['account.label.hide_password'] ?? 'Hide password';
        } else {
          item.nextElementSibling.type = 'password';
          item.querySelector('span').textContent = t_label?.['account.label.show_password'] ?? 'Show password';
        }
      });
    };

    eyeIcons.forEach((item) => {
      item.addEventListener('click', handleClick);
    });

    return () => {
      eyeIcons.forEach((item) => {
        item.removeEventListener('click', handleClick);
      });
    };
  }, []);

  // useEffect(() => {
  //     const delayInMilliseconds = 16 * 60 * 1000;
  //     const timeoutId = setTimeout(checkForTemporaryPassword, delayInMilliseconds);

  //     return () => {
  //         clearTimeout(timeoutId);
  //     }
  // }, [])

  const checkForTemporaryPassword = () => {
    if (email) {
      dispatch(checkUserStatus(email)).then((response) => {
        if (response?.userStatus === "FORCE_CHANGE_PASSWORD") {
          setDisplayTemporaryPasswordMsg(true);
        }
      })
    }
  }

  const createSalesforceCampaign = ({ firstName, lastName }, checkoutStatus = CHECKOUT_STATUS.COMPLETED) => {
    let payload = salesforcePayload({ firstName, lastName, email, checkoutStatus });
    if (gdprEmailUpdates !== undefined) {
      payload = { ...payload, 'OptinMarketing': gdprEmailUpdates ? "YES" : "NO" }
    }
    return dispatch(createCampaignMember(payload))
  }

  const getPeriodText2 = (periodUnit) => {
    if (periodUnit) {
      if (periodUnit === "Month")
        return t_label?.['checkout.label.monthly'] ?? "monthly"
      else if (periodUnit === "Year")
        return t_label?.['checkout.label.annual'] ?? "annual"
      else if (periodUnit === "Day")
        return t_label?.['checkout.label.day'] ?? "day"
    }
    return null;
  }


  return (
    displayPage ?
      <>
        {
          cbInstance &&
          <div className="body body--alt2">
            <Header />

            <main className="main main--fullh">
              <section className="section personal-details">
                <div className="grid-cont">

                  <form onSubmit={handleSubmit} noValidate id="create-account__form" className="personal-details__form">
                    <h2 className="personal-details__heading">
                      {
                        userStatus === "NEW" ?
                          t_label?.['checkout.label.confirm_purchase_and_create_account'] ?? 'Confirm Purchase & Create Account'
                          :
                          t_label?.['checkout.label.confirm_purchase'] ?? 'Confirm Purchase'
                      }
                    </h2>

                    <label className="personal-details__label">{t_label?.['checkout.label.your_email'] ?? 'Your Email'}</label>
                    <div className="form-group personal-details__control personal-details__control--hastip">
                      <div className="personal-details__icon personal-details__icon--prefix">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M11.5 7.5H4.5C3.94772 7.5 3.5 7.94772 3.5 8.5V12C3.5 12.5523 3.94772 13 4.5 13H11.5C12.0523 13 12.5 12.5523 12.5 12V8.5C12.5 7.94772 12.0523 7.5 11.5 7.5Z" />
                          <path d="M5.5 7.5V5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5V7.5" />
                        </svg>
                        <div className="personal-details__tooltip">
                          <span>{t_message?.['checkout.message.fixed_email'] ?? "This email address was entered earlier in the process and cannot be changed. If you would like to use another email address, please start over."}</span>
                        </div>
                      </div>
                      <input disabled type="email" value={email} name="email" id="email" className="personal-details__input" />
                    </div>


                    {
                      !(parseFloat(total) === 0 && thankyouPageType === "COURSE") &&
                      <div className='mt-8'>
                        <h2 className="personal-details__heading">{t_label?.['billing.label.payment_details']}</h2>
                        <h5 className='payment-detail__subtitle'>{t_label?.['checkout.label.choose_payment_method']}</h5>

                        <div style={{ 'display': displaySavedPaymentMethod ? 'none' : 'block' }}>
                          <div className='flex-container flex-col'>

                            <div
                              id='apple-pay-button'
                              className='payment-details__button'
                              style={displayApplePayBtn ? {} : { 'display': 'none' }}
                            >
                            </div>

                            <div
                              id='gpay-button'
                              className='payment-details__button'
                              style={payment_module.includes('google_pay') ? {} : { 'display': 'none' }}
                            >
                            </div>

                          </div>
                          <div
                            className='flex-container flex-col payment-detail__label'
                            style={displayPayWithCardLabel ? {} : { 'display': 'none' }}
                          >{
                              t_label?.['checkout.label.pay_with_card']}
                          </div>
                          <CBCardComponent ref={cardRef} />
                        </div>

                        {
                          displaySavedPaymentMethod &&
                          <div className="form-group container-gray">
                            <form>
                              <div className='saved-payment-methods-title'>{t_label?.['checkout.label.saved_payment_method']}</div>

                              {
                                paymentSources.map(paymentSource => {
                                  return (
                                    <>
                                      <label htmlFor={paymentSource.id}>
                                        <span className="mt-5 flex items-center gap-1.5 cursor-pointer">
                                          <input defaultChecked={paymentSource?.isPrimary} className='saved-payment-methods-option' type="radio" id={paymentSource.id} name="payment_source" value={paymentSource.id} />

                                          {
                                            paymentSource?.card?.cardType != null && getCardImg(paymentSource?.card?.cardType) &&
                                            <img src={getCardImg(paymentSource?.card?.cardType)} width="30" height="18" />

                                          }
                                          <span className="text-h3">{t_label?.['billing.label.ending_in'] ?? 'Ending in'} <span className="font-bold text-gray-900">{paymentSource.card.last4}</span> {t_label?.['billing.label.expiring'] ?? 'expiring'} <span className="font-bold text-gray-900">{`${paymentSource.card.expiryMonth}/${paymentSource.card.expiryYear}`}</span></span>
                                        </span>

                                      </label>
                                    </>
                                  )
                                })
                              }
                            </form>
                          </div>
                        }

                        {errorMessage && <div className="cc-error" role="alert">{errorMessage}</div>}


                        {
                          paymentSources.length > 0 &&
                          <div className='flex-container'>
                            <div
                              onClick={(e) => setDisplaySavedPaymentMethod(!displaySavedPaymentMethod)}
                              className='payment-methods__add'
                            >
                              {displaySavedPaymentMethod ? t_label?.['checkout.label.add_new_payment_method'] : t_label?.['checkout.label.use_saved_payment_method']}
                            </div>
                          </div>
                        }

                        {/* <button onClick={handleSubmit} type="submit" className="button payment-details__submit">{t_label?.['checkout.label.continue_to_confirmation']}</button> */}
                        {/* </form> */}
                      </div>
                    }

                    {
                      subStatus === "InTrial" &&
                      <label className="form-group personal-details__checkbox">
                        <input id='agreement' required type="checkbox" name="agreement" />

                        <Localization text={thankyouPageType != 'ADD_ON' ? t_message?.['checkout.message.subscription_trial_billing'] : t_message?.['checkout.message.subscription_billing_one_time']} values={{
                          trialPeriod,
                          trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                          amount: displayPrice(nextTotal, currencyCode),
                          periodUnit: getPeriodText2(periodUnit)?.toLowerCase()
                        }}
                        />
                        &nbsp;
                        <Localization text={t_message?.['checkout.message.subscription_trial_billing_acknowledge']} values={{
                          trialPeriod,
                          trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                          periodUnit: getPeriodText2(periodUnit)?.toLowerCase()
                        }}
                        />

                      </label>
                    }

                    <label className="form-group personal-details__checkbox">
                      <input id='accept_tos' required type="checkbox" name="accept_tos" />
                      <span dangerouslySetInnerHTML={{ __html: t_message?.['checkout.message.agree_to_policy'] }} />
                    </label>

                    <button disabled={disableBtn} type="submit" className={`button payment-details__submit ${disableBtn ? 'disabled' : ''}`}>{t_label?.['checkout.label.complete_purchase']}</button>
                  </form>

                  <OrderSummary />

                </div>

              </section >
            </main >

            <Footer />

          </div >
        }
      </>
      :
      <LinearProgress />
  )
}

export default SelectPaymentMethod