import axios from "axios";
import ls from "./localstore";

import { deepTransformKeys } from "./object";
import { toCamelCase, toSnakeCase } from "./text";
import { vvLogin } from "../settings.js/dev";

const axiosClient = axios.create();

const request = (method, service, url, opts) => {
  const payload = {
    baseURL: service,
    headers: {},
    method,
    url,
    ...opts,
  };

  let token = ls.get('token');

  if (sendToken(url, token)) {
    payload['headers']['Authorization'] = `Bearer ${token}`;
  }

  return axiosClient
    .request(payload)
    .then((response) => {
      const { data } = response;
      return deepTransformKeys(data, toCamelCase);
    })
    .catch((error) => {
      const { config, response } = error;
      if (response) {
        const { status, data } = response;
        console.warn(
          `${config.method} call to "${config.url}" resulted in ${status} (${data.code})`
        );

        if (data.Error && (data.Error === "Session expired. Please login again" || data.Error === "Something went wrong. Please login again")) {
          ls.remove('token');
          window.location.href = vvLogin;
        }

        const throwable = { ...data, status };
        throw throwable;
      } else {
        console.log(`Error: ${error.message}`);
        const throwable = {
          status: -1,
          code: "-1",
          message: error.message,
          backtrace: error.stack,
        };
        throw throwable;
      }
    });
};

const api = {
  get(service, url, data = {}) {
    return request("GET", service, url, {
      params: deepTransformKeys(data, toCamelCase),
    });
  },
  post(service, url, data = {}, opts) {
    return request("POST", service, url, {
      data: deepTransformKeys(data, toCamelCase),
      ...opts
    });
  },
  put(service, url, data = {}) {
    return request("PUT", service, url, {
      data: deepTransformKeys(data, toCamelCase),
    });
  },
  delete(service, url, data = {}) {
    return request("DELETE", service, url, {
      params: deepTransformKeys(data, toCamelCase),
    });
  },
};

const sendToken = (url, token) => {
  if (
    token == null ||
    [
      "/login",
      "/signup",
      "/checkout/hosted-page",
      "/checkout/estimate",
      "/site",
    ].includes(url)
  ) {
    return false;
  }
  return true;
};

export default api;

export const plugin = ({ baseUrl, method, path, params }) =>
  api[method](baseUrl, path, params);
