/**
 * Author: Harsh Agrawal
 * Summary: This page represents the not eligible page. It dynamically displays a message indicating ineligibility based on the selected items.
 */

import React from 'react'
import Header from '../../../components/layout/header'
import Footer from '../../../components/layout/footer'
import Intro from '../../../assets/img/intro.png'
import Intro2x from '../../../assets/img/intro@2x.png'
import { useLocation } from 'react-router-dom'
import { vvLogin } from '../../../settings.js/dev'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../reducers/translation'
import { TRANSLATION_TYPE } from '../../../utils/constants'

function NotEligible() {
    const { state } = useLocation();

    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    return (
        <>
            <div className="body body--alt">
                <Header />

                <main className="main main--fullh">
                    <section className="section not-eligible">
                        <div className="grid-cont">
                            <img src={Intro} srcSet={`${Intro2x} 2x`} width="409" height="225" alt="" className="thanks__img" />
                            <h1 className="not-eligible__title">{state?.message}</h1>
                            <p className="not-eligible__subtitle">{state?.description}</p>
                            <a href={vvLogin} className="button thanks__button">{t_label?.['billing.label.upgrade_my_plan'] ?? 'Upgrade My Plan'}</a>
                        </div>
                    </section>
                </main>

                <Footer />
            </div>
        </>
    )
}

export default NotEligible