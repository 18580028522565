import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './assets/css/output.css';
import './assets/css/main.css'; // import main.css after output.css to overide css conflict
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider >
);

reportWebVitals();
