import React from "react";
import { useNavigate } from "react-router-dom";
import { vvLogin } from "../../../settings.js/dev";
import Footer from "../../../components/layout/footer";
import { getTranslation } from "../../../reducers/translation";
import { TRANSLATION_TYPE } from "../../../utils/constants";
import { useSelector } from "react-redux";

const PasswordResetSuccess = () => {
  let navigate = useNavigate();
  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  return (
    <>
      <div>
        <body class="login-body">
          <header class="header">
            <img src="img/logo.png" srcset="img/logo@2x.png 2x" alt="" />

            <nav class="user-nav">
              <span>
                Already a<span class="xs:d-none"> VectorVest</span> Customer?
              </span>
              <a
                href={vvLogin}
                target="_blank"
                class="user-nav__link"
              >
                Sign In
              </a>
            </nav>
          </header>

          <section class="login">
            <div class="grid-cont">
              <form action="thanks.html" id="login__form" class="login__form">
                <div class="login__cont">
                  <h1 class="login__title">
                    {t_message?.['account.message.password_reset'] ?? 'Thank you! Your password has been successfully reset.'}
                  </h1>
                </div>
                <div class="login__foot">
                  <div class="login__foot-inner">
                    <div
                      class="button cursor-pointer"
                    >
                      <span>
                        <a
                          href={vvLogin}

                          class="user-nav__link"
                        >
                          {t_label?.['account.button.sign_in'] ?? 'Sign In'}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <Footer />
        </body>
      </div>
    </>
  );
};

export default PasswordResetSuccess;
