const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const formatDate = (date) => {
    if (date === null)
        return;

    try {
        const datePart = date.split("-");

        const day = datePart[2]
        const month = monthNames[datePart[1] - 1];
        const year = datePart[0];

        const formattedDate = `${month} ${day}, ${year}`;

        return formattedDate;
    } catch (error) {
        console.log(error);
    }
    return;
}

export const formatDate2 = (date) => {
    var options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleString('en-US', options);
}

export const addMonthToCurrent = (monthsToAdd) => {
    var date = new Date();
    date.setMonth(date.getMonth() + monthsToAdd);
    return date;
}