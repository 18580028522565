import React from 'react';
import logopng from './../../../assets/img/logo-blue@2x.png';
import logo2xpng from './../../../assets/img/logo-blue@2_2x.png';

export default function Header() {
    return (
        <>
            <header className="header">
                <img src={logopng} srcSet={`${logo2xpng} 2x`} alt="" />
            </header>
        </>
    )
}
