import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { useEffect } from "react";
import VVRoutes from "./Routes";
import SnackbarAlert from "./components/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { initializeTranslation } from "./reducers/translation";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeTranslation());
  }, [])

  return (
    <>
      <Router>
        <VVRoutes />
      </Router>
      <SnackbarAlert />
    </>
  );
}

export default App;