// useModalUtils.js

import { useEffect } from 'react';

export function focusAndOpenKeyboard(el, timeout) {
    if (!timeout) {
        timeout = 100;
    }
    if (el) {
        // Getting error on using commented code: ResizeObserver loop completed with undelivered notifications.

        // var __tempEl__ = document.createElement('input');
        // __tempEl__.style.position = 'absolute';
        // __tempEl__.style.top = el.offsetTop + 7 + 'px';
        // __tempEl__.style.left = el.offsetLeft + 'px';
        // __tempEl__.style.height = 0;
        // __tempEl__.style.opacity = 0;
        // document.body.appendChild(__tempEl__);
        // __tempEl__.focus();

        setTimeout(function () {
            el.focus();
            el.click();
            // document.body.removeChild(__tempEl__);
        }, timeout);
    }
}

export function slideToggle(element) {
    element.classList.toggle('visible');
    element.style.maxHeight = (element.classList.contains('visible') ? 69 + element.scrollHeight : 0) + 'px';
}

function useModalUtils() {
    useEffect(() => {
        const handleModalClick = (event) => {
            event.preventDefault();
            document.body.style.overflow = 'hidden';
            document.getElementById(event.target.getAttribute('data-modal-id')).classList.add('active');
        };

        const handleModalCloseClick = (event) => {
            event.preventDefault();
            document.body.style.overflow = 'visible';
            event.target.closest('.modal').classList.remove('active');
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                document.body.style.overflow = 'visible';
                document.querySelectorAll('.modal').forEach((item) => {
                    item.classList.remove('active');
                });
            }
        };

        document.querySelectorAll('[data-modal-id]').forEach((item) => {
            item.addEventListener('click', handleModalClick);
        });

        document.querySelectorAll('.modal__back, .modal__close').forEach((item) => {
            item.addEventListener('click', handleModalCloseClick);
        });

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.querySelectorAll('[data-modal-id]').forEach((item) => {
                item.removeEventListener('click', handleModalClick);
            });

            document.querySelectorAll('.modal__back, .modal__close').forEach((item) => {
                item.removeEventListener('click', handleModalCloseClick);
            });

            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);
}

export function usePreventBackNavigation(props) {
    const allowBackNavigation = props && props.allowBackNavigation === true;
    useEffect(() => {
        if (!allowBackNavigation) {
            const handlePopstate = () => {
                window.history.pushState(null, document.title, window.location.href);
            };

            // Initialize history state and add the event listener
            window.history.pushState(null, document.title, window.location.href);
            window.addEventListener('popstate', handlePopstate);

            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('popstate', handlePopstate);
            };
        }
    }, []);
}

export function deletePacContainer() {
    var pacContainers = document.getElementsByClassName("pac-container");


    for (var i = pacContainers.length - 1; i >= 0; i--) {
        var pacContainer = pacContainers[i];
        pacContainer.parentNode.removeChild(pacContainer);
    }

}

export default useModalUtils;
