import React from 'react'
import { Outlet, Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import localStore from './utils/localstore';
import { logout2 } from './reducers/auth';
import { vvLogin } from './settings.js/dev';
import cookieStore from './utils/cookie';
import { parseJwt } from './utils/jwt';

const ProtectedRoute = ({ }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    let accessToken = localStore.get("token");

    if (searchParams.has("token") || cookieStore.get("vvstore")) {
        let token = searchParams.get("token") ?? cookieStore.get("vvstore");

        if (accessToken != null && token != accessToken) {
            const accessTokenDecoded = parseJwt(accessToken);
            const tokenDecoded = parseJwt(token);

            if (accessTokenDecoded?.email != tokenDecoded?.email) {
                dispatch(logout2());
                return <></>
            }
        }

        localStore.set('token', token);
        accessToken = token;
    }

    if (accessToken == null) {
        window.location.href = vvLogin;
        return <></>
        // return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;