/**
 * Author: Harsh Agrawal
 * Summary: This page enables users to update their account details.
 */

import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { focusAndOpenKeyboard } from "../../../utils/globalUtils";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import GoogleMapAddress from "../../../components/address";
import { validateForm } from "../../../utils/form";
import { updateUserBillingDetails } from "../../../reducers/accountDetails";
import { displayError } from "../../../reducers/snackbar";
import SideBar from "../../../components/layout/sidebar";
import styles from './../../../assets/css/output.module.css'
import Footer from "../../../components/layout/footer";
import { getTranslation } from "../../../reducers/translation";
import { TRANSLATION_TYPE } from "../../../utils/constants";

export default function EditAccBillingDetails() {
  const { billingDetail } = useSelector((state) => state.accountDetails);
  const { email } = useSelector((state) => state.accountDetails);
  const [searchResult, setSearchResult] = useState({});

  const [formData, setFormData] = useState({
    firstName: billingDetail.firstName,
    lastName: billingDetail.lastName,
    address: billingDetail.addressLine1,
    address2: billingDetail.addressLine2,
    city: billingDetail.city,
    state: billingDetail.state,
    zip: billingDetail.zip,
    country: billingDetail.country,
    phone: billingDetail.phone,
    isSubmitted: false,
  });

  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    focusAndOpenKeyboard(document.getElementById("first-name"), 300);
  }, []);

  const validateBillingForm = () => {
    setTimeout(() => {
      validateForm("edit-account-billing-details__form");
    }, 50);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    // Handle checkboxes separately
    const inputValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const handleAddressAutoComplete = () => {
    let address = "";
    let zip = "";
    let city = "";
    let state = "";
    let stateCode = "";
    let country = "";

    if (searchResult != null) {
      const place = searchResult.getPlace();
      const address_components = place.address_components;

      address_components.forEach((component) => {
        const { short_name, long_name } = component;
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number":
            address = long_name + " ";
            break;
          case "route":
            address += short_name;
            break;
          case "locality":
            city = long_name;
            break;
          case "postal_town":
            city = long_name;
            break;
          case "administrative_area_level_1":
            stateCode = short_name;
            state = long_name;
            break;
          case "postal_code":
            zip = long_name;
            break;
          case "country":
            country = short_name;
            break;
          default:
        }
      });
    }

    let formData_ = {
      ...formData,
      address,
      city,
      state,
      stateCode,
      zip,
      country,
    };

    setFormData(formData_);

    focusAndOpenKeyboard(document.getElementById("address2"));

    if (formData.isSubmitted) validateBillingForm();

    // fetchEstimate(formData_);
  };

  const autoCompleteAddress = ({
    address,
    city,
    state,
    stateCode,
    zip,
    country,
  }) => {
    let formData_ = {
      ...formData,
      address,
      city,
      state,
      stateCode,
      zip,
      country,
    };

    setFormData(formData_);

    focusAndOpenKeyboard(document.getElementById("address2"));

    if (formData.isSubmitted) validateBillingForm();
  };

  const handleSubmit = () => {
    if (validateForm("edit-account-billing-details__form")) {
      let payload = createEditBillingDetailsPayload();
      dispatch(updateUserBillingDetails(payload))
        .then((response) => {
          navigate("/account");
        })
        .catch((error) => {
          dispatch(displayError(error));
        });
    }
  };

  const createEditBillingDetailsPayload = () => {
    let billingDetail_ = {
      FirstName: `${formData.firstName}`,
      LastName: `${formData.lastName}`,
      AddressLine1: `${formData.address}`,
      AddressLine2: formData.address2 !== null ? `${formData.address2}` : null,
      City: `${formData.city}`,
      State: `${formData.state}`,
      Zip: `${formData.zip}`,
      Country: `${formData.country}`,
      Phone: `${formData.phone}`,
    };

    let payload = { billingDetail: billingDetail_, email };

    return payload;
  };
  return (
    <>
      <div class={`dark ${styles.body} bg-white pt-22 md:pl-13 md:pt-0 lg:pl-50`}>
        <SideBar page="account" />
        <div class="min-h-[100dvh] flex flex-col">
          <div class="mb-auto">
            <main class="mx-auto box-content max-w-[580px] p-5 md:p-10 lg:p-12">
              <div class="mb-7 flex">
                <div
                  class="flex items-center gap-1 text-sm hover:text-blue-600 cursor-pointer"
                  onClick={() => navigate("/account")}
                >
                  <svg class="h-4 w-4">
                    <use href="img/sprite.svg#arrow-left"></use>
                  </svg>

                  <span>{t_label?.['account.button.go_back'] ?? 'Go Back'}</span>
                </div>
              </div>

              <form
                action=""
                class="mt-5 rounded-md bg-gray-0 p-5"
                id="edit-account-billing-details__form"
              >
                <h2 class="text-h2 font-bold text-gray-900">
                  {t_label?.['account.label.edit_personal_details'] ?? 'Edit Personal Details'}
                </h2>
                <div class="gap-x-5 md:grid md:grid-cols-2">
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.first_name'] ?? 'First Name'}</div>
                    <div class="form-group relative mt-2">
                      <input
                        required
                        data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                        type="text"
                        placeholder=""
                        name="firstName"
                        value={formData.firstName}
                        id="first-name"
                        class="block w-full appearance-none rounded-md border border-gray-500/30 bg-gray-800 py-1.5 px-[9px] text-white placeholder:text-gray-500 hover:border-gray-500 focus:border-blue-selected focus:bg-gray-900 disabled:pointer-events-none disabled:opacity-50 aria-invalid:border-red dark:border-gray-300/50 dark:bg-white dark:text-gray-900 dark:placeholder:text-gray-400 dark:hover:border-gray-300 dark:focus:border-blue-selected dark:focus:bg-white dark:focus:ring-2 dark:focus:ring-blue-selected/15 dark:aria-invalid:border-red"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.last_name'] ?? 'Last Name'}</div>
                    <div class="form-group relative mt-2">
                      <input
                        required
                        data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        id="last-name"
                        class="block w-full appearance-none rounded-md border border-gray-500/30 bg-gray-800 py-1.5 px-[9px] text-white placeholder:text-gray-500 hover:border-gray-500 focus:border-blue-selected focus:bg-gray-900 disabled:pointer-events-none disabled:opacity-50 aria-invalid:border-red dark:border-gray-300/50 dark:bg-white dark:text-gray-900 dark:placeholder:text-gray-400 dark:hover:border-gray-300 dark:focus:border-blue-selected dark:focus:bg-white dark:focus:ring-2 dark:focus:ring-blue-selected/15 dark:aria-invalid:border-red"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-5 text-sm">{t_label?.['checkout.label.phone'] ?? 'Phone'}</div>
                <div class="form-group relative mt-2">
                  <input
                    required
                    data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                    type="text"
                    placeholder=""
                    name="phone"
                    value={formData.phone}
                    id="phone"
                    class="block w-full appearance-none rounded-md border border-gray-500/30 bg-gray-800 py-1.5 px-[9px] text-white placeholder:text-gray-500 hover:border-gray-500 focus:border-blue-selected focus:bg-gray-900 disabled:pointer-events-none disabled:opacity-50 aria-invalid:border-red dark:border-gray-300/50 dark:bg-white dark:text-gray-900 dark:placeholder:text-gray-400 dark:hover:border-gray-300 dark:focus:border-blue-selected dark:focus:bg-white dark:focus:ring-2 dark:focus:ring-blue-selected/15 dark:aria-invalid:border-red"
                    onChange={handleInputChange}
                  />
                </div>
                <div class="mt-5 text-sm ">{t_label?.['checkout.label.address'] ?? 'Address'}</div>
                <div className="relative mt-2 form-group personal-details__control">
                  <GoogleMapAddress
                    address={formData.address}
                    callback={autoCompleteAddress}
                    onChange={handleInputChange}
                  />
                </div>

                <div class="personal-details__control relative mt-2">
                  <input
                    type="text"
                    name="address2"
                    value={formData.address2}
                    id="address2"
                    class="block w-full appearance-none rounded-md border border-gray-500/30 bg-gray-800 py-1.5 px-[9px] text-white placeholder:text-gray-500 hover:border-gray-500 focus:border-blue-selected focus:bg-gray-900 disabled:pointer-events-none disabled:opacity-50 aria-invalid:border-red dark:border-gray-300/50 dark:bg-white dark:text-gray-900 dark:placeholder:text-gray-400 dark:hover:border-gray-300 dark:focus:border-blue-selected dark:focus:bg-white dark:focus:ring-2 dark:focus:ring-blue-selected/15 dark:aria-invalid:border-red"
                    onChange={handleInputChange}
                  />
                </div>


                <div class="gap-x-5 md:grid md:grid-cols-3">
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['account.label.state'] ?? 'State'}</div>
                    <div class="form-group relative mt-2">
                      <input
                        required
                        data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                        type="text"
                        name="state"
                        value={formData.state}
                        id="state"
                        class="block w-full appearance-none rounded-md border border-gray-500/30 bg-gray-800 py-1.5 px-[9px] text-white placeholder:text-gray-500 hover:border-gray-500 focus:border-blue-selected focus:bg-gray-900 disabled:pointer-events-none disabled:opacity-50 aria-invalid:border-red dark:border-gray-300/50 dark:bg-white dark:text-gray-900 dark:placeholder:text-gray-400 dark:hover:border-gray-300 dark:focus:border-blue-selected dark:focus:bg-white dark:focus:ring-2 dark:focus:ring-blue-selected/15 dark:aria-invalid:border-red"
                        onChange={handleInputChange}
                      />
                      <div class="pointer-events-none absolute top-0 right-0 flex h-full items-center justify-center gap-1 px-2">
                        <svg class="h-4 w-4 text-gray-500 opacity-60 dark:text-gray-400">
                          <use href="img/sprite.svg#chevron-down"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['account.label.zip'] ?? 'Zip'}</div>
                    <div class="form-group relative mt-2">
                      <input
                        required
                        data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                        type="text"
                        name="zip"
                        value={formData.zip}
                        id="zip"
                        class="block w-full appearance-none rounded-md border border-gray-500/30 bg-gray-800 py-1.5 px-[9px] text-white placeholder:text-gray-500 hover:border-gray-500 focus:border-blue-selected focus:bg-gray-900 disabled:pointer-events-none disabled:opacity-50 aria-invalid:border-red dark:border-gray-300/50 dark:bg-white dark:text-gray-900 dark:placeholder:text-gray-400 dark:hover:border-gray-300 dark:focus:border-blue-selected dark:focus:bg-white dark:focus:ring-2 dark:focus:ring-blue-selected/15 dark:aria-invalid:border-red"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.country'] ?? 'Country'}</div>
                    <div class="relative mt-2">
                      <select
                        required
                        data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                        name="country"
                        id="country"
                        value={formData.country}
                        class="personal-details__select"
                        onChange={handleInputChange}
                      >
                        {options.map((option_) => (
                          <option value={option_.value}>{option_.label}</option>
                        ))}
                      </select>
                      <div class="pointer-events-none absolute top-0 right-0 flex h-full items-center justify-center gap-1 px-2">
                        <svg class="h-4 w-4 text-gray-500 opacity-60 dark:text-gray-400">
                          <use href="img/sprite.svg#chevron-down"></use>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="-mx-5 mt-4 border-gray-300/50" />
                <div class="-mx-5 -mb-5 flex items-center justify-end gap-2 p-3">
                  <div
                    class="flex h-[26px] items-center justify-center gap-1 rounded-md bg-gray-700 px-2.5 text-sm text-blue-300 ring-1 ring-inset ring-blue-300/30 hover:text-white hover:shadow-button-ter-hover active:text-white active:shadow-button-ter-hover active:ring-blue-300/80 disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-blue-100 dark:text-blue-600 dark:ring-blue-200 dark:hover:bg-blue-200 dark:hover:ring-blue-300 dark:active:bg-blue-300 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent cursor-pointer"
                    onClick={() => navigate("/account")}
                  >
                    <svg class="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                      <use href="img/sprite.svg#close"></use>
                    </svg>

                    <span>{t_label?.['account.label.cancel'] ?? 'Cancel'}</span>
                  </div>
                  <div
                    onClick={handleSubmit}
                    type="submit"
                    class="flex h-[26px] items-center justify-center gap-1 rounded-md bg-gradient-to-b from-blue-600 to-blue-800 px-2.5 text-sm text-white shadow-button hover:shadow-button-hover active:shadow-button-active disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none dark:bg-blue-600 dark:bg-none dark:shadow-none dark:hover:bg-blue-800 dark:active:bg-blue-900 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 cursor-pointer"
                  >
                    <svg class="h-4 w-4 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                      <use href="img/sprite.svg#check"></use>
                    </svg>

                    <span>{t_label?.['account.label.save'] ?? 'Save'}</span>
                  </div>
                </div>
              </form>
            </main>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
