import api from "./api";
import { baseURL } from "../settings.js/dev";
import { captchaKey } from "../settings.js/dev";

export const recaptchaAssessment = async (action) => {
  await new Promise((resolve) => {
    window.grecaptcha.enterprise.ready(resolve);
  });

  try {
    const token = await window.grecaptcha.enterprise.execute(captchaKey, { action });
    return token;
  } catch (error) {
    console.error('Error executing reCAPTCHA:', error);
  }


  return null;
}