import { createSlice } from "@reduxjs/toolkit";
import { parseError } from "../utils/error";

const initialState = {
  visible: false,
  content: null,
  severity: "error",
  vertical: "top",
  horizontal: "center",
  timeout: 6000,
};
const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.visible = true;
      state.content = action.payload.message;
      if (action.payload.severity === "success") {
        state.severity = "success";
      } else {
        state.severity = "error";
      }
    },
    hideSnackbar: (state) => {
      state.visible = false;
      state.content = null;
      // state.severity = null;
    },
  },
});

export const displayError = (error) => (dispatch) => {
  let msg = parseError(error);
  if (msg) dispatch(showSnackbar({ message: msg }));
};

export const displaySuccess = (msg) => (dispatch) => {
  if (msg) dispatch(showSnackbar({ message: msg, severity: "success" }));
};

export const displayResendVerification = () => (dispatch) => {
  let msg = "Email Resend!";
  if (msg) dispatch(showSnackbar({ message: msg, severity: "success" }));
};

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
