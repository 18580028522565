/**
 * Author: Harsh Agrawal
 * Summary: This page displays account details, allowing users to view their account information.
 */

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from './../../../assets/css/output.module.css';
import { updateCustomerEmail } from "../../../reducers/accountDetails";
import { displayError } from "../../../reducers/snackbar";
import { fetchUserBillingDetails } from "../../../reducers/accountDetails";
import rotate from "./../../../assets/img/rotate.png";
import { TiTick } from "react-icons/ti";
import mail from "./../../../assets/img/mail.png";
import { confirmUserEmail } from "../../../reducers/accountDetails";
import { displaySuccess } from "../../../reducers/snackbar";
import { getAddressStr } from "../../../utils/address";
import countryList from "react-select-country-list";
import { displayResendVerification } from "../../../reducers/snackbar";
import SideBar from "../../../components/layout/sidebar";
import { initChargebee } from "../../../reducers/chargebee";
import $ from 'jquery';
import Footer from './../../../components/layout/footer';
import { vvEmailPreference } from "../../../settings.js/dev";
import { getTranslation } from "../../../reducers/translation";
import { TRANSLATION_TYPE } from "../../../utils/constants";

export const AccountLoginDetails = () => {
  const { billingDetail } = useSelector((state) => state.accountDetails);
  const { isCbInitiated } = useSelector((state) => state.chargebee);
  const options = useMemo(() => countryList().getData(), []);
  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const dispatch = useDispatch();

  const { email, emailVerified, updateEmail, isElite } = useSelector(
    (state) => state.accountDetails
  );

  const [code, setCode] = useState("");
  let navigate = useNavigate();

  const updateCode = (e) => {
    setCode(e.target.value);
  };

  const fetchUserDetails = () => {
    dispatch(fetchUserBillingDetails(email))
      .then((response) => {

      })
      .catch((error) => {
        dispatch(displayError(error));
      });
  };

  useEffect(() => {
    fetchUserDetails();

    if (!isCbInitiated) {
      dispatch(initChargebee()).catch((error) => {
        dispatch(displayError(error));
      })
    }

  }, [email]);

  useEffect(() => {
    $('[data-toggle]').on('click', function (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      const $this = $(this);
      const $target = $($this.attr('data-toggle'));
      $target.attr('aria-hidden', $target.attr('aria-hidden') == 'false' ? true : false);
    });
  }, []);

  const handleUserVerification = (event) => {
    dispatch(
      confirmUserEmail({
        Code: code,
      })
    )
      .then((response) => {
        dispatch(displaySuccess("Email Verified"));

      })
      .catch((error) => {
        dispatch(displayError(error));
      });
  };

  const verificationResendMethod = () => {
    dispatch(updateCustomerEmail({ email, newEmail: updateEmail, confirmEmail: updateEmail }))
      .then((response) => {
        dispatch(displayResendVerification());

      })
      .catch((error) => {
        dispatch(displayError(error));
      });
  };

  const getAddress = () => {
    let address = "";
    if (billingDetail) {
      let { addressLine1, addressLine2, city, state, zip, country } =
        billingDetail;
      const addressComponents = [
        addressLine1,
        addressLine2,
        city,
        state,
        zip,
        country,
      ];
      const filteredAddressComponents = addressComponents.filter(
        (component) => component
      );
      address = filteredAddressComponents.join(", ");
    }
    return address;
  };

  const navigateToUpdateEmailPreferences = () => {
    // window.location.href = `${vvEmailPreference}?email=${email}`;
    window.open(
      `${vvEmailPreference}?email=${email}`,
      '_blank'
    );
  }

  return (
    <>
      <div class={`dark ${styles.body}  bg-white pt-22 md:pl-13 md:pt-0 lg:pl-50`}>
        <SideBar page="account" />

        <div class="min-h-[100dvh] flex flex-col">
          <div class="mb-auto">
            <main class="mx-auto box-content max-w-[580px] p-5 md:p-10 lg:p-12">
              <div class="mb-7 flex items-center gap-2.5">
                <h1 class="text-h1 text-gray-900">{t_label?.['account.label.account'] ?? 'Account'}</h1>
                {isElite === true && (
                  <div class="-my-px flex h-6 items-center gap-1 rounded-full bg-gradient-to-b from-[#FFE3A3] to-[#FEBE2B] px-1.5 text-[#835D06]">
                    <svg class="h-4 w-4">
                      <use href="img/sprite16f.svg#crown"></use>
                    </svg>
                    <div class="text-xs font-bold">{t_label?.['account.label.elite_member'] ?? 'Elite Member'}</div>
                  </div>
                )}
              </div>

              <div class="mt-5 rounded-md bg-gray-0 p-5">
                <div class="flex items-center justify-between">
                  <h2 class="text-h2 font-bold text-gray-900">{t_label?.['account.label.login_details'] ?? 'Login Details'}</h2>
                  <div class="cursor-pointer -my-1">
                    <div
                      onClick={() => navigate("/editEmail")}
                      class="flex h-[26px] font-bold items-center justify-center gap-1 rounded-md border border-gray-600 px-2.5-px text-sm hover:border-gray-500 hover:text-gray-300 active:border-gray-400 active:text-gray-200 disabled:border-gray-800 disabled:text-gray-600 dark:border-gray-300 dark:text-gray-600 dark:hover:bg-gray-200 dark:active:border-gray-500 dark:disabled:border-gray-300 dark:disabled:bg-transparent dark:disabled:text-gray-400"
                    >
                      <svg class="h-4 w-4 text-gray-400 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                        <use href="img/sprite.svg#edit"></use>
                      </svg>
                      <span>{t_label?.['account.label.edit'] ?? 'Edit'}</span>
                    </div>
                  </div>
                </div>
                <div class="mt-5 text-sm">{t_label?.['checkout.label.email'] ?? 'Email'}</div>
                <div class="mt-2 flex flex-wrap items-center justify-between gap-2">
                  <div class="text-lg text-gray-900">{email}</div>
                  {
                    email &&
                    <button
                      type="button"
                      disabled=""
                      onClick={() => navigateToUpdateEmailPreferences()}
                      class="flex h-[26px] font-bold items-center justify-center gap-1 rounded-md bg-button-secondary px-2.5 text-sm text-blue-300 shadow-button hover:text-white active:text-white active:ring-1 active:ring-inset active:ring-blue-300/80 disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-none dark:text-gray-600 dark:ring-1 dark:ring-inset dark:ring-blue-200 dark:hover:bg-blue-100 dark:hover:ring-blue-300 dark:active:bg-blue-100 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent"
                    >
                      <span>{t_label?.['checkout.button.update_email_preference'] ?? 'Update Email Preferences'}</span>
                    </button>
                  }
                </div>

                <div class="mt-6 flex">
                  <button
                    type="button"
                    disabled=""
                    onClick={() => navigate("/reset")}
                    class="flex h-[26px] font-bold items-center justify-center gap-1 rounded-md bg-button-secondary px-2.5 text-sm text-blue-300 shadow-button hover:text-white active:text-white active:ring-1 active:ring-inset active:ring-blue-300/80 disabled:bg-gray-700 disabled:bg-none disabled:text-gray-500 disabled:shadow-none disabled:ring-transparent dark:bg-white dark:bg-none dark:text-blue-600 dark:ring-1 dark:ring-inset dark:ring-blue-200 dark:hover:bg-blue-100 dark:hover:ring-blue-300 dark:active:bg-blue-100 dark:active:ring-blue-500 dark:disabled:bg-gray-300 dark:disabled:text-gray-400 dark:disabled:ring-transparent"
                  >
                    <span>{t_label?.['account.label.change_password'] ?? 'Change Password'}</span>
                  </button>
                </div>
              </div>

              <div class="mt-5 rounded-md bg-gray-0 p-5">
                <div class="flex items-center justify-between">
                  <h2 class="text-h2 font-bold text-gray-900">{t_label?.['checkout.label.billing_details'] ?? 'Billing Details'}</h2>
                  <div class="cursor-pointer -my-1">
                    <a
                      onClick={() => navigate("/editBilling")}
                      class="flex h-[26px] font-bold items-center justify-center gap-1 rounded-md border border-gray-600 px-2.5-px text-sm hover:border-gray-500 hover:text-gray-300 active:border-gray-400 active:text-gray-200 disabled:border-gray-800 disabled:text-gray-600 dark:border-gray-300 dark:text-gray-600 dark:hover:bg-gray-200 dark:active:border-gray-500 dark:disabled:border-gray-300 dark:disabled:bg-transparent dark:disabled:text-gray-400"
                    >
                      <svg class="h-4 w-4 text-gray-400 first:-ml-0.5 last:-mr-0.5 first:last:-mx-[5px]">
                        <use href="img/sprite.svg#edit"></use>
                      </svg>
                      <span>{t_label?.['account.label.edit'] ?? 'Edit'}</span>
                    </a>
                  </div>
                </div>
                <div class="gap-x-5 md:grid md:grid-cols-2">
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.first_name'] ?? 'First Name'}</div>
                    <div class="mt-2 text-lg text-gray-900">
                      {billingDetail.firstName}
                    </div>
                  </div>
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.last_name'] ?? 'Last Name'}</div>
                    <div class="mt-2 text-lg text-gray-900">
                      {billingDetail.lastName}
                    </div>
                  </div>
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.address'] ?? 'Address'}</div>
                    <div class="mt-2 text-lg text-gray-900">
                      {getAddressStr(billingDetail, options)}
                    </div>
                  </div>
                  <div>
                    <div class="mt-5 text-sm">{t_label?.['checkout.label.phone'] ?? 'Phone'}</div>
                    <div class="mt-2 text-lg text-gray-900">
                      {billingDetail.phone}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
