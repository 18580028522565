import React, { useState, useEffect } from "react";
import { changeUserPassword } from "../../../reducers/accountDetails";
import { useDispatch } from "react-redux";
import { validateForm } from "../../../utils/form";
import { displayError } from "../../../reducers/snackbar";
import { focusAndOpenKeyboard } from "../../../utils/globalUtils";
import { useNavigate } from "react-router-dom";
import { vvLogin } from "../../../settings.js/dev";
import Footer from "../../../components/layout/footer";
import { logout2 } from "../../../reducers/auth";
import { getTranslation } from "../../../reducers/translation";
import { TRANSLATION_TYPE } from "../../../utils/constants";
import { useSelector } from "react-redux";

const Reset = () => {
  let navigate = useNavigate();
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setPassword] = useState("");
  const [ConfrimNewPassword, setConfirmPassword] = useState("");

  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

  const dispatch = useDispatch();

  const updateOldPassword = (e) => {
    setOldPassword(e.target.value);
  };

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  const updateConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm("login__form")) {
      dispatch(
        changeUserPassword({ OldPassword, NewPassword, ConfrimNewPassword })
      )
        .then((response) => {
          // navigate("/resetComplete");
          dispatch(logout2());
        })
        .catch((error) => {
          dispatch(displayError(error));
        });
    }
  };

  useEffect(() => {
    focusAndOpenKeyboard(document.getElementById("oldpassword"), 300);

    const eyeIcons = document.querySelectorAll(".personal-details__icon--eye");

    const handleClick = (event) => {
      eyeIcons.forEach((item) => {
        item.classList.toggle("crossed");
        if (item.classList.contains("crossed")) {
          item.nextElementSibling.type = "text";
          item.querySelector("span").textContent = t_label?.['account.label.hide_password'] ?? 'Hide password'
        } else {
          item.nextElementSibling.type = "password";
          item.querySelector("span").textContent = t_label?.['account.label.show_password'] ?? 'Show password'
        }
      });
    };

    eyeIcons.forEach((item) => {
      item.addEventListener("click", handleClick);
    });

    return () => {
      eyeIcons.forEach((item) => {
        item.removeEventListener("click", handleClick);
      });
    };
  }, []);
  return (
    <>
      <div>
        <body class="login-body">
          <header class="header">
            <img src="img/logo.png" srcset="img/logo@2x.png 2x" alt="" />

            <nav class="user-nav">
              <span dangerouslySetInnerHTML={{ __html: t_message?.['account.message.already_a_customer'] }} />
              <a
                href={vvLogin}
                target="_blank"
                class="user-nav__link"
              >
                {t_label?.['account.button.sign_in'] ?? 'Sign In'}
              </a>
            </nav>
          </header>

          <section class="login">
            <div class="grid-cont">
              <form
                noValidate
                action=""
                onSubmit={handleSubmit}
                id="login__form"
                class="login__form"
              >
                <div class="login__cont">
                  <h1 class="login__title">{t_label?.['account.button.reset_password'] ?? 'Reset Password'}</h1>
                  <p class="login__subtitle">
                    {t_message?.['account.message.reset_email_mail'] ?? 'If your account was found, an email has been sent containing a reset code. Please enter the code and your new password.'}
                  </p>
                  <label class="personal-details__label">{t_label?.['account.label.old_password'] ?? 'Old Password'}</label>
                  <div class="form-group personal-details__control">
                    <button
                      type="button"
                      tabindex="-1"
                      class="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye"
                    >
                      <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                      </svg>
                      <div class="personal-details__tooltip personal-details__tooltip--auto">
                        <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                      </div>
                    </button>
                    <input
                      onChange={updateOldPassword}
                      required
                      data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                      type="password"
                      name="oldpassword"
                      id="oldpassword"
                      class="personal-details__input"
                    />
                  </div>

                  <label class="personal-details__label">{t_label?.['checkout.label.new_password'] ?? 'New Password'}</label>
                  <div class="form-group personal-details__control">
                    <button
                      type="button"
                      tabindex="-1"
                      class="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye"
                    >
                      <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                      </svg>
                      <div class="personal-details__tooltip personal-details__tooltip--auto">
                        <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                      </div>
                    </button>
                    <input
                      onChange={updatePassword}
                      required
                      data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                      type="password"
                      name="password"
                      id="password"
                      class="personal-details__input"
                    />
                  </div>

                  <label class="personal-details__label">
                    {t_label?.['account.label.confirm_password'] ?? 'Confirm Password'}
                  </label>
                  <div class="form-group personal-details__control">
                    <button
                      type="button"
                      tabindex="-1"
                      class="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye"
                    >
                      <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                      </svg>
                      <div class="personal-details__tooltip personal-details__tooltip--auto">
                        <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                      </div>
                    </button>
                    <input
                      required
                      data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'}
                      type="password"
                      name="password2"
                      data-pristine-equals="#password"
                      data-pristine-equals-message={t_message?.['account.message.password_not_matching']}
                      id="password2"
                      class="personal-details__input"
                      onChange={updateConfirmPassword}
                    />
                  </div>
                </div>
                <div class="login__foot">
                  <div class="login__foot-inner">
                    <button type="submit" class="button">
                      <span>{t_label?.['account.label.change_password'] ?? 'Change Password'}</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <Footer />
        </body>
      </div >
    </>
  );
};

export default Reset;
