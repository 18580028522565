/**
 * Author: Harsh Agrawal
 * Summary: This page facilitates the creation of a new user account in AWS Cognito.
 */


import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../components/layout/header';
import Footer from '../../../components/layout/footer';
import OrderSummary from '../../../components/orderSummary';
import { useDispatch, useSelector } from 'react-redux';
import { validateForm } from '../../../utils/form';
import { useNavigate } from 'react-router-dom';
import { displayError, displaySuccess } from '../../../reducers/snackbar';
import sepaLogo from './../../../assets/img/sepa-logo.svg'
import bancontactLogo from './../../../assets/img/bancontact-logo.svg'
import appleLogo from './../../../assets/img/apple-logo-black.svg'
import CBCardComponent from '../../../components/cardComponent';
import { createIntent, updateIntent } from '../../../reducers/chargebee';
import { deletePacContainer, focusAndOpenKeyboard } from '../../../utils/globalUtils';
import { updatePaymentIntentId, updatePaymentToken, updateDisplayPurchaseComplete, setEcommerceGTMEvent, setCheckoutPayload } from '../../../reducers/cart';
import { initChargebee } from '../../../reducers/chargebee';
import { salesforcePayload } from '../../../utils/functions';
import { createCampaignMember } from '../../../reducers/salesforce';
import { LinearProgress } from '@mui/material';
import { TRANSLATION_TYPE, USER_PAYMENT_METHOD_PREFERENCE } from '../../../utils/constants';
import { getTranslation } from '../../../reducers/translation';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { CHECKOUT_STATUS } from '../../../utils/constants';
import { createSubscription } from '../../../reducers/billingDetails';
import { createAccountForNewUser, checkUserStatus } from '../../../reducers/auth';
import Localization from '../../../components/translation';
import { displayPrice } from '../../../components/currencyPrice';
import { recaptchaAssessment } from '../../../utils/recaptcha';
import { setDisplayTax } from '../../../reducers/cart';
import { fetchEstimateForNewSubscription } from '../../../reducers/cart';
import ErrorAlert from '../../../components/Alert';
import { parseError } from '../../../utils/error';



function AddPaymentMethod() {
  const [displayTemporaryPasswordMsg, setDisplayTemporaryPasswordMsg] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { email, userStatus } = useSelector((state) => (state.auth))
  const { estimate, checkoutPayload, paymentOption, userPaymentMethodPreference, promoCode } = useSelector((state) => state.cart);
  const { nextTotal, trialPeriod, trialPeriodUnit, periodUnit, subStatus, currencyCode, total, thankyouPageType } = estimate;
  const [displayApplePayBtn, setDisplayApplePayBtn] = useState(false);
  const { cbInstance, isCbInitiated, _3dsEnabled, paymentModule } = useSelector((state) => state.chargebee);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const [gpayHandler, setGpayHandler] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState({});
  const payment_module = _3dsEnabled ? ['card', ...paymentModule] : paymentModule;
  const [displayPage, setDisplayPage] = useState(payment_module.length === 0);
  const { gdprEmailUpdates } = useSelector((state) => state.accountDetails);
  const [isWalletUsed, setIsWalletUsed] = useState(false);

  const t = useSelector(getTranslation)
  const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
  const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}
  const [disableBtn, setDisableBtn] = useState(userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.APPLE_PAY);
  const [displayPayWithCardLabel, setDisplayPayWithCardLabel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState();
  const [applePayHandler_, setapplePayHandler_] = useState(null)


  // usePreventBackNavigation();
  deletePacContainer();

  useEffect(() => {
    if (!isCbInitiated) {
      dispatch(initChargebee());
    }
  }, [])

  useEffect(() => {

    // mountPaymentMethod('google-pay', 'google_pay', '#gpay-button', {
    //     buttonColor: 'white', buttonType: 'plain', buttonSizeMode: 'fill'
    // })
    if (cbInstance)
      fetchPaymentIntent();
  }, [cbInstance])

  const createPaymentIntent = async (payment_method_type) => {
    let response = await createIntent({ PaymentMethodType: payment_method_type, CurrencyCode: currencyCode, Amount: Math.round(total * 100) });
    return response?.payment_intent;
  }

  const updatePaymentIntent = async (payment_intent, newTotal) => {
    let response = await updateIntent({ id: payment_intent.id, PaymentMethodType: payment_intent.payment_method_type, CurrencyCode: payment_intent.currency_code, Amount: Math.round(newTotal * 100) });
    return response?.payment_intent;
  }

  const fetchPaymentIntent = () => {

    let payment_intent = {};
    let payment_intent_response = {}; // only used to display loading page animation while payment intent is getting fetched


    for (let i = 0; i < payment_module.length; i++) {
      createPaymentIntent(payment_module[i]).then((intent) => {
        payment_intent[payment_module[i]] = intent;
        updatePageLoading(payment_intent);
      }).finally(() => {
        payment_intent_response[payment_module[i]] = true;
        updatePageLoading(payment_intent_response, payment_intent);
      })
    }

  }

  const updatePageLoading = (payment_intent_response, payment_intent) => {

    let isPaymentIntentInitialized = true;

    for (let i = 0; i < payment_module.length; i++) {
      if (!payment_intent_response?.[payment_module[i]])
        isPaymentIntentInitialized = false;
    }

    if (isPaymentIntentInitialized) {
      setDisplayPage(true);
      setPaymentIntent(payment_intent);

      if (payment_intent?.google_pay)
        initGPayCheckout(payment_intent?.google_pay);
      if (payment_intent?.apple_pay)
        initApplePayCheckout(payment_intent?.apple_pay);
    }

  }


  const initGPayCheckout = (payment_intent) => {
    if (cbInstance) {
      if (gpayHandler == null) {
        cbInstance.load('google-pay').then((gpayHandler) => {
          setGpayHandler(gpayHandler);
          gpayHandler.setPaymentIntent(payment_intent);
          setDisplayPayWithCardLabel(true);
          gpayHandler.mountPaymentButton('#gpay-button', {
            buttonColor: 'white', buttonType: 'plain', buttonSizeMode: 'fill'
          }).then(() => {
            // once button mounted
            return gpayHandler.handlePayment();
          }).then((result) => {
            dispatch(updatePaymentIntentId({ 'paymentIntentId': result.paymentIntent.id }))
            setIsWalletUsed(true);
            // navigate("/checkout/createaccount");
          }).catch((error) => {
            // handle error
          });
        });
      } else {
        createPaymentIntent('google_pay').then((intent) => {
          gpayHandler.setPaymentIntent(intent);
        })
      }

    }
  };

  const initApplePayCheckout = (payment_intent) => {
    if (cbInstance) {
      if (applePayHandler_ == null) {
        cbInstance.load("apple-pay").then((applePayHandler) => {
          setapplePayHandler_(applePayHandler);
          const isSupportedDevice = applePayHandler.canMakePayments();
          setDisplayApplePayBtn(isSupportedDevice);
          if (isSupportedDevice) {
            applePayHandler.setPaymentIntent(payment_intent);
            setDisplayPayWithCardLabel(true);
            applePayHandler.mountPaymentButton("#apple-pay-button", {
              buttonColor: 'black',
              buttonType: 'check-out',
              onpaymentmethodselected: (event, callback) => {

                let payload = getCheckoutPayload(event?.paymentMethod?.billingContact);

                dispatch(fetchEstimateForNewSubscription(payload)).then((response) => {
                  updateApplePayHandlerWithNewAmount(response?.total, applePayHandler);
                  dispatch(setDisplayTax({ "displayTax": true }));
                  const update = {
                    newTotal: {
                      label: 'Total',
                      amount: response.total
                    }
                  }
                  callback(update)
                }).catch((error) => {
                  dispatch(displayError(error));
                })

              }

            }).then(() => {
              // once button mounted
              return applePayHandler.handlePayment(
                {
                  error: function (d) {
                    console.log("error", d);
                    console.log(d?.active_payment_attempt?.error_detail)
                    dispatch(displayError({ 'error': d?.active_payment_attempt?.error_text }));
                  },
                }

              );
            }).then((paymentIntent) => {
              let billingDetail = paymentIntent?.paymentIntent?.payer_info?.billing_address;
              billingDetail = { ...billingDetail, 'country': billingDetail.countryCode };
              let promoCode = getPromoCode();
              let payload = { ...checkoutPayload, billingDetail, promoCode };
              dispatch(setCheckoutPayload({ payload }));
              // paymentIntent contains authorized payment intent
              let paymentOption_ = { 'paymentIntentId': paymentIntent?.paymentIntent?.id };
              dispatch(updatePaymentIntentId(paymentOption_))
              setIsWalletUsed(true);
              completeWalletPurchase(paymentOption_, payload);
            }).catch((error) => {
              console.log(error);

              // handle error
            });
          }
        });
      }
    }
  };

  const getPromoCode = () => {
    let promoCodeElement = document.getElementById('promo-code');
    if (promoCodeElement == null)
      return [];
    return promoCodeElement.value.split(',').filter(value => value.trim() !== '');
  }

  const mountPaymentMethod = (module_name, payment_method_type, button_selector, options) => {
    let paymentHandler;
    if (cbInstance) {
      cbInstance.load(module_name).then((paymentHandler_) => {
        paymentHandler = paymentHandler_;
        createPaymentIntent({ payment_method_type })
          .then((payment_intent) => {
            paymentHandler.setPaymentIntent(payment_intent);
            return paymentHandler.mountPaymentButton(button_selector, options);
          })
          .then(() => {
            // once button mounted
            return paymentHandler.handlePayment();
          })
          .then((paymentIntent) => {
            dispatch(updatePaymentIntentId({ 'paymentIntentId': paymentIntent.id }))
            // navigate("/checkout/createaccount");
            setIsWalletUsed(true);
          })
          .catch((error) => {
            console.log(error)
            // handle error
          });

      });
    }
  };

  const fetchEstimate = (payload = checkoutPayload) => {
    setError("");
    dispatch(fetchEstimateForNewSubscription(payload)).then((response) => {
      dispatch(setDisplayTax({ "displayTax": true }));
      updateApplePayHandlerWithNewAmount(response?.total);
    }).catch((error) => {
      dispatch(displayError(error));
      setError(error);
    })
  }

  const updateApplePayHandlerWithNewAmount = (amount, applePayHandler = applePayHandler_) => {
    if (applePayHandler != null && applePayHandler?.paymentIntent != null) {
      if (applePayHandler?.paymentIntent?.amount !== amount) {
        updatePaymentIntent(applePayHandler?.paymentIntent, amount).then((payment_intent) => {
          applePayHandler.setPaymentIntent(payment_intent);
        })
      }
    }
  }

  const fetchEstimateOnPromoCodeChange = (e) => {
    let promoCode = getPromoCode();
    let payload = { ...checkoutPayload, promoCode };
    dispatch(setCheckoutPayload({ payload }));
    fetchEstimate(payload);
  }

  const getCheckoutPayload = (applePayBillingContact) => {
    let billingAddress = {};

    let address1 = applePayBillingContact?.addressLines[0] ? applePayBillingContact?.addressLines[0] : applePayBillingContact?.locality;

    billingAddress = {
      "firstName": `${applePayBillingContact?.givenName}`,
      "lastName": `${applePayBillingContact?.familyName}`,
      "addressLine1": `${address1}`,
      "addressLine2": null,
      "city": `${applePayBillingContact?.locality}`,
      "state": `${applePayBillingContact?.administrativeArea}`,
      "zip": `${applePayBillingContact?.postalCode}`,
      "country": `${applePayBillingContact?.countryCode ?? applePayBillingContact?.country}`
    }
    let payload = {
      ...checkoutPayload, 'billingDetail': billingAddress
    };

    let promoCode = getPromoCode();

    if (promoCode && promoCode.length > 0) {
      payload = { ...payload, promoCode };
    }

    dispatch(setCheckoutPayload({ payload }));
    return payload;
  }

  const handleSubmit = async (event) => {
    if (event != null)
      event.preventDefault();

    if (validateForm("create-account__form")) {
      var recaptchaToken = await recaptchaAssessment('checkout');

      if (!isWalletUsed) {
        if (cardRef.current) {
          var token = null;
          try {
            setDisableBtn(true);
            let paymentOption_ = null;
            if (_3dsEnabled) {
              let payment_intent = await cardRef.current.authorizeWith3ds(paymentIntent?.card);
              paymentOption_ = { 'paymentIntentId': payment_intent.id };
              dispatch(updatePaymentIntentId(paymentOption_));
            } else {
              token = await cardRef.current.tokenize(event);
              paymentOption_ = { 'paymentToken': token }
              dispatch(updatePaymentToken(paymentOption_))
            }
            completePurchase(paymentOption_, recaptchaToken);
            // navigate("/checkout/createaccount");
          } catch (error) {
            setDisableBtn(false);
            console.log(error)
          }
        } else {
          completePurchase({}, recaptchaToken);
        }
      } else {
        completePurchase(paymentOption, recaptchaToken);
      }
    }
  };

  const completeWalletPurchase = async (paymentOption, payload) => {
    var recaptchaToken = await recaptchaAssessment('checkout');
    completePurchase(paymentOption, recaptchaToken, payload);
  }

  const updatePassword = (e) => {
    setPassword(e.target.value);
    updateApplePayBtn();
  }

  const updateConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    updateApplePayBtn();
  }

  const completePurchase = (paymentOption, recaptchaToken, payload = checkoutPayload) => {
    if (validateForm("create-account__form")) {
      if (paymentOption?.paymentToken)
        payload = { ...payload, PaymentTokenId: paymentOption.paymentToken };
      else if (paymentOption?.paymentIntentId)
        payload = { ...payload, PaymentIntentId: paymentOption.paymentIntentId };
      else if (paymentOption?.paymentSourceId)
        payload = { ...payload, paymentSourceId: paymentOption.paymentSourceId };
      setDisableBtn(true);
      setErrorMessage("");
      dispatch(createSubscription(payload, recaptchaToken)).then((response) => {
        dispatch(setEcommerceGTMEvent({ ecommerceGtmEvent: response?.subscriptionGTMEvent }))
        createSalesforceCampaign(payload?.billingDetail);
        dispatch(updateDisplayPurchaseComplete({ displayPurchaseComplete: true }))

        if (userStatus === "NEW") {
          let password1 = document.getElementById('password').value;
          let password2 = document.getElementById('password2').value;
          dispatch(createAccountForNewUser({ email, password: password1, confirmPassword: password2 })).then((response) => {
            navigate("/checkout/thankyou")
          }).catch((error) => {
            setDisableBtn(false);
            dispatch(displayError(error));
          })
        }
        else
          navigate("/checkout/thankyou")

      }).catch((error) => {
        setDisableBtn(false);
        if (error?.Param === "payment")
          setErrorMessage(error?.Error)
        else
          dispatch(displayError(error));
      })

    }
  };

  useEffect(() => {
    // dispatch(fetchRenewalEstimate(email)).catch((error) => {
    //     displayError(error);
    // })

    checkForTemporaryPassword();

    focusAndOpenKeyboard(document.getElementById('password'), 300);

    const handleClick = (event) => {
      const eyeIcons = document.querySelectorAll('.personal-details__icon--eye');

      eyeIcons.forEach((item) => {
        item.classList.toggle('crossed');
        if (item.classList.contains('crossed')) {
          item.nextElementSibling.type = 'text';
          item.querySelector('span').textContent = t_label?.['account.label.hide_password'] ?? 'Hide password';
        } else {
          item.nextElementSibling.type = 'password';
          item.querySelector('span').textContent = t_label?.['account.label.show_password'] ?? 'Show password';
        }
      });
    };

    setTimeout(() => {
      const eyeIcons = document.querySelectorAll('.personal-details__icon--eye');
      console.log(eyeIcons);
      eyeIcons.forEach((item) => {
        item.addEventListener('click', handleClick);
      });
    }, 3000);


    return () => {
      const eyeIcons = document.querySelectorAll('.personal-details__icon--eye');
      eyeIcons.forEach((item) => {
        item.removeEventListener('click', handleClick);
      });
    };
  }, []);

  const checkForTemporaryPassword = () => {
    if (email) {
      dispatch(checkUserStatus(email)).then((response) => {
        if (response?.userStatus === "FORCE_CHANGE_PASSWORD") {
          setDisplayTemporaryPasswordMsg(true);
        }
      })
    }
  }

  const createSalesforceCampaign = ({ firstName, lastName }, checkoutStatus = CHECKOUT_STATUS.COMPLETED) => {
    let payload = salesforcePayload({ firstName, lastName, email, checkoutStatus });
    if (gdprEmailUpdates !== undefined) {
      payload = { ...payload, 'OptinMarketing': gdprEmailUpdates ? "YES" : "NO" }
    }
    return dispatch(createCampaignMember(payload))
  }

  const getPeriodText2 = (periodUnit) => {
    if (periodUnit) {
      if (periodUnit === "Month")
        return t_label?.['checkout.label.monthly'] ?? "monthly"
      else if (periodUnit === "Year")
        return t_label?.['checkout.label.annual'] ?? "annual"
      else if (periodUnit === "Day")
        return t_label?.['checkout.label.day'] ?? "day"
    }
    return null;
  }

  const updateApplePayBtn = () => {
    if (userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.APPLE_PAY) {
      setDisableBtn(!validateForm("create-account__form", true));
    }
  }


  return (
    displayPage ?
      <>
        {
          cbInstance &&
          <div className="body body--alt2">
            <Header />

            <main className="main main--fullh">
              <section className="section personal-details">
                <div className="grid-cont">


                  <form onSubmit={handleSubmit} noValidate id="create-account__form" className="personal-details__form">
                    <h2 className="personal-details__heading">
                      {
                        userStatus === "NEW" ?
                          t_label?.['checkout.label.confirm_purchase_and_create_account'] ?? 'Confirm Purchase & Create Account'
                          :
                          t_label?.['checkout.label.confirm_purchase'] ?? 'Confirm Purchase'
                      }
                    </h2>

                    <label className="personal-details__label">{t_label?.['checkout.label.your_email'] ?? 'Your Email'}</label>
                    <div className="form-group personal-details__control personal-details__control--hastip">
                      <div className="personal-details__icon personal-details__icon--prefix">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M11.5 7.5H4.5C3.94772 7.5 3.5 7.94772 3.5 8.5V12C3.5 12.5523 3.94772 13 4.5 13H11.5C12.0523 13 12.5 12.5523 12.5 12V8.5C12.5 7.94772 12.0523 7.5 11.5 7.5Z" />
                          <path d="M5.5 7.5V5.5C5.5 4.83696 5.76339 4.20107 6.23223 3.73223C6.70107 3.26339 7.33696 3 8 3C8.66304 3 9.29893 3.26339 9.76777 3.73223C10.2366 4.20107 10.5 4.83696 10.5 5.5V7.5" />
                        </svg>
                        <div className="personal-details__tooltip">
                          <span>{t_message?.['checkout.message.fixed_email'] ?? "This email address was entered earlier in the process and cannot be changed. If you would like to use another email address, please start over."}</span>
                        </div>
                      </div>
                      <input disabled type="email" value={email} name="email" id="email" className="personal-details__input" />
                    </div>
                    {userStatus === "NEW" &&
                      <>
                        <label className="personal-details__label">{t_label?.['checkout.label.new_password'] ?? 'New Password'}</label>
                        <div className="form-group personal-details__control">
                          <button type="button" tabIndex="-1" className="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                              <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                            </svg>
                            <div className="personal-details__tooltip personal-details__tooltip--auto">
                              <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                            </div>
                          </button>
                          <input required type="password" name="password" id="password" className="personal-details__input" onChange={updatePassword} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} />
                        </div>

                        <label className="personal-details__label">{t_label?.['account.label.confirm_password'] ?? 'Confirm Password'}</label>
                        <div className="form-group personal-details__control">
                          <button type="button" tabIndex="-1" className="personal-details__icon personal-details__icon--button personal-details__icon--postfix personal-details__icon--eye">
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M1.66406 8.00033C1.66406 8.00033 4.33073 2.66699 8.9974 2.66699C13.6641 2.66699 16.3307 8.00033 16.3307 8.00033C16.3307 8.00033 13.6641 13.3337 8.9974 13.3337C4.33073 13.3337 1.66406 8.00033 1.66406 8.00033Z" />
                              <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" />
                            </svg>
                            <div className="personal-details__tooltip personal-details__tooltip--auto">
                              <span>{t_label?.['account.label.show_password'] ?? 'Show password'}</span>
                            </div>
                          </button>
                          <input required type="password" name="password2" data-pristine-equals="#password" data-pristine-equals-message={t_message?.['account.message.password_not_matching']} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} id="password2" className="personal-details__input" onChange={updateConfirmPassword} />
                        </div>

                      </>
                    }

                    {
                      userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.APPLE_PAY && (promoCode == null || promoCode.length < 2) &&
                      <>
                        <div className="grid-row mt-4">
                          <div className="grid-col sm:grid-col--12">
                            <div className="form-group promo-code">
                              <label htmlFor="promo-code" className="personal-details__label mt-0">{t_label?.['checkout.label.enter_a_promo_code'] ?? 'Enter a Promo Code'}</label>
                              <input
                                type="text"
                                name="promoCode"
                                defaultValue={promoCode}
                                id="promo-code"
                                placeholder={t_label?.['checkout.label.enter_a_promo_code'] ?? 'Enter a Promo Code'}
                                className="personal-details__input"
                                data-pristine-maxLength="50"
                                data-pristine-maxlength-message={t_message?.['checkout.message.promo_code_min_len'] ?? "Promo code cannot have more than 50 characters"}
                                onBlur={fetchEstimateOnPromoCodeChange}
                              />

                              {
                                error != null && error.Param === 'promocode' &&
                                <ErrorAlert error={parseError(error)} bgcolor='#f7f8f9' />
                              }
                            </div>

                          </div>
                        </div>
                      </>
                    }

                    {
                      !(parseFloat(total) === 0 && thankyouPageType === "COURSE") &&
                      userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.CARD &&

                      <>

                        <div className='mt-8'>
                          <h2 className="personal-details__heading">{t_label?.['billing.label.payment_details']}</h2>
                          <h5 className='payment-detail__subtitle'>{t_label?.['checkout.label.choose_payment_method']}</h5>

                          <div className='flex-container flex-col'>

                            {/* {
                              userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.APPLE_PAY &&
                              <div
                                id='apple-pay-button'
                                className='payment-details__button'
                                style={displayApplePayBtn ? {} : { 'display': 'none' }}
                              >
                              </div>
                            } */}

                            {/* {
                              userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.GOOGLE_PAY &&
                              <div
                                id='gpay-button'
                                className='payment-details__button'
                                style={paymentIntent?.google_pay ? {} : { 'display': 'none' }}
                              >
                              </div>
                            } */}

                          </div>
                          <div
                            className='flex-container flex-col payment-detail__label'
                            style={displayPayWithCardLabel ? {} : { 'display': 'none' }}
                          >
                            {/* {t_label?.['checkout.label.pay_with_card']} */}
                          </div>

                        </div>

                        < CBCardComponent ref={cardRef} />


                      </>
                    }


                    {errorMessage && <div className="cc-error" role="alert">{errorMessage}</div>}

                    {
                      subStatus === "InTrial" &&
                      <label className="form-group personal-details__checkbox">
                        <input id='agreement' required type="checkbox" name="agreement"
                          onChange={updateApplePayBtn}
                        />

                        <Localization text={thankyouPageType != 'ADD_ON' ? t_message?.['checkout.message.subscription_trial_billing'] : t_message?.['checkout.message.subscription_billing_one_time']} values={{
                          trialPeriod,
                          trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                          amount: displayPrice(nextTotal, currencyCode),
                          periodUnit: getPeriodText2(periodUnit)?.toLowerCase()
                        }}
                        />
                        &nbsp;
                        <Localization text={t_message?.['checkout.message.subscription_trial_billing_acknowledge']} values={{
                          trialPeriod,
                          trialPeriodUnit: trialPeriodUnit.toLowerCase(),
                          periodUnit: getPeriodText2(periodUnit)?.toLowerCase()
                        }}
                        />

                      </label>
                    }

                    <label className="form-group personal-details__checkbox">
                      <input id='accept_tos' required type="checkbox" name="accept_tos"
                        onChange={updateApplePayBtn}
                      />
                      <span dangerouslySetInnerHTML={{ __html: t_message?.['checkout.message.agree_to_policy'] }} />
                    </label>

                    {
                      userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.CARD &&
                      <button disabled={disableBtn} type="submit" className={`button payment-details__submit ${disableBtn ? 'disabled' : ''}`}>{t_label?.['checkout.label.complete_purchase']}</button>
                    }

                    {
                      userPaymentMethodPreference === USER_PAYMENT_METHOD_PREFERENCE.APPLE_PAY && displayApplePayBtn &&
                      <div
                        onClick={(e) => validateForm("create-account__form")}
                      >
                        <div
                          style={disableBtn ? { 'pointerEvents': 'none' } : {}}
                        >
                          <div
                            id='apple-pay-button'
                            className='payment-details__button'
                          >
                          </div>
                        </div>
                      </div>
                    }

                  </form>

                  <OrderSummary />

                </div>
              </section >
            </main >

            <Footer />

          </div >
        }
      </>
      :
      <LinearProgress />
  )
}

export default AddPaymentMethod