import cookieStore from "./cookie";

export const createItemPayload = (items, count) => {
    let itemsObj = [];

    for (let i = 0; i < count; i++) {
        let item = items[`${i}`];
        let itemObj = {};

        if (item.item != null)
            itemObj = { "Name": item.item };
        if (item.quantity != null)
            itemObj = { ...itemObj, "Quantity": item.quantity };

        itemsObj.push(itemObj);
    }

    return itemsObj;
}

export const parseItemsFromURLSearchParams = (searchParams) => {
    let count = 0;
    let cart = {};
    let promoCode = [];
    let token = '';
    let userEmail = '';

    for (let i = 0; i < Array.from(searchParams).length; i++) {
        let key1 = `items[${i}]`;
        let key2 = `quantity[${i}]`;
        let obj = {};
        if (searchParams.has(key1)) {
            let v1 = searchParams.get(key1);
            obj["item"] = v1;
            count++;
        } else {
            break;
        }
        if (searchParams.has(key2)) {
            let v2 = searchParams.get(key2);
            obj["quantity"] = Number(v2);
        }
        cart[i] = obj;
    }

    for (let i = 0; i < Array.from(searchParams).length; i++) {
        let key1 = `promoCode[${i}]`;
        let key2 = `promocode[${i}]`;

        if (searchParams.has(key1) || searchParams.has(key2)) {
            let val = searchParams.get(key1) ?? searchParams.get(key2);
            promoCode.push(val);
        }
    }

    if (searchParams.has("promoCode") || searchParams.has("promocode")) {
        if (promoCode.length == 0)
            promoCode.push(searchParams.get("promoCode") ?? searchParams.get("promocode"));
    }

    if (searchParams.has("email"))
        userEmail = searchParams.get("email");

    if (searchParams.has("token"))
        token = searchParams.get("token");

    if (!token)
        token = cookieStore.get("vvstore");

    return { cart, count, promoCode, userEmail, token };
}

export const redirectUserToBaseCurrencyURL = (items) => {
    var currentUrl = window.location.href;
    var currentSearchParams = new URLSearchParams(window.location.search);

    var newUrl = currentUrl.split('?')[0] + '?';

    for (var i = 0; i < items.length; i++) {
        if (i === 0)
            newUrl += `items[${i}]=` + items[i]?.name;
        else
            newUrl += `&items[${i}]=` + items[i]?.name;

        if (items[i]?.quantity && items[i]?.quantity > 0)
            newUrl += `&quantity[${i}]=` + items[i]?.quantity;
    }

    if (currentSearchParams.has("email")) {
        newUrl += '&email=' + currentSearchParams.get("email");
    }
    if (currentSearchParams.has("token")) {
        newUrl += '&token=' + currentSearchParams.get("token");
    }
    if (currentSearchParams.has("promoCode")) {
        newUrl += '&promoCode=' + currentSearchParams.get("promoCode");
    }
    if (currentSearchParams.has("promocode")) {
        newUrl += '&promocode=' + currentSearchParams.get("promocode");
    }

    for (let i = 0; i < Array.from(currentSearchParams).length; i++) {
        let key1 = `promoCode[${i}]`;
        let key2 = `promocode[${i}]`;

        if (currentSearchParams.has(key1) || currentSearchParams.has(key2)) {
            let val = currentSearchParams.get(key1) ?? currentSearchParams.get(key2);
            newUrl += `&promocode[${i}]=` + val;
        }
    }

    // Redirect the user to the new URL
    window.location.href = newUrl;
}


