export const getAddressStr = (billingDetail, countryOptions) => {
    let address = '';
    if (billingDetail) {
        let { addressLine1, addressLine2, city, state, zip, country } = billingDetail;

        if (country != null) {
            let countryOption = countryOptions.find(option => option.value === country);
            if (countryOptions != null)
                country = countryOption?.label;
        }

        const addressComponents2 = [state, zip, country];
        const filteredAddressComponents2 = addressComponents2.filter(component => component);
        const addressTemp = filteredAddressComponents2.join(' ');

        const addressComponents = [addressLine1, addressLine2, city, addressTemp];
        const filteredAddressComponents = addressComponents.filter(component => component);
        address = filteredAddressComponents.join(', ');
    }

    return address;
}