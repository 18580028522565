// Function to parse cookies
const parseCookies = () => {
  const cookies = document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=').map(c => c.trim());
    cookies[name] = value;
    return cookies;
  }, {});
  return cookies;
};

// Function to get cookie value by name
const get = (name) => {
  const cookies = parseCookies();
  return cookies[name];
};

const remove = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}


const cookieStore = {
  get,
  remove
}

export default cookieStore;