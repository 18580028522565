import Pristine from 'pristinejs';

export const validateForm = (formId, silent) => {
    const form = document.getElementById(formId);
    const pristine = new Pristine(form);
    return pristine.validate(silent);
}

export const addFormCustomFieldError = (formId, ele, error) => {
    const form = document.getElementById(formId);
    const pristine = new Pristine(form);
    pristine.validate();
    pristine.addError(ele, error)
    return pristine;
}