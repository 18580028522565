import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings.js/dev";
import api from "../utils/api";
import { trialEligible } from "./accountDetails";
import localStore from "../utils/localstore";
import { vvLogout } from "../settings.js/dev";
import cookieStore from "../utils/cookie";

const initialState = {
  isUserLoggedIn: false,
  accessToken: null,
  email: '',
  userName: '',
  isExistingUser: false,
  userStatus: null,
  getCards: false,
  countryCode: localStore.get('userLocation') ?? 'US',
  userValidationResponse: {}
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      let { accessToken, email } = action.payload;
      state.isUserLoggedIn = true;
      state.accessToken = accessToken;
    },
    existingUser: (state, action) => {
      let { email, username, isExistingUser } = action.payload;
      state.isExistingUser = isExistingUser;
      state.email = email;
      state.username = username;
    },
    setUserDetails: (state, action) => {
      let { userStatus, getCards } = action.payload;
      state.userStatus = userStatus;
      state.getCards = getCards;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload?.email;
    },
    setCountryCode: (state, action) => {
      let { countryCode } = action.payload;
      state.countryCode = countryCode;
    },
    updateUserValidationData: (state, action) => {
      state.userValidationResponse = action.payload;
    }
  }
});

export const login = (payload) => async (dispatch) => {
  const response = await api.post(baseURL, "/login", payload);
  dispatch(loggedIn(response));
  localStore.set("token", response.accessToken);
  return response;
}

export const resetSession = () => async (dispatch) => {
  dispatch({ 'type': 'RESET_STORE' });
}

export const logout = () => async (dispatch) => {
  dispatch({ 'type': 'RESET_STORE' });
  localStore.remove("token");
  //dont clear token from cookie
}

export const logout2 = () => async (dispatch) => {
  dispatch({ 'type': 'RESET_STORE' });
  localStore.remove("token");
  cookieStore.remove("vvstore");
  window.location.href = vvLogout;
}

export const createAccountForNewUser = (payload) => async (dispatch) => {
  const response = await api.post(baseURL, "/signup", payload);
  return response;
};

export const getUserLocation = () => async (dispatch) => {
  var response = await fetch('https://api.ipdata.co/?api-key=028c9890a48356ba9e8180885d2e9776e07ca028c6db4bdf368e9d8b');
  response = await response.json();
  if (response?.country_code) {
    localStore.set('userLocation', response?.country_code, false);
    dispatch(setCountryCode({ 'countryCode': response?.country_code }))
  }
  return response;
};

export const validateUserEmail =
  ({ email, items }) =>
    async (dispatch) => {
      const response = await api.post(baseURL, "/login/validate/", {
        email,
        items,
      });

      dispatch(updateUserValidationData(response));
      dispatch(
        existingUser({ 'email': response?.email, 'username': response?.username, isExistingUser: response.userStatus === "EXIST" })
      );
      dispatch(
        setUserDetails(response)
      )
      dispatch(
        trialEligible({ 'email': response?.email, isTrialEligible: response.isTrialEligible })
      );
      return response;
    };

export const checkUserStatus = (email) => async (dispatch) => {
  const response = await api.post(baseURL, `/user/status`, { email });
  return response;
};

export const { loggedIn, loggedOut, existingUser, setUserDetails, setCountryCode, setUserEmail, updateUserValidationData } = auth.actions;

export default auth.reducer;
