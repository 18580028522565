import { createSlice } from "@reduxjs/toolkit";
import { getLocale, getLocalizationFile } from "../utils/translationUtils";


const initialState = {
  locale: '',
  translation: {},
  initialized: false
};

export const translation = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      let { locale } = action.payload;
      state.locale = locale;
      state.initialized = false;
    },
    setTranslation: (state, action) => {
      state.translation = action.payload.translation;
      state.initialized = true
    }
  },
});

export const getTranslation = (state) => {
  const { translation, locale, initialized } = state.translation;
  return initialized ? translation?.[locale] : {};
}

export const initializeTranslation = () => async (dispatch) => {
  const locale = await getLocale();
  // const locale = 'fr';
  const translationObj = await getLocalizationFile(locale);
  dispatch(setLocale({ locale }));
  dispatch(setTranslation({ 'translation': translationObj }));
};


export const {
  setLocale,
  setTranslation
} = translation.actions;

export default translation.reducer;
